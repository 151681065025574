import * as Yup from 'yup';
import { convertToHTML } from 'draft-convert';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicContactInfoFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`footer${item[1]}`]: '',
      [`copyright${item[1]}`]: '',
    };
  });
  return dynamicFields;
}
export function getLocationFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`address${item[1]}`]: '',
      [`mapLink${item[1]}`]: '',
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicContactInfoFields();
  const locationFields = getLocationFields();
  const initialFormData = {
    isMultiLang: 'false',
    fullName: '',
    email: '',
    code: null,
    number: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedIn: '',
    youtube: '',
    tiktok: '',
    location: [{ ...locationFields }],
    ...dynamicFields,
  };

  return initialFormData;
}

export const contactInfoInitialFormData = getInitialFormData();

export function contactInfoValidationSchema(isMultiLangFlag) {
  const locationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required!'),
    mapLink: Yup.string()
      .url('Invalid map link URL!')
      .required('Map link is required!'),
  });

  let validationFields = {
    [`footer${DEFAULT_LANG.value}`]: Yup.mixed().required(`Footer(${DEFAULT_LANG.label}) is required!`),
    [`copyright${DEFAULT_LANG.value}`]: Yup.mixed()
      // eslint-disable-next-line func-names
      .test('copyright', '', function(fieldValue) {
        const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
        if (isFieldEmpty === '<p></p>') {
          return this.createError({
            message: `Description(${DEFAULT_LANG.label}) is required!`,
          });
        }
        return true;
      })
      .required(`Description(${DEFAULT_LANG.label}) is required!`),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`footer${value}`]: Yup.string().required(`Footer(${label}) is required!`),
        [`copyright${value}`]: Yup.mixed()
          // eslint-disable-next-line func-names
          .test('copyright', '', function(fieldValue) {
            const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
            if (isFieldEmpty === '<p></p>') {
              return this.createError({
                message: `Description(${DEFAULT_LANG.label}) is required!`,
              });
            }
            return true;
          })
          .required(`Description(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`footer${value}`]: Yup.string(),
        [`copyright${value}`]: Yup.mixed(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    fullName: Yup.string().required('Please input full name!'),
    email: Yup.string()
      .email('Invalid email format!')
      .required('Please input email!'),
    code: Yup.string()
      .nullable()
      .required('Country code is required!'),
    number: Yup.string().required('Phone number is required!'),
    whatsapp: Yup.string().required('Whatsapp number with country code is required!'),
    facebook: Yup.string().url('Invalid facebook url!'),
    instagram: Yup.string().url('Invalid instagram url!'),
    twitter: Yup.string().url('Invalid twitter url!'),
    linkedIn: Yup.string().url('Invalid linkedin url!'),
    youtube: Yup.string().url('Invalid youtube url!'),
    tiktok: Yup.string().url('Invalid tiktok url!'),
    location: Yup.array().of(locationSchema),
    ...validationFields,
  });
}
