import { Col, Divider, Row } from 'antd';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons/buttons';
import DivBreak from '../../../components/divBreak/DivBreak';
import FormUpload from '../../../components/upload/FormUpload';
import { IMAGE_URL } from '../../../utils/constants';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal';
import { deleteInspectionReportFile } from '../../../redux/inspection report/actionCreator';

const InspectionReportFieldForm = props => {
  const { values, setFieldValue, keyNameAndValue, cancelModal, formData } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const deleteImageLoading = useSelector(state => state.inspectionReport.loading);

  const [deleteFile, setDeleteFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onCancel = () => {
    setShowDeleteModal(false);
    setDeleteFile(null);
    setFieldValue(`${keyNameAndValue?.value}`, formData[keyNameAndValue?.value]);
  };

  const handleDeleteClick = file => {
    // eslint-disable-next-line no-underscore-dangle
    if (params?.id) {
      if (file?.originFileObj) return;
      setShowDeleteModal(true);
      setDeleteFile(file);
    }
  };

  const handleDeleteConfirmation = async () => {
    let deleteType = '';
    switch (keyNameAndValue?.value) {
      case 'leftImages':
        deleteType = 'left';
        break;
      case 'rightImages':
        deleteType = 'right';
        break;
      case 'frontImages':
        deleteType = 'front';
        break;
      case 'backImages':
        deleteType = 'back';
        break;
      default:
        deleteType = 'interior';
    }
    const res = await dispatch(deleteInspectionReportFile(params?.id, deleteFile?.name, deleteType));
    if (res?.data && res?.data?.status) {
      let remainingImages =
        keyNameAndValue?.value === 'interiorControlsAndSwitches'
          ? res?.data?.inspectionReport?.interior[`${keyNameAndValue?.value}`]
          : res?.data?.inspectionReport?.bodyInspection[`${keyNameAndValue?.value}`];
      switch (keyNameAndValue?.value) {
        case 'leftImages':
          remainingImages = res?.data?.inspectionReport?.bodyInspection?.leftSide?.images;
          break;
        case 'rightImages':
          remainingImages = res?.data?.inspectionReport?.bodyInspection?.rightSide?.images;
          break;
        case 'frontImages':
          remainingImages = res?.data?.inspectionReport?.bodyInspection?.frontSide?.images;
          break;
        case 'backImages':
          remainingImages = res?.data?.inspectionReport?.bodyInspection?.backSide?.images;
          break;
        default:
          remainingImages = res?.data?.inspectionReport?.interior[`${keyNameAndValue?.value}`];
      }
      if (Array.isArray(remainingImages)) {
        const remainingImagesInFile = remainingImages.map((item, index) => {
          return {
            uid: index + 1,
            name: `${item}`,
            status: 'done',
            url: `${IMAGE_URL}/${item}`,
          };
        });
        setFieldValue(`${keyNameAndValue?.value}`, remainingImagesInFile);
        setShowDeleteModal(false);
      }
    }
    setDeleteFile(null);
  };

  return (
    <>
      <DeleteConfirmationModal
        title={keyNameAndValue?.label}
        visible={showDeleteModal}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={deleteImageLoading}
        itemName={deleteFile?.name}
      />
      <div className="add-product-block">
        <Row>
          <Col xs={24}>
            <FormUpload
              name={keyNameAndValue?.value}
              value={values[`${keyNameAndValue?.value}`]}
              handleChange={info => {
                const fileList = [...info.fileList];
                setFieldValue(keyNameAndValue?.value, fileList.length > 0 ? fileList : null);
              }}
              accept="image/*"
              isMulti
              isRequired={false}
              onRemove={handleDeleteClick}
            />
          </Col>
        </Row>

        <DivBreak />
        <Divider />
        <Row justify="center" align="middle">
          <Button
            type="default"
            style={{ backgroundColor: '#155BDC', color: '#fff', height: '40px' }}
            onClick={e => {
              e.stopPropagation();
              cancelModal();
            }}
          >
            Save {keyNameAndValue?.label}
          </Button>
        </Row>
      </div>
    </>
  );
};

InspectionReportFieldForm.propTypes = {
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired,
  keyNameAndValue: propTypes.object.isRequired,
  cancelModal: propTypes.func.isRequired,
  formData: propTypes.object.isRequired,
};

export default InspectionReportFieldForm;
