const actions = {
  GET_TESTIMONIAL_BEGIN: 'GET_TESTIMONIAL_BEGIN',
  GET_TESTIMONIAL_SUCCESS: 'GET_TESTIMONIAL_SUCCESS',
  GET_TESTIMONIAL_FAILED: 'GET_TESTIMONIAL_FAILED',

  ADD_TESTIMONIAL_BEGIN: 'ADD_TESTIMONIAL_BEGIN',
  ADD_TESTIMONIAL_SUCCESS: 'ADD_TESTIMONIAL_SUCCESS',
  ADD_TESTIMONIAL_FAILED: 'ADD_TESTIMONIAL_FAILED',

  UPDATE_TESTIMONIAL_BEGIN: 'UPDATE_TESTIMONIAL_BEGIN',
  UPDATE_TESTIMONIAL_SUCCESS: 'UPDATE_TESTIMONIAL_SUCCESS',
  UPDATE_TESTIMONIAL_FAILED: 'UPDATE_TESTIMONIAL_FAILED',

  DELETE_TESTIMONIAL_BEGIN: 'DELETE_TESTIMONIAL_BEGIN',
  DELETE_TESTIMONIAL_SUCCESS: 'DELETE_TESTIMONIAL_SUCCESS',
  DELETE_TESTIMONIAL_FAILED: 'DELETE_TESTIMONIAL_FAILED',

  getTestimonialBegin: () => {
    return {
      type: actions.GET_TESTIMONIAL_BEGIN,
    };
  },

  getTestimonialSuccess: data => {
    return {
      type: actions.GET_TESTIMONIAL_SUCCESS,
      data,
    };
  },

  getTestimonialFailed: err => {
    return {
      type: actions.GET_TESTIMONIAL_FAILED,
      err,
    };
  },

  addTestimonialBegin: () => {
    return {
      type: actions.ADD_TESTIMONIAL_BEGIN,
    };
  },

  addTestimonialSuccess: data => {
    return {
      type: actions.ADD_TESTIMONIAL_SUCCESS,
      data,
    };
  },

  addTestimonialFailed: err => {
    return {
      type: actions.ADD_TESTIMONIAL_FAILED,
      err,
    };
  },

  updateTestimonialBegin: () => {
    return {
      type: actions.UPDATE_TESTIMONIAL_BEGIN,
    };
  },

  updateTestimonialSuccess: data => {
    return {
      type: actions.UPDATE_TESTIMONIAL_SUCCESS,
      data,
    };
  },

  updateTestimonialFailed: err => {
    return {
      type: actions.UPDATE_TESTIMONIAL_FAILED,
      err,
    };
  },

  deleteTestimonialBegin: () => {
    return {
      type: actions.DELETE_TESTIMONIAL_BEGIN,
    };
  },

  deleteTestimonialSuccess: data => {
    return {
      type: actions.DELETE_TESTIMONIAL_SUCCESS,
      data,
    };
  },

  deleteTestimonialFailed: err => {
    return {
      type: actions.DELETE_TESTIMONIAL_FAILED,
      err,
    };
  },
};

export default actions;