import actions from './actions';

const {
  GET_DASHBOARD_DATA_BEGIN, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_DATA_FAILED,
} = actions;

const initState = {
  datas: [],
  loading: false,
  error: null,
};


const DashboardReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_DASHBOARD_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        datas: data,
        loading: false,
      };
    case GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default DashboardReducer;