// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import Cookies from 'js-cookie';

// USER COMPONENTS
import { getAllPages } from '../../../redux/page/actionCreator';

const DisplayPage = ({ match }) => {
  let dataIndex = (Cookies.get('lang') == 'fr' ? 0 : 1);
  const dispatch = useDispatch();
  const [page, setPage] = useState({});

  const { pages, loading } = useSelector(state => {
    return {
      pages: state.page.pages,
      loading: state.page.loading
    }
  });

  useEffect(() => {
    if (pages.length === 0) getAllPages(dispatch);
    setPage(pages.find(page => page._id === match.params.id) || {});
  }, [pages.length]);

  // Receives an HTML string as an argument and returns an object with the sanitized HTML
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  if (Object.keys(page).length > 0) {
    const { details, page: page1, status } = page;
    if (details.length == 1)
      dataIndex = 0;

    return (
      <div style={{ padding: 20, backgroundColor: '#fff' }}>
        <h2 as={'h4'} style={{ textAlign: 'center', fontSize: 30, padding: 10, fontWeight: 'bold' }}>{details[dataIndex]?.title ?? 'NA'}</h2>
        <br />
        <div className="preview" dangerouslySetInnerHTML={createMarkup(details[dataIndex]?.body) ?? 'NA'}></div>
        <br />
        <div className="info" style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Page:</span> {page1}</p>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Status:</span> {status ? 'Active' : 'Inactive'}</p>
        </div>
      </div >
    );
  }
  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : <h4 style={{ fontSize: 30, height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>page Not Found</h4>;
};

export default DisplayPage;