import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Image, Row } from 'antd';
import { FieldArray } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import FormInput from '../../../components/input/input';
import FormUpload from '../../../components/upload/FormUpload';
import DivBreak from '../../../components/divBreak/DivBreak';
import { deleteCarIssueImage } from '../../../redux/car/actionCreator';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal';
import { IMAGE_URL } from '../../../utils/constants';

const CarIssueForm = props => {
  const { values, setFieldValue, cancelModal, errors, formData } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { carsData, carsLoading } = useSelector(state => {
    return {
      carsData: state.car.cars,
      carsLoading: state.car.loading,
    };
  });

  const [carIssuesTouched, setCarIssuesTouched] = useState(null);

  const [deleteFile, setDeleteFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [carIssueApiData, setCarIssueApiData] = useState([]);

  useEffect(() => {
    if (carsData && carsData.length > 0 && params?.id) {
      // eslint-disable-next-line no-underscore-dangle
      const carEditDataById = carsData.find(item => item?._id === params?.id);

      const getCarIssueFiles = () => {
        const fileArr = JSON.parse(carEditDataById?.carIssues);
        if (Array.isArray(fileArr))
          return fileArr.map((item, index) => {
            return {
              issueCaption: item?.caption,
              issueImage: [
                {
                  uid: index + 1,
                  name: `${item?.file}`,
                  status: 'done',
                  url: `${IMAGE_URL}/${item?.file}`,
                },
              ],
            };
          });
        return [];
      };

      setCarIssueApiData(getCarIssueFiles());
    }
  }, [carsData]);

  const onCancel = () => {
    setShowDeleteModal(false);
    setDeleteFile(null);
    setFieldValue('carIssueArr', formData?.carIssueArr);
  };

  const handleDeleteClick = file => {
    if (file?.originFileObj) return;
    setShowDeleteModal(true);
    setDeleteFile(file);
  };

  const handleDeleteConfirmation = async () => {
    const res = await dispatch(deleteCarIssueImage(params?.id, deleteFile?.name));
    if (res?.data && res?.data?.status) {
      const remainingCarIssues = JSON.parse(res?.data?.car?.carIssues);
      if (Array.isArray(remainingCarIssues)) {
        const remainingCarIssuesInFile = remainingCarIssues.map((item, index) => {
          return {
            issueCaption: item?.caption,
            issueImage: [
              {
                uid: index + 1,
                name: `${item?.file}`,
                status: 'done',
                url: `${IMAGE_URL}/${item?.file}`,
              },
            ],
          };
        });
        setCarIssueApiData(remainingCarIssuesInFile);
        setShowDeleteModal(false);
      }
    }
    setDeleteFile(null);
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Car Issue"
        visible={showDeleteModal}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={carsLoading}
        itemName={deleteFile?.name}
      />
      {/* carIssueArr */}
      <div className="add-product-block">
        {carIssueApiData.length > 0 && (
          <>
            <Row>
              {carIssueApiData.map(image => {
                return (
                  <>
                    <Col xs={7}>
                      <span>{image?.issueCaption ?? ''}</span>
                      <Image src={`${IMAGE_URL}/${image?.issueImage[0]?.name}`} />
                      <Button
                        size="small"
                        onClick={() => {
                          handleDeleteClick(image?.issueImage[0]);
                        }}
                        style={{ position: 'absolute', right: 0, bottom: 0, opacity: 0.6 }}
                      >
                        <FeatherIcon icon="trash" size={14} color="red" />
                      </Button>
                    </Col>
                    <Col xs={1} />
                  </>
                );
              })}
            </Row>
            <DivBreak height={24} />
          </>
        )}
        <Row>
          <FieldArray
            name="carIssueArr"
            render={arrayHelpers => {
              const { push, remove, form } = arrayHelpers;
              return (
                <>
                  {values?.carIssueArr.map((item, index) => {
                    const myError = form.errors?.carIssueArr && form.errors?.carIssueArr[index];
                    const myTouched = form.touched?.carIssueArr && form.touched?.carIssueArr[index];
                    const carIssTouched = carIssuesTouched && carIssuesTouched[index];
                    return (
                      <>
                        <Row key={index} style={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}>
                          <Col xs={24}>
                            <FormUpload
                              name="issueImage"
                              label="Car Issue Image"
                              value={values.carIssueArr[index]?.issueImage}
                              handleChange={info => {
                                const fileList = [...info.fileList].slice(-1);
                                setFieldValue(
                                  `carIssueArr[${index}].issueImage`,
                                  fileList.length > 0 ? [fileList[0]] : null,
                                );
                              }}
                              accept="image/*"
                              errors={myError}
                              touched={carIssTouched ?? myTouched}
                            />
                          </Col>
                          <Col xs={20}>
                            <FormInput
                              name="issueCaption"
                              label="Issue Caption"
                              value={values.carIssueArr[index]?.issueCaption}
                              handleChange={event => {
                                setFieldValue(`carIssueArr[${index}].issueCaption`, event?.target?.value);
                              }}
                              placeholder="Issue Caption"
                              errors={myError}
                              touched={carIssTouched ?? myTouched}
                            />
                          </Col>
                          <Col xs={1} />
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(index);
                            }}
                            style={{ paddingTop: '44px' }}
                          />
                          <br />
                        </Row>
                      </>
                    );
                  })}
                  <DivBreak />
                  <Row justify="end" align="middle">
                    <Col>
                      <Button
                        type="default"
                        onClick={() => {
                          const formFields = {
                            issueCaption: '',
                            issueImage: null,
                          };

                          push(formFields);
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add Car Issue
                      </Button>
                    </Col>
                  </Row>
                </>
              );
            }}
          />
        </Row>

        <DivBreak />
        <Divider />
        <Row justify="center" align="middle">
          <Button
            type="default"
            style={{ backgroundColor: '#155BDC', color: '#fff', height: '40px' }}
            onClick={e => {
              const carIssueTouched = values.carIssueArr.map(() => ({ issueCaption: true, issueImage: true }));
              setCarIssuesTouched(carIssueTouched);
              e.stopPropagation();
              if (errors?.carIssueArr) return;
              cancelModal();
            }}
          >
            Save Car Issues
          </Button>
        </Row>
      </div>
    </>
  );
};

CarIssueForm.propTypes = {
  values: propTypes.object,
  errors: propTypes.object,
  setFieldValue: propTypes.func,
  cancelModal: propTypes.func,
  formData: propTypes.object,
};

export default CarIssueForm;
