import Cookies from 'js-cookie';
import { message } from 'antd';
import actions from './authentication/actions';
import axios from './axios';

const { loginSuccess, logoutSuccess } = actions;

const errorHandling = (err, shouldDisplayMsg = true) => {
  return async dispatch => {
    if (err && err.response) {
      if (err.response.status === 403 || err.response.status === 401 || err.response.status === 406) {
        try {
          const res = await axios.get('/auth/refresh-token', {
            headers: {
              RefreshToken: `Bearer ${Cookies.get('refreshToken')}`,
              'X-User': 'admin',
            },
          });
          const { authToken, refreshToken, user } = res?.data;
          Cookies.set('authToken', authToken);
          Cookies.set('refreshToken', refreshToken);
          Cookies.set('refreshToken', refreshToken);
          dispatch(loginSuccess(user));
        } catch (error) {
          Cookies.remove('logedIn', { expires: 7 });
          Cookies.remove('authToken', { expires: 7 });
          Cookies.remove('refreshToken', { expires: 7 });
          dispatch(logoutSuccess(null));
        }
      } else {
        if (shouldDisplayMsg) {
          if (typeof err.response.data.msg === 'string') message.error(err.response.data.msg);
          else {
            Object.keys(err.response.data.msg).map(key => {
              message.error(err.response.data.msg[key]);
            });
          }
        }
      }
    }
  };
};

export default errorHandling;
