const actions = {
  GET_SERVICING_HISTORY_BEGIN: 'GET_SERVICING_HISTORY_BEGIN',
  GET_SERVICING_HISTORY_SUCCESS: 'GET_SERVICING_HISTORY_SUCCESS',
  GET_SERVICING_HISTORY_FAILED: 'GET_SERVICING_HISTORY_FAILED',

  ADD_SERVICING_HISTORY_BEGIN: 'ADD_SERVICING_HISTORY_BEGIN',
  ADD_SERVICING_HISTORY_SUCCESS: 'ADD_SERVICING_HISTORY_SUCCESS',
  ADD_SERVICING_HISTORY_FAILED: 'ADD_SERVICING_HISTORY_FAILED',

  UPDATE_SERVICING_HISTORY_BEGIN: 'UPDATE_SERVICING_HISTORY_BEGIN',
  UPDATE_SERVICING_HISTORY_SUCCESS: 'UPDATE_SERVICING_HISTORY_SUCCESS',
  UPDATE_SERVICING_HISTORY_FAILED: 'UPDATE_SERVICING_HISTORY_FAILED',

  DELETE_SERVICING_HISTORY_BEGIN: 'DELETE_SERVICING_HISTORY_BEGIN',
  DELETE_SERVICING_HISTORY_SUCCESS: 'DELETE_SERVICING_HISTORY_SUCCESS',
  DELETE_SERVICING_HISTORY_FAILED: 'DELETE_SERVICING_HISTORY_FAILED',

  getServicingHistoryBegin: () => {
    return {
      type: actions.GET_SERVICING_HISTORY_BEGIN,
    };
  },

  getServicingHistorySuccess: data => {
    return {
      type: actions.GET_SERVICING_HISTORY_SUCCESS,
      data,
    };
  },

  getServicingHistoryFailed: err => {
    return {
      type: actions.GET_SERVICING_HISTORY_FAILED,
      err,
    };
  },

  addServicingHistoryBegin: () => {
    return {
      type: actions.ADD_SERVICING_HISTORY_BEGIN,
    };
  },

  addServicingHistorySuccess: data => {
    return {
      type: actions.ADD_SERVICING_HISTORY_SUCCESS,
      data,
    };
  },

  addServicingHistoryFailed: err => {
    return {
      type: actions.ADD_SERVICING_HISTORY_FAILED,
      err,
    };
  },

  updateServicingHistoryBegin: () => {
    return {
      type: actions.UPDATE_SERVICING_HISTORY_BEGIN,
    };
  },

  updateServicingHistorySuccess: data => {
    return {
      type: actions.UPDATE_SERVICING_HISTORY_SUCCESS,
      data,
    };
  },

  updateServicingHistoryFailed: err => {
    return {
      type: actions.UPDATE_SERVICING_HISTORY_FAILED,
      err,
    };
  },

  deleteServicingHistoryBegin: () => {
    return {
      type: actions.DELETE_SERVICING_HISTORY_BEGIN,
    };
  },

  deleteServicingHistorySuccess: data => {
    return {
      type: actions.DELETE_SERVICING_HISTORY_SUCCESS,
      data,
    };
  },

  deleteServicingHistoryFailed: err => {
    return {
      type: actions.DELETE_SERVICING_HISTORY_FAILED,
      err,
    };
  },
};

export default actions;