/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import Pluralize from 'pluralize';
import moment from 'moment';
import { inspectionReportTableColumns } from './components/inspectionReport.schema';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { getAllInspectionReports, deleteInspectionReport } from '../../redux/inspection report/actionCreator';
import { Cards } from '../../components/cards/frame/cards-frame';
import { UserTableStyleWrapper } from '../pages/style';
import Heading from '../../components/heading/heading';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';

const InspectionReportMain = () => {
  const dispatch = useDispatch();
  const {
    inspectionReportsData,
    totalInspections,
    currentPage,
    pageLimit,
    inspectionReportLoading,
    searchKeyword,
    path,
  } = useSelector(state => {
    return {
      inspectionReportsData: state.inspectionReport.reports,
      totalInspections: state.inspectionReport.total,
      inspectionReportLoading: state.inspectionReport.loading,
      currentPage: state.inspectionReport.currentPage,
      pageLimit: state.inspectionReport.pageLimit,
      searchKeyword: state.headerSearch.searchKeyword,
      path: state.headerSearch.path,
    };
  });

  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 50, searchKeyword });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    if (searchKeyword && path === 'inspection-reports')
      dispatch(
        getAllInspectionReports(
          `?search=${searchKeyword}&page=${pagination.currentPage || 1}&limit=${pagination.pageLimit || 50}`,
        ),
      );
    else dispatch(getAllInspectionReports(`?page=${pagination.currentPage || 1}&limit=${pagination.pageLimit || 50}`));

    // eslint-disable-next-line
  }, [searchKeyword, pagination.currentPage, pagination.pageLimit]);

  useEffect(() => {
    if (pagination.searchKeyword !== searchKeyword) {
      if (path === 'inspection-reports') setPagination({ currentPage: 1, pageLimit: 50, searchKeyword });
    }
  }, [searchKeyword]);

  // For handling the close model
  const onCancel = () => {
    setShowDeleteModal(false);
    setDeleteItem(null);
  };

  const handleDeleteClick = responseData => {
    setDeleteItem({ id: responseData?._id, name: responseData?.car?.name });
    setShowDeleteModal(true);
  };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteInspectionReport(deleteItem && deleteItem?.id));
      if (res?.data?.status) {
        onCancel();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setDeleteItem(null);
    }
  };

  const inspectionReportTableData = inspectionReportsData.map((report, index) => {
    const { _id, date, car } = report;

    return {
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      date: <p>{moment(date).format('MMM Do YYYY')}</p>,
      car: (
        <div className="user-info">
          <Link to={`../admin/cars/car-detail/${car?._id}`} style={{ color: 'skyblue' }}>
            <Heading className="user-name" as="h6">
              {car?.name ?? 'NA'}
            </Heading>
          </Link>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Link to={`../admin/inspection-reports/display/${_id}`}>
            <Button className="btn-icon" type="primary" to="#" shape="circle">
              <FeatherIcon icon="eye" size={16} />
            </Button>
          </Link>
          <Link to={`../admin/inspection-reports/edit/${_id}`}>
            <Button className="btn-icon" type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
          </Link>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => handleDeleteClick(report)}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    };
  });

  // To handle the page and limit change
  const handlePageOrLimitChange = data => {
    setPagination({ ...pagination, currentPage: data.current, pageLimit: data.pageSize });
  };

  // Handling edit inspection report type
  //   const handleEdit = (id, data) => {
  //     dispatch(editInspectionReport(id, data));
  //   };

  return (
    <>
      <DeleteConfirmationModal
        title="Inspection Report"
        visible={showDeleteModal}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={inspectionReportLoading}
        itemName={deleteItem?.name ?? ''}
      />
      <PageHeader
        ghost
        title="Car Inspection Reports"
        subTitle={
          totalInspections > 0 && (
            <span className="title-counter">
              {totalInspections} {Pluralize('Report', totalInspections)}{' '}
            </span>
          )
        }
        buttons={[
          <div key="1" className="page-header-actions">
            <NavLink exact to="../../admin/inspection-reports/add">
              <Button size="small" key="4" style={{ backgroundColor: '#155BDC', color: '#fff' }}>
                <FeatherIcon icon="plus" size={14} />
                Add New
              </Button>
            </NavLink>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {inspectionReportLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <Cards headless>
                <UserTableStyleWrapper>
                  <TableWrapper className="table-responsive">
                    <Table
                      dataSource={inspectionReportTableData}
                      columns={inspectionReportTableColumns}
                      pagination={{
                        defaultPageSize: pageLimit,
                        total: totalInspections,
                        current: currentPage,
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalInspections} items`,
                      }}
                      onChange={handlePageOrLimitChange}
                    />
                  </TableWrapper>
                </UserTableStyleWrapper>
              </Cards>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default InspectionReportMain;
