import actions from './actions';

const {
  GET_REPORT_BEGIN, GET_REPORT_SUCCESS, GET_REPORT_FAILED,
  ADD_REPORT_BEGIN, ADD_REPORT_SUCCESS, ADD_REPORT_FAILED,
  UPDATE_REPORT_BEGIN, UPDATE_REPORT_SUCCESS, UPDATE_REPORT_FAILED,
  DELETE_REPORT_BEGIN, DELETE_REPORT_SUCCESS, DELETE_REPORT_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  reports: [],
  loading: false,
  error: null,
};


const InspectionReportReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        total: data.total || 0,
        reports: data.inspectionReports,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_REPORT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_REPORT_SUCCESS:
      return {
        ...state,
        reports: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_REPORT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        reports: data,
        loading: false,
      };
    case UPDATE_REPORT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        reports: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_REPORT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default InspectionReportReducer;