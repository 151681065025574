import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getUserBegin, getUserSuccess, getUserFailed,
  addUserBegin, addUserSuccess, addUserFailed,
  updateUserBegin, updateUserSuccess, updateUserFailed,
  deleteUserBegin, deleteUserSuccess, deleteUserFailed,
} = actions;

// GETTING ALL USERS
let cancelToken;
export const getAllUsers = filter => async (dispatch) => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getUserSuccess({ users: [] }));
    dispatch(getUserBegin());
    const res = await axios.get(`users${filter ? filter : ''}`, { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    const { users, total, currentPage, pageLimit } = res.data;
    dispatch(getUserSuccess({ users, total, currentPage, pageLimit }));
    return res;
  } catch (err) {
    dispatch(getUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
}

// ADDING NEW USER
export const addNewUser = data => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(addUserBegin());
    const res = await axios.post('users', data, { headers: { ...getHeaders() } });
    const newUsers = [res.data.user, ...state.user.users];

    dispatch(addUserSuccess(newUsers));
    message.success('New user created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addUserFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING USER
export const editUser = (id, datas) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateUserBegin());
    const res = await axios.put(`users/${id}`, datas, { headers: { ...getHeaders() } });
    const newUsers = state.user.users.map(user => {
      if (user._id === id) return res.data.user;
      else return user;
    })
    dispatch(updateUserSuccess(newUsers));
    message.success('User updated successfully')
    return res;
  } catch (err) {
    dispatch(updateUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE USER
export const deleteUser = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteUserBegin());
    const res = await axios.delete(`users/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteUserSuccess(state.user.users.filter(user => user._id !== id)));
    message.success('User deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 