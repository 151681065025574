import * as Yup from 'yup';

export const SPECS_LIST = {
  engine: [
    { label: 'Front Crank Oil Seal', value: 'frontCrankOilSeal' },
    { label: 'Engine Tune Up', value: 'engineTuneUp' },
    { label: 'Valve Cover Gasket', value: 'valveCoverGasket' },
    { label: 'Water Leak', value: 'waterLeak' },
    { label: 'Water Pump', value: 'waterPump' },
    { label: 'Air Filter', value: 'airFilter' },
    { label: 'Engine Over Haul', value: 'engineOverHaul' },
    { label: 'Engine Mount', value: 'engineMount' },
    { label: 'Oil Filter Bracket Oring', value: 'oilFilterBracketOring' },
    { label: 'Radiator', value: 'radiator' },
    { label: 'Belts', value: 'belts' },
    { label: 'Rear Crank Oil Seal', value: 'rearCrankOilSeal' },
    { label: 'Oil Pan Gasket', value: 'oilPanGasket' },
    { label: 'Intake Manifold Gasket', value: 'intakeManifoldGasket' },
    { label: 'Engine Timing Cover Gasket', value: 'engineTimingCoverGasket' },
    { label: 'Fuel Filter', value: 'fuelFilter' },
  ],
  transmission: [
    { label: 'Oil Pump Gasket', value: 'oilPumpGasket' },
    { label: 'Transmission Mount', value: 'transmissionMount' },
    { label: 'Oil Coller Pipe', value: 'oilCollerPipe' },
    { label: 'Front Oil Seal', value: 'frontOilSeal' },
    { label: 'Oil Pan Gasket', value: 'oilPanGasketT' },
    { label: 'Gear Performance', value: 'gearPerformance' },
    { label: 'Rear Oil Seal', value: 'rearOilSeal' },
    { label: 'Gear Stick Souta', value: 'gearStickSouta' },
  ],
  electricSystem: [
    { label: 'Battery', value: 'battery' },
    { label: 'Wiper Blades', value: 'wiperBlades' },
    { label: 'A/C Condensor', value: 'acCondensor' },
    { label: 'A/C Radiator', value: 'acRadiator' },
    { label: 'Alternator', value: 'alternator' },
    { label: 'A/C', value: 'ac' },
    { label: 'Trouble Code', value: 'troubleCode' },
    { label: 'Starler', value: 'starler' },
    { label: 'Center Lock', value: 'centerLock' },
    { label: 'Computer Cods', value: 'computerCods' },
  ],
  liquids: [
    { label: 'Engine Oil', value: 'engineOil' },
    { label: 'Engine Coolant', value: 'engineCoolant' },
    { label: 'Transmission Oil', value: 'transmissionOil' },
    { label: 'Washer Fluid', value: 'washerFluid' },
    { label: 'Brake Fluid', value: 'brakeFluid' },
  ],
  underChassis: [
    { label: 'Tie Rod', value: 'tieRod' },
    { label: 'Pitman Arm', value: 'pitmanArm' },
    { label: 'Steering Box', value: 'steeringBox' },
    { label: 'Stabilizer Linkage', value: 'stabilizerLinkage' },
    { label: 'Rear Lower Arm Bushing', value: 'rearLowerArmBushing' },
    { label: 'Ball Joint L&R', value: 'ballJointLandR' },
    { label: 'Steering Pump', value: 'steeringPump' },
    { label: 'Steering Rack & Pinion', value: 'steeringRackAndPinion' },
    { label: 'Cross Joint Propeller Shaft', value: 'crossJointPropellerShaft' },
    { label: 'Suspension Spring', value: 'suspensionSpring' },
    { label: 'Idler Arm', value: 'idlerArm' },
    { label: 'Steering Hoses', value: 'steeringHoses' },
    { label: 'Front Shock L&R', value: 'frontShockLandR' },
    { label: 'Upper Arm Bushing', value: 'upperArmBushing' },
    { label: 'Rear Shock L&R', value: 'rearShockLandR' },
  ],
  brakeSystem: [
    { label: 'Brake Pad (Front/ Rear)', value: 'brakePad' },
    { label: 'Brake Master CLY', value: 'brakeMasterCLY' },
    { label: 'Hand Brake Cable', value: 'handBrakeCable' },
    { label: 'Front / Rear Brake Disc Rotor', value: 'brakeDiscRotor' },
    { label: 'Back Booster', value: 'backBooster' },
    { label: 'Brake Hoses', value: 'brakeHoses' },
    { label: 'Wheel Bearing', value: 'wheelBearing' },
    { label: 'Brake Wheel Call', value: 'brakeWheelCall' },
  ],
  rearAxlesAndDriveShafts: [
    { label: 'Front Axle', value: 'frontAxle' },
    { label: 'Axle Difference', value: 'axleDifference' },
    { label: 'Difference / Front / Rear', value: 'differenceFrontOrRear' },
    { label: 'Rear Axle', value: 'rearAxle' },
    { label: 'Axle Seal / Front / Rear', value: 'axleSealFrontOrRear' },
  ],

  interior: [
    { label: 'Seat And Belts', value: 'seatAndBelts' },
    { label: 'Fuel & Temperature Gauge', value: 'fuelAndTemperatureGauge' },
    { label: 'Radio/Music System', value: 'radioOrMusicSystem' },
    { label: 'Dashboard & Gauges', value: 'dashboardAndGauges' },
    { label: 'SRS Airbags', value: 'sRSAirbags' },
    { label: 'SunRoof And Windows', value: 'sunRoofAndWindows' },
    { label: 'Mirrors', value: 'mirrors' },
    { label: 'Steering Wheel Tilt/lock', value: 'steeringWheelTiltOrlock' },
    { label: 'Interior Controls & Switches', value: 'interiorControlsAndSwitches' },
  ],
};

export const SPECS_LABEL = {
  engine: 'Engine',
  transmission: 'Transmission',
  electricSystem: 'Electric System',
  liquids: 'Liquids',
  underChassis: 'Under Chassis',
  brakeSystem: 'Break System',
  rearAxlesAndDriveShafts: 'Rear Axles And Drive Shafts',
  interior: 'Interior',
};

export const IMAGE_AND_CAPTION_LIST = [
  { label: 'Left Side Images', value: 'leftImages' },
  { label: 'Left Side Caption', value: 'leftCaption' },
  { label: 'Right Side Images', value: 'rightImages' },
  { label: 'Right Side Caption', value: 'rightCaption' },
  { label: 'Front Side Images', value: 'frontImages' },
  { label: 'Front Side Caption', value: 'frontCaption' },
  { label: 'Back Side Images', value: 'backImages' },
  { label: 'Back Side Caption', value: 'backCaption' },
];

export function getAllSpecFieldsArray() {
  let specFields = [];
  Object.values(SPECS_LIST).forEach(item => {
    specFields = [...specFields, ...item];
  });
  return specFields;
}

function getAllSpecFieldsInitialValues() {
  let specsFieldsObj = {};
  const specsFieldsArr = getAllSpecFieldsArray();
  specsFieldsArr.forEach(item => {
    specsFieldsObj = {
      ...specsFieldsObj,
      [item.value]: null,
    };
  });
  IMAGE_AND_CAPTION_LIST.forEach(item => {
    if (item.value.includes('Caption')) {
      specsFieldsObj = {
        ...specsFieldsObj,
        [item.value]: '',
      };
    } else
      specsFieldsObj = {
        ...specsFieldsObj,
        [item.value]: null,
      };
  });
  return specsFieldsObj;
}

export const inspectReportInitialFormData = {
  car: null,
  date: '',
  ...getAllSpecFieldsInitialValues(),
};

export const inspectReportValidationSchema = () => {
  // let specsFieldsValidation = {};
  // const specsFieldsArr = getAllSpecFieldsArray();
  // [...specsFieldsArr, ...IMAGE_AND_CAPTION_LIST].forEach(item => {
  //   specsFieldsValidation = {
  //     ...specsFieldsValidation,
  //     [`${item.value}`]: Yup.string().nullable(),
  //   };
  // });

  return Yup.object().shape({
    car: Yup.string()
      .nullable()
      .required('Please select Car!'),
    date: Yup.string()
      .nullable()
      .required('Please select Date!'),
    // ...specsFieldsValidation,
  });
};

export const inspectionReportTableColumns = [
  {
    title: 'S.No',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'Car',
    dataIndex: 'car',
    key: 'car',
  },
  {
    title: 'Inspection Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
