import { message } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  vinSearchBegin,
  vinSearchFailed,
  vinSearchSuccess,
  getCarsBegin,
  getCarsSuccess,
  getCarsFailed,
  getCarBegin,
  getCarSuccess,
  getCarFailed,
  addCarBegin,
  addCarSuccess,
  addCarFailed,
  updateCarBegin,
  updateCarSuccess,
  updateCarFailed,
  deleteCarBegin,
  deleteCarSuccess,
  deleteCarFailed,
  cloneCarBegin,
  cloneCarSuccess,
  cloneCarFailed,
} = actions;

// GETTING ALL CARS
let cancelToken;
export const getAllCars = filter => async dispatch => {
  if (cancelToken) {
    cancelToken.cancel();
  }
  cancelToken = CancelToken.source();
  try {
    dispatch(getCarSuccess({ total: 0, cars: [] }));
    dispatch(getCarBegin());
    const res = await axios.get(`cars?${filter || ''}`, {
      headers: { ...getHeaders() },
      cancelToken: cancelToken?.token,
    });
    dispatch(getCarSuccess(res.data));
  } catch (err) {
    dispatch(getCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

// GETTING ALL CARS WITHOUT LIMIT
export const getCars = () => async dispatch => {
  try {
    dispatch(getCarsBegin());
    const res = await axios.get('/cars?all=true', { headers: { ...getHeaders() } });
    dispatch(getCarsSuccess(res.data.cars));
  } catch (err) {
    dispatch(getCarsFailed(err.response.data.msg));
    dispatch(errorHandling(err, false));
  }
};

// GETTING CAR DETAILS BY VIN
export const searchByVIN = vin => async dispatch => {
  try {
    dispatch(vinSearchBegin());
    const res = await axios.get(`/cars/search/${vin}`, {
      headers: { ...getHeaders() },
    });
    dispatch(vinSearchSuccess(res.data.detail));
  } catch (err) {
    dispatch(vinSearchFailed(err.response.data.msg));
    dispatch(errorHandling(err));
  }
};

// ADDING NEW CAR
export const addNewCar = (datas, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addCarBegin());
    const res = await axios.post('cars', datas, { headers: { ...getHeaders() } });
    const newCars = [res.data.car, ...state.car.cars];

    dispatch(addCarSuccess(newCars));
    message.success('New car created successfully');
    // form.resetFields();
    history.push('/admin/cars');
    return res;
  } catch (err) {
    dispatch(addCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, true));
    return err;
  }
};

// UPDATING CAR BY ID
export const editCar = (id, datas, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBegin());
    const res = await axios.put(`cars/${id}`, datas, {
      headers: { ...getHeaders() },
    });
    const newCars = state.car.cars.map(c => {
      if (c._id === id) return res.data.car;
      return c;
    });
    dispatch(updateCarSuccess(newCars));
    message.success('Car updated successfully');
    history.push('/admin/cars');
    return res;
  } catch (err) {
    dispatch(updateCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// UPDATING CAR BY ID
export const updateCarImageOrder = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBegin());
    const res = await axios.put(`cars/sort-images/${id}`, { images: datas }, { headers: { ...getHeaders() } });
    const newCars = state.car.cars.map(c => {
      if (c._id === id) return res.data.car;
      return c;
    });
    dispatch(updateCarSuccess(newCars));
    message.success('Car image order changed successfully');
    return res;
  } catch (err) {
    dispatch(updateCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CAR ISSUE IMAGE
export const deleteCarIssueImage = (id, file) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBegin());
    const res = await axios.delete(`cars/${id}/${file}?type=issue`, {
      headers: { ...getHeaders() },
    });
    const newCars = state.car.cars.map(c => {
      if (c._id === id) return res.data.car;
      return c;
    });
    dispatch(updateCarSuccess(newCars));
    message.success('Car issue removed successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CAR FILE
export const deleteCarFile = (id, file, type) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBegin());
    const res = await axios.delete(`cars/${id}/${file}?type=${type}`, {
      headers: { ...getHeaders() },
    });
    const newCars = state.car.cars.map(c => {
      if (c._id === id) return res.data.car;
      return c;
    });
    dispatch(updateCarSuccess(newCars));
    message.success(`Car ${type} removed successfully`);
    return res;
  } catch (err) {
    dispatch(deleteCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CAR
export const deleteCar = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteCarBegin());
    const res = await axios.delete(`cars/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteCarSuccess(state.car.cars.filter(c => c._id !== id)));
    message.success('Car deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// CLONE CAR
export const cloneCar = id => async dispatch => {
  try {
    dispatch(cloneCarBegin());
    const res = await axios.post(`/cars/copy/${id}`, {}, { headers: { ...getHeaders() } });
    dispatch(cloneCarSuccess(res.data.cars));
  } catch (err) {
    dispatch(cloneCarFailed(err.response.data.msg));
    dispatch(errorHandling(err, false));
  }
};
