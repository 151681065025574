import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getBlogCategoryBegin, getBlogCategorySuccess, getBlogCategoryFailed,
  addBlogCategoryBegin, addBlogCategorySuccess, addBlogCategoryFailed,
  updateBlogCategoryBegin, updateBlogCategorySuccess, updateBlogCategoryFailed,
  deleteBlogCategoryBegin, deleteBlogCategorySuccess, deleteBlogCategoryFailed,
} = actions;


// GETTING ALL BLOG-CATEGORIES
export const getAllBlogCategories = async dispatch => {
  try {
    dispatch(getBlogCategoryBegin());
    const res = await axios.get('categories', { headers: { ...getHeaders() } });
    dispatch(getBlogCategorySuccess(res.data.categories));
    return res;
  } catch (err) {
    dispatch(getBlogCategoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
}

// ADDING NEW BLOG CATEGORY
export const addNewBlogCategory = data => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addBlogCategoryBegin());
    const res = await axios.post('categories', data, { headers: { ...getHeaders() } });

    dispatch(addBlogCategorySuccess([res.data.category, ...state.blogCategory.categories]));
    message.success('New blog category created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addBlogCategoryFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING BLOG CATEGORY
export const editBlogCategory = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateBlogCategoryBegin());
    const res = await axios.put(`categories/${id}`, datas, { headers: { ...getHeaders() } });
    const newBlogCategories = state.blogCategory.categories.map(category => {
      if (category._id === id) return res.data.category;
      else return category;
    })
    dispatch(updateBlogCategorySuccess(newBlogCategories));
    message.success('Blog category updated successfully')
    return res;
  } catch (err) {
    dispatch(updateBlogCategoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE BLOG CATEGORY
export const deleteBlogCategory = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteBlogCategoryBegin());
    const res = await axios.delete(`categories/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteBlogCategorySuccess(state.blogCategory.categories.filter(category => category._id !== id)));
    message.success('Blog category deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteBlogCategoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 