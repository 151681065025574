import actions from './actions';

const { SEARCH_HEADER_SUCCESS } = actions;

const initState = {
  searchKeyword: '',
  path: '',
  pathLength: 0,
};

const headerSearchReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEARCH_HEADER_SUCCESS:
      return {
        ...state,
        searchKeyword: data.searchKeyword || '',
        path: data.path || '',
        pathLength: data.pathLength,
      };
    default:
      return state;
  }
};

export default headerSearchReducer;
