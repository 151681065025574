import actions from './actions';

const {
  GET_PRESS_BEGIN,
  GET_PRESS_SUCCESS,
  GET_PRESS_FAILED,
  ADD_PRESS_BEGIN,
  ADD_PRESS_SUCCESS,
  ADD_PRESS_FAILED,
  UPDATE_PRESS_BEGIN,
  UPDATE_PRESS_SUCCESS,
  UPDATE_PRESS_FAILED,
  DELETE_PRESS_BEGIN,
  DELETE_PRESS_SUCCESS,
  DELETE_PRESS_FAILED,
} = actions;

const initState = {
  press: [],
  total: 0,
  loading: false,
  error: null,
};

const PressReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PRESS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PRESS_SUCCESS:
      return {
        ...state,
        press: data.press,
        total: data.total,
        loading: false,
      };
    case GET_PRESS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_PRESS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRESS_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        press: data,
        loading: false,
      };
    case ADD_PRESS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_PRESS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRESS_SUCCESS:
      return {
        ...state,
        press: data,
        loading: false,
      };
    case UPDATE_PRESS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_PRESS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRESS_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        press: data,
        loading: false,
      };
    case DELETE_PRESS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default PressReducer;
