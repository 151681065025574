import Cookies from 'js-cookie';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const { getCarPriceRangeBegin, getCarPriceRangeSuccess, getCarPriceRangeFailed } = actions;

let cancelToken;
// GETTING CAR PRICE RANGE
export const getCarsPriceRange = () => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    // dispatch(getCarPriceRangeSuccess({ minPrice: 0, maxPrice: 0 }));
    dispatch(getCarPriceRangeBegin());

    const res = await axios.get(`cars/filter-params`, {
      headers: { ...getHeaders() },
    });
    const { minPrice, maxPrice } = res.data.data;

    dispatch(getCarPriceRangeSuccess({ minPrice, maxPrice }));
    return res;
  } catch (err) {
    dispatch(getCarPriceRangeFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};
