const actions = {
  GET_SEARCH_INQUIRY_BEGIN: 'GET_SEARCH_INQUIRY_BEGIN',
  GET_SEARCH_INQUIRY_SUCCESS: 'GET_SEARCH_INQUIRY_SUCCESS',
  GET_SEARCH_INQUIRY_FAILED: 'GET_SEARCH_INQUIRY_FAILED',

  UPDATE_SEARCH_INQUIRY_BEGIN: 'UPDATE_SEARCH_INQUIRY_BEGIN',
  UPDATE_SEARCH_INQUIRY_SUCCESS: 'UPDATE_SEARCH_INQUIRY_SUCCESS',
  UPDATE_SEARCH_INQUIRY_FAILED: 'UPDATE_SEARCH_INQUIRY_FAILED',

  DELETE_SEARCH_INQUIRY_BEGIN: 'DELETE_SEARCH_INQUIRY_BEGIN',
  DELETE_SEARCH_INQUIRY_SUCCESS: 'DELETE_SEARCH_INQUIRY_SUCCESS',
  DELETE_SEARCH_INQUIRY_FAILED: 'DELETE_SEARCH_INQUIRY_FAILED',

  getSearchInquiryBegin: () => {
    return {
      type: actions.GET_SEARCH_INQUIRY_BEGIN,
    };
  },

  getSearchInquirySuccess: data => {
    return {
      type: actions.GET_SEARCH_INQUIRY_SUCCESS,
      data,
    };
  },

  getSearchInquiryFailed: err => {
    return {
      type: actions.GET_SEARCH_INQUIRY_FAILED,
      err,
    };
  },

  updateSearchInquiryBegin: () => {
    return {
      type: actions.UPDATE_SEARCH_INQUIRY_BEGIN,
    };
  },

  updateSearchInquirySuccess: data => {
    return {
      type: actions.UPDATE_SEARCH_INQUIRY_SUCCESS,
      data,
    };
  },

  updateSearchInquiryFailed: err => {
    return {
      type: actions.UPDATE_SEARCH_INQUIRY_FAILED,
      err,
    };
  },

  deleteSearchInquiryBegin: () => {
    return {
      type: actions.DELETE_SEARCH_INQUIRY_BEGIN,
    };
  },

  deleteSearchInquirySuccess: data => {
    return {
      type: actions.DELETE_SEARCH_INQUIRY_SUCCESS,
      data,
    };
  },

  deleteSearchInquiryFailed: err => {
    return {
      type: actions.DELETE_SEARCH_INQUIRY_FAILED,
      err,
    };
  },
};

export default actions;