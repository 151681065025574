import actions from './actions';

const {
  GET_FOOTER_CONTENT_BEGIN, GET_FOOTER_CONTENT_SUCCESS, GET_FOOTER_CONTENT_FAILED,
  ADD_FOOTER_CONTENT_BEGIN, ADD_FOOTER_CONTENT_SUCCESS, ADD_FOOTER_CONTENT_FAILED
} = actions;

const initState = {
  footerContent: [],
  loading: false,
  error: null,
};


const FooterContentReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_FOOTER_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FOOTER_CONTENT_SUCCESS:
      return {
        ...state,
        footerContent: data?.footerContent,
        loading: false,
      };
    case GET_FOOTER_CONTENT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_FOOTER_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FOOTER_CONTENT_SUCCESS:
      return {
        ...state,
        footerContent: data,
        loading: false,
      };
    case ADD_FOOTER_CONTENT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default FooterContentReducer;