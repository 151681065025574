import { message } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import axios from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getBookingsBegin,
  getBookingsSuccess,
  getBookingsFailed,
  changeBookingStatusBegin,
  changeBookingStatusSuccess,
  changeBookingStatusFailed,
  deleteBookingBegin,
  deleteBookingSuccess,
  deleteBookingFailed,
} = actions;

// GETTING ALL BOOKINGS
export const getAllBookings = async dispatch => {
  try {
    dispatch(getBookingsBegin());
    const res = await axios.get('booking', { headers: { ...getHeaders() } });
    dispatch(getBookingsSuccess(res.data.bookings));
  } catch (err) {
    dispatch(getBookingsFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

// CHANGE BOOKING STATUS BY ID
export const changeBookingStatusById = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(changeBookingStatusBegin());
    const res = await axios.post(`booking/${id}`, null, {
      headers: { ...getHeaders() },
    });

    dispatch(changeBookingStatusSuccess([res.data.bookings, ...state.bookingsReducer.bookings]));
    message.success('Booking status updated successfully');
    return res;
  } catch (err) {
    dispatch(changeBookingStatusFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE BOOKING BY ID
export const deleteBookingById = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteBookingBegin());
    const res = await axios.delete(`booking/${id}`, {
      headers: { ...getHeaders() },
    });
    // eslint-disable-next-line no-underscore-dangle
    dispatch(deleteBookingSuccess(state.bookingsReducer.bookings.filter(booking => booking?._id !== id)));
    message.success('Booking deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteBookingFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
