/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Pluralize from 'pluralize';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { blogTableColumns } from './overview/blog.schema';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { deleteBlog, getAllBlogs } from '../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../redux/blogcategory/actionCreator';
import { getAllUsers } from '../../redux/users/actionCreator';
import { UserTableStyleWrapper } from '../pages/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { IMAGE_URL } from '../../utils/constants';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';

const BlogMain = () => {
  const dispatch = useDispatch();
  const { total, blogs, categories, role, currentPage, pageLimit, searchKeyword, path, blogLoading } = useSelector(
    state => {
      return {
        total: state.blog.total,
        blogs: state.blog.blogs,
        categories: state.blogCategory.categories,
        role: state.auth.user.role.name,
        currentPage: state.blog.currentPage,
        pageLimit: state.blog.pageLimit,
        blogLoading: state.blog.loading,
        searchKeyword: state.headerSearch.searchKeyword,
        path: state.headerSearch.path,
      };
    },
  );
  const currentLang = Cookies.get('lang');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 50, searchKeyword });
  const [deleteItem, setDeleteItem] = useState(null);
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    dispatch(getAllUsers('?role=writer'));
    if (categories.length === 0) getAllBlogCategories(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchKeyword && path === 'blogs')
      dispatch(
        getAllBlogs(`?search=${searchKeyword}&page=${pagination.currentPage || 1}&limit=${pagination.pageLimit || 50}`),
      );
    else dispatch(getAllBlogs(`?page=${pagination.currentPage || 1}&limit=${pagination.pageLimit || 50}`));

    // eslint-disable-next-line
  }, [searchKeyword, pagination.currentPage, pagination.pageLimit]);

  useEffect(() => {
    if (pagination.searchKeyword !== searchKeyword) {
      if (path === 'blogs') setPagination({ currentPage: 1, pageLimit: 50, searchKeyword });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    const faqWithLang = blogs?.find(faq => faq.details.length > 1);
    if (faqWithLang) {
      const indexOfData = faqWithLang?.details?.findIndex(faq => faq?.lang === currentLang);
      // eslint-disable-next-line no-unused-expressions
      indexOfData > -1 ? setDataIndex(indexOfData) : setDataIndex(0);
    }
  }, [currentLang, blogs]);

  // For handling the close model
  const onCancel = () => {
    setShowDeleteModal(false);
    setDeleteItem(null);
  };

  const handleDeleteClick = responseData => {
    setDeleteItem({ id: responseData?._id, name: responseData?.details[dataIndex]?.title });
    setShowDeleteModal(true);
  };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteBlog(deleteItem && deleteItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllBlogCategories(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setDeleteItem(null);
    }
  };

  const blogTableData = blogs.map((blog, index) => {
    const { _id, category, details, image, featured, createdAt } = blog;

    return {
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      image: (
        <figure>
          <img
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            src={`${IMAGE_URL}/${image}`}
            alt="no_image"
          />
        </figure>
      ),
      title: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {details[dataIndex]?.title ?? 'NA'}
          </Heading>
        </div>
      ),
      category: (
        <div className="user-info">{category?.details?.length > 0 ? category?.details[dataIndex]?.category : 'NA'}</div>
      ),
      featured: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {featured ? 'Yes' : 'No'}
          </Heading>
        </div>
      ),
      createdAt: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
          </Heading>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Link to={`blogs/view/${_id}`}>
            <Button className="btn-icon" type="primary" to="#" shape="circle">
              <FeatherIcon icon="eye" size={16} />
            </Button>
          </Link>
          <Link to={`blogs/edit/${_id}`}>
            <Button className="btn-icon" type="info" to="#" shape="circle" onClick="blogs">
              <FeatherIcon icon="edit" size={16} />
            </Button>
          </Link>
          <Button
            className="btn-icon"
            type="danger"
            to="#"
            shape="circle"
            onClick={() => {
              handleDeleteClick(blog);
            }}
          >
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    };
  });

  // To handle the page and limit change
  const handlePageOrLimitChange = data => {
    setPagination({ ...pagination, currentPage: data.current, pageLimit: data.pageSize });
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Blog"
        visible={showDeleteModal}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={blogLoading}
        itemName={deleteItem?.name ?? ''}
      />
      <PageHeader
        ghost
        title="Blogs"
        subTitle={
          total > 0 && (
            <span className="title-counter">
              {total} {Pluralize('Blog', total)}{' '}
            </span>
          )
        }
        buttons={[
          <Link to={`../${role}/blogs/add`}>
            <Button key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
              <FeatherIcon icon="plus" size={16} /> Add New
            </Button>
            ,
          </Link>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <>
              {blogLoading ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <Cards headless>
                  <UserTableStyleWrapper>
                    <TableWrapper className="table-responsive">
                      <Table
                        dataSource={blogTableData}
                        columns={blogTableColumns}
                        pagination={{
                          defaultPageSize: pageLimit,
                          total,
                          current: currentPage,
                          showSizeChanger: true,
                          showTotal: (totalData, range) => `${range[0]}-${range[1]} of ${totalData} items`,
                        }}
                        onChange={handlePageOrLimitChange}
                      />
                    </TableWrapper>
                  </UserTableStyleWrapper>
                </Cards>
              )}
            </>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default BlogMain;
