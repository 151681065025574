const actions = {
  GET_BLOG_CATEGORY_BEGIN: 'GET_BLOG_CATEGORY_BEGIN',
  GET_BLOG_CATEGORY_SUCCESS: 'GET_BLOG_CATEGORY_SUCCESS',
  GET_BLOG_CATEGORY_FAILED: 'GET_BLOG_CATEGORY_FAILED',

  ADD_BLOG_CATEGORY_BEGIN: 'ADD_BLOG_CATEGORY_BEGIN',
  ADD_BLOG_CATEGORY_SUCCESS: 'ADD_BLOG_CATEGORY_SUCCESS',
  ADD_BLOG_CATEGORY_FAILED: 'ADD_BLOG_CATEGORY_FAILED',

  UPDATE_BLOG_CATEGORY_BEGIN: 'UPDATE_BLOG_CATEGORY_BEGIN',
  UPDATE_BLOG_CATEGORY_SUCCESS: 'UPDATE_BLOG_CATEGORY_SUCCESS',
  UPDATE_BLOG_CATEGORY_FAILED: 'UPDATE_BLOG_CATEGORY_FAILED',

  DELETE_BLOG_CATEGORY_BEGIN: 'DELETE_BLOG_CATEGORY_BEGIN',
  DELETE_BLOG_CATEGORY_SUCCESS: 'DELETE_BLOG_CATEGORY_SUCCESS',
  DELETE_BLOG_CATEGORY_FAILED: 'DELETE_BLOG_CATEGORY_FAILED',

  getBlogCategoryBegin: () => {
    return {
      type: actions.GET_BLOG_CATEGORY_BEGIN,
    };
  },

  getBlogCategorySuccess: data => {
    return {
      type: actions.GET_BLOG_CATEGORY_SUCCESS,
      data,
    };
  },

  getBlogCategoryFailed: err => {
    return {
      type: actions.GET_BLOG_CATEGORY_FAILED,
      err,
    };
  },

  addBlogCategoryBegin: () => {
    return {
      type: actions.ADD_BLOG_CATEGORY_BEGIN,
    };
  },

  addBlogCategorySuccess: data => {
    return {
      type: actions.ADD_BLOG_CATEGORY_SUCCESS,
      data,
    };
  },

  addBlogCategoryFailed: err => {
    return {
      type: actions.ADD_BLOG_CATEGORY_FAILED,
      err,
    };
  },

  updateBlogCategoryBegin: () => {
    return {
      type: actions.UPDATE_BLOG_CATEGORY_BEGIN,
    };
  },

  updateBlogCategorySuccess: data => {
    return {
      type: actions.UPDATE_BLOG_CATEGORY_SUCCESS,
      data,
    };
  },

  updateBlogCategoryFailed: err => {
    return {
      type: actions.UPDATE_BLOG_CATEGORY_FAILED,
      err,
    };
  },

  deleteBlogCategoryBegin: () => {
    return {
      type: actions.DELETE_BLOG_CATEGORY_BEGIN,
    };
  },

  deleteBlogCategorySuccess: data => {
    return {
      type: actions.DELETE_BLOG_CATEGORY_SUCCESS,
      data,
    };
  },

  deleteBlogCategoryFailed: err => {
    return {
      type: actions.DELETE_BLOG_CATEGORY_FAILED,
      err,
    };
  },
};

export default actions;