import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import { PropTypes } from 'prop-types';
import './upload.style.css';

const FormUpload = props => {
  const {
    label,
    name,
    isDisabled = false,
    isRequired = true,
    id,
    value,
    style,
    errorMsg,
    helperText,
    className,
    handleChange,
    isMulti = false,
    ...rest
  } = props;

  const [preview, setPreview] = useState({ visible: false, image: null });

  const handlePreview = async file => {
    setPreview({
      visible: true,
      image: file.url || file.thumbUrl,
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const showUploadButton = () => {
    if (value) {
      if (isMulti) return uploadButton;
      if (typeof value === 'string') {
        return null;
      }
      if (value.length < 1) return uploadButton;
      return null;
    }
    return uploadButton;
  };

  return (
    <div className={isRequired ? 'input-required' : 'form-input'}>
      <div className="input-label">
        <label htmlFor={id ?? name}>{label}</label>
      </div>
      <div>
        <Upload
          listType="picture-card"
          fileList={value ?? []}
          onPreview={handlePreview}
          onChange={handleChange}
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: true,
          }}
          className="custom-upload"
          accept={rest.accept}
          disabled={isDisabled}
          multiple={isMulti}
          beforeUpload={() => {
            return false;
          }}
          {...rest}
        >
          {showUploadButton()}
        </Upload>

        {preview.visible && (
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{ visible: preview.visible, onVisibleChange: () => setPreview({ ...preview, visible: false }) }}
            >
              <Image src={`${preview.image}`} />
              {/* {value?.map(image => (
                <Image src={`${image.url || image.thumbUrl}`} />
              ))} */}
            </Image.PreviewGroup>
          </div>
        )}
      </div>
      {rest?.errors && rest?.touched && rest.touched?.[`${name}`] && rest.errors?.[`${name}`] && (
        <div className="input-required-msg">
          <span>{rest.errors?.[`${name}`]}</span>
        </div>
      )}
    </div>
  );
};

export default FormUpload;

FormUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  style: PropTypes.string,
  errorMsg: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  accept: PropTypes.string,
};
