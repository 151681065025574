const actions = {
  GET_FINANCING_BEGIN: 'GET_FINANCING_BEGIN',
  GET_FINANCING_SUCCESS: 'GET_FINANCING_SUCCESS',
  GET_FINANCING_FAILED: 'GET_FINANCING_FAILED',

  ADD_FINANCING_BEGIN: 'ADD_FINANCING_BEGIN',
  ADD_FINANCING_SUCCESS: 'ADD_FINANCING_SUCCESS',
  ADD_FINANCING_FAILED: 'ADD_FINANCING_FAILED',

  UPDATE_FINANCING_BEGIN: 'UPDATE_FINANCING_BEGIN',
  UPDATE_FINANCING_SUCCESS: 'UPDATE_FINANCING_SUCCESS',
  UPDATE_FINANCING_FAILED: 'UPDATE_FINANCING_FAILED',

  DELETE_FINANCING_BEGIN: 'DELETE_FINANCING_BEGIN',
  DELETE_FINANCING_SUCCESS: 'DELETE_FINANCING_SUCCESS',
  DELETE_FINANCING_FAILED: 'DELETE_FINANCING_FAILED',

  getFinancingBegin: () => {
    return {
      type: actions.GET_FINANCING_BEGIN,
    };
  },

  getFinancingSuccess: data => {
    return {
      type: actions.GET_FINANCING_SUCCESS,
      data,
    };
  },

  getFinancingFailed: err => {
    return {
      type: actions.GET_FINANCING_FAILED,
      err,
    };
  },

  addFinancingBegin: () => {
    return {
      type: actions.ADD_FINANCING_BEGIN,
    };
  },

  addFinancingSuccess: data => {
    return {
      type: actions.ADD_FINANCING_SUCCESS,
      data,
    };
  },

  addFinancingFailed: err => {
    return {
      type: actions.ADD_FINANCING_FAILED,
      err,
    };
  },

  updateFinancingBegin: () => {
    return {
      type: actions.UPDATE_FINANCING_BEGIN,
    };
  },

  updateFinancingSuccess: data => {
    return {
      type: actions.UPDATE_FINANCING_SUCCESS,
      data,
    };
  },

  updateFinancingFailed: err => {
    return {
      type: actions.UPDATE_FINANCING_FAILED,
      err,
    };
  },

  deleteFinancingBegin: () => {
    return {
      type: actions.DELETE_FINANCING_BEGIN,
    };
  },

  deleteFinancingSuccess: data => {
    return {
      type: actions.DELETE_FINANCING_SUCCESS,
      data,
    };
  },

  deleteFinancingFailed: err => {
    return {
      type: actions.DELETE_FINANCING_FAILED,
      err,
    };
  },
};

export default actions;