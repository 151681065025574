// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Spin } from 'antd';
// import Cookies from 'js-cookie';
import moment from 'moment';

// USER COMPONENTS
import { getAllBlogs } from '../../../redux/blog/actionCreator';
import { IMAGE_URL } from '../../../utils/constants';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';

const DisplayBlog = ({ match }) => {
  // const dataIndex = (Cookies.get('lang') == 'fr' ? 0 : 1);
  const dataIndex = 0;
  const dispatch = useDispatch();
  const [blog, setBlog] = useState({});

  const { blogs, loading } = useSelector(state => {
    return {
      blogs: state.blog.blogs,
      loading: state.blog.loading,
    };
  });

  useEffect(() => {
    if (blogs.length === 0) dispatch(getAllBlogs());
    setBlog(blogs.find(blog => blog._id === match.params.id) || {});
  }, [blogs.length]);

  // Receives an HTML string as an argument and returns an object with the sanitized HTML
  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  if (Object.keys(blog).length > 0) {
    const { details, category, author, image, featured, createdAt } = blog;

    return (
      <>
        <PageHeader ghost title="Blog Detail" />
        <Main>
          <div style={{ padding: '20px 40px 40px', backgroundColor: '#fff', borderRadius: 10 }}>
            <h2 style={{ textAlign: 'center', fontSize: 30, padding: 10, fontWeight: 'bold' }}>
              {details[dataIndex]?.title ?? 'NA'}
            </h2>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Image
                src={`${IMAGE_URL}/${image}`}
                alt="blog_image"
                width="100%"
                style={{
                  height: '350px',
                  objectFit: 'cover',
                  borderRadius: '6px',
                }}
              />
            </div>
            <br />
            <div className="preview" dangerouslySetInnerHTML={createMarkup(details[dataIndex]?.description) ?? 'NA'} />
            <br />
            <div
              className="info"
              style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <p style={{ marginBottom: 0 }}>
                {' '}
                <span style={{ fontWeight: 'bold' }}>Author:</span> {author?.name}
              </p>
              <p style={{ marginBottom: 0 }}>
                {' '}
                <span style={{ fontWeight: 'bold' }}>Category:</span> {category?.details[dataIndex]?.category ?? 'NA'}
              </p>
            </div>
            <div className="info" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ marginBottom: 0 }}>
                {' '}
                <span style={{ fontWeight: 'bold' }}>Is Featured:</span> {featured ? 'Yes' : 'No'}
              </p>
              <p style={{ marginBottom: 0 }}>
                {' '}
                <span style={{ fontWeight: 'bold' }}>CreatedAt:</span> {moment(createdAt).format('ddd D MMM YYYY')}
              </p>
            </div>
          </div>
        </Main>
      </>
    );
  }
  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <h4 style={{ fontSize: 30, height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Blog Not Found
    </h4>
  );
};

export default DisplayBlog;
