import actions from './actions';

const {
  GET_SEARCH_INQUIRY_BEGIN, GET_SEARCH_INQUIRY_SUCCESS, GET_SEARCH_INQUIRY_FAILED,
  UPDATE_SEARCH_INQUIRY_BEGIN, UPDATE_SEARCH_INQUIRY_SUCCESS, UPDATE_SEARCH_INQUIRY_FAILED,
  DELETE_SEARCH_INQUIRY_BEGIN, DELETE_SEARCH_INQUIRY_SUCCESS, DELETE_SEARCH_INQUIRY_FAILED
} = actions;

const initState = {
  total: 0,
  inquiries: [],
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const SearchInquiryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SEARCH_INQUIRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_INQUIRY_SUCCESS:
      return {
        ...state,
        total: data.total,
        inquiries: data.searchInquiries,
        currentPage: data.currentPage,
        pageLimit: data.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_SEARCH_INQUIRY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SEARCH_INQUIRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SEARCH_INQUIRY_SUCCESS:
      return {
        ...state,
        inquiries: data,
        loading: false,
      };
    case UPDATE_SEARCH_INQUIRY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_SEARCH_INQUIRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SEARCH_INQUIRY_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        inquiries: data,
        loading: false,
      };
    case DELETE_SEARCH_INQUIRY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default SearchInquiryReducer;