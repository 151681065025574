/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ContactQueryTable from './overview/ContactQueryTable';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { getAllContactQueries } from '../../redux/contact query/actionCreator';


const ContactQuery = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({ currentPage: 1, pageLimit: 50 });

    const { contactQueries, total, currentPage, pageLimit, loading, searchKeyword, path } = useSelector(state => {
        return {
            contactQueries: state.contactQueries.contactQueries,
            total: state.contactQueries.total,
            currentPage: state.contactQueries.currentPage,
            pageLimit: state.contactQueries.pageLimit,
            loading: state.contactQueries.loading,
            searchKeyword: state.headerSearch.searchKeyword,
            path: state.headerSearch.path,
        }
    })
    useEffect(() => {
        if (searchKeyword && path === "contact-query") {
            dispatch(getAllContactQueries(`?search=${searchKeyword}&page=${state.currentPage || 1}&limit=${state.pageLimit || 50}`))
        }
        else {
            dispatch(getAllContactQueries(`?page=${state.currentPage || 1}&limit=${state.pageLimit || 50}`));
        }
    }, [searchKeyword, state.pageLimit, state.currentPage]);


    return (
        <>
            <PageHeader
                ghost
                title="Contact Queries"
            />
            <Main>
                <Row gutter={15}>
                    <Col xs={24}>
                        {
                            loading ? (
                                <div className="spin">
                                    <Spin />
                                </div>
                            ) :
                                <ContactQueryTable contactQueries={contactQueries} totalQuery={total} queryState={setState} currentPage={currentPage} pageLimit={pageLimit} />
                        }
                    </Col>
                </Row>
            </Main>
        </>
    );
};

export default ContactQuery;