const actions = {
  GET_CAR_PRICE_RANGE_BEGIN: 'GET_CAR_PRICE_RANGE_BEGIN',
  GET_CAR_PRICE_RANGE_SUCCESS: 'GET_CAR_PRICE_RANGE_SUCCESS',
  GET_CAR_PRICE_RANGE_FAILED: 'GET_CAR_PRICE_RANGE_FAILED',

  getCarPriceRangeBegin: () => {
    return {
      type: actions.GET_CAR_PRICE_RANGE_BEGIN,
    };
  },

  getCarPriceRangeSuccess: data => {
    return {
      type: actions.GET_CAR_PRICE_RANGE_SUCCESS,
      data,
    };
  },

  getCarPriceRangeFailed: err => {
    return {
      type: actions.GET_CAR_PRICE_RANGE_FAILED,
      err,
    };
  },
};

export default actions;
