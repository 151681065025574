// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState } from 'react';
import { Form, Input, Switch, Row, Col } from 'antd';
import propTypes from 'prop-types';

// USER COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

const EditBlogCategory = ({ visible, onCancel, blogCategory, handleEdit }) => {
  const [form] = Form.useForm();
  const [engInput, setEngInput] = useState(blogCategory.details?.length > 1 ? true : false);

  const handleSubmit = datas => {
    const { category, categoryEng } = datas;
    let details = [{ lang: 'fr', category }];
    if (engInput) details.push({ lang: 'en', category: categoryEng });
    handleEdit(blogCategory._id, { details });
    onCancel();
  };

  const onChange = checked => {
    setEngInput(checked);
  };

  return (
    <Modal type={'primary'} title="Edit Blog Category" visible={visible} footer={null} onCancel={onCancel}>
      <div className="category-modal">
        <BasicFormWrapper>
          {/* language switcher */}
          <div className="add-blog-category">
            <Row>
              <Col xs={24}>
                <h4>Translate</h4>
                <Switch defaultChecked={engInput} onChange={onChange} />
              </Col>
            </Row>
          </div>
          <br />
          <Form
            form={form}
            name="CreateBlogCategory"
            onFinish={handleSubmit}
            initialValues={{ ...blogCategory.details[0], categoryEng: blogCategory.details[1]?.category }}
            layout="vertical"
          >
            <Form.Item
              name="category"
              label="Catégorie"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir la catégorie du blog!',
                },
              ]}
            >
              <Input placeholder="Catégorie..." />
            </Form.Item>
            {engInput && (
              <>
                <Form.Item
                  name="categoryEng"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please input blog category!',
                    },
                  ]}
                >
                  <Input placeholder="category..." />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={{ backgroundColor: '#155BDC', color: '#fff', marginRight: 5 }} htmlType="submit">
                  Update Blog Category
                </Button>
                <Button size="default" type="white" key="back" outlined onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
};

EditBlogCategory.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default EditBlogCategory;
