const actions = {
  GET_PRESS_BEGIN: 'GET_PRESS_BEGIN',
  GET_PRESS_SUCCESS: 'GET_PRESS_SUCCESS',
  GET_PRESS_FAILED: 'GET_PRESS_FAILED',

  ADD_PRESS_BEGIN: 'ADD_PRESS_BEGIN',
  ADD_PRESS_SUCCESS: 'ADD_PRESS_SUCCESS',
  ADD_PRESS_FAILED: 'ADD_PRESS_FAILED',

  UPDATE_PRESS_BEGIN: 'UPDATE_PRESS_BEGIN',
  UPDATE_PRESS_SUCCESS: 'UPDATE_PRESS_SUCCESS',
  UPDATE_PRESS_FAILED: 'UPDATE_PRESS_FAILED',

  DELETE_PRESS_BEGIN: 'DELETE_PRESS_BEGIN',
  DELETE_PRESS_SUCCESS: 'DELETE_PRESS_SUCCESS',
  DELETE_PRESS_FAILED: 'DELETE_PRESS_FAILED',

  getPressBegin: () => {
    return {
      type: actions.GET_PRESS_BEGIN,
    };
  },

  getPressSuccess: data => {
    return {
      type: actions.GET_PRESS_SUCCESS,
      data,
    };
  },

  getPressFailed: err => {
    return {
      type: actions.GET_PRESS_FAILED,
      err,
    };
  },

  addPressBegin: () => {
    return {
      type: actions.ADD_PRESS_BEGIN,
    };
  },

  addPressSuccess: data => {
    return {
      type: actions.ADD_PRESS_SUCCESS,
      data,
    };
  },

  addPressFailed: err => {
    return {
      type: actions.ADD_PRESS_FAILED,
      err,
    };
  },

  updatePressBegin: () => {
    return {
      type: actions.UPDATE_PRESS_BEGIN,
    };
  },

  updatePressSuccess: data => {
    return {
      type: actions.UPDATE_PRESS_SUCCESS,
      data,
    };
  },

  updatePressFailed: err => {
    return {
      type: actions.UPDATE_PRESS_FAILED,
      err,
    };
  },

  deletePressBegin: () => {
    return {
      type: actions.DELETE_PRESS_BEGIN,
    };
  },

  deletePressSuccess: data => {
    return {
      type: actions.DELETE_PRESS_SUCCESS,
      data,
    };
  },

  deletePressFailed: err => {
    return {
      type: actions.DELETE_PRESS_FAILED,
      err,
    };
  },
};

export default actions;
