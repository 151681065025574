import React from 'react';
import propTypes from 'prop-types';

const DivBreak = props => {
  const { height } = props;
  return <div style={{ height: `${height ? `${String(height)}px` : '16px'}` }} />;
};

DivBreak.propTypes = {
  height: propTypes.number,
};

export default DivBreak;
