// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState } from 'react';
import { Table, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment';

// USER COMPONENTS
import { UserTableStyleWrapper } from '../../pages/style';
import { TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import DeleteBlog from './DeleteBlog';
import { IMAGE_URL } from '../../../utils/constants';

const BlogTable = ({ totalBlog, blogs, handleDelete, loading, pageLimit, currentPage, blogState }) => {
  const dataIndex = (Cookies.get('lang') == 'fr' ? 0 : 1);
  const [state, setState] = useState({ deleteModelVisible: false });
  const newBlogs = [];

  const [blog, setBlog] = useState({});

  // To show the delete modal
  const showModal = (id) => {
    setBlog(blogs.find(bc => bc._id === id));
    setState({ ...state, deleteModelVisible: true });
  };

  // To hide the delete modal
  const onCancel = () => {
    setBlog({});
    setState({ ...state, deleteModelVisible: false, });
  };

  // To handle the page and limit change
  const handlePageOrLimitChange = (data) => { blogState({ ...state, currentPage: data.current, pageLimit: data.pageSize }) };

  blogs.map((blog, index) => {
    const { _id, category, details, image, featured, createdAt } = blog;

    return newBlogs.push({
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      image: (
        <figure>
          <img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={`${IMAGE_URL}/${image}`} alt="no_image" />
        </figure>
      ),
      title: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {details[dataIndex]?.title ?? 'NA'}
          </Heading>
        </div>
      ),
      category: (
        <div className="user-info">
          {category?.details?.length > 0 ? category?.details[dataIndex]?.category : 'NA'}
        </div>
      ),
      featured: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {featured ? 'Yes' : 'No'}
          </Heading>
        </div>
      ),
      createdAt: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
          </Heading>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Link to={`blogs/view/${_id}`}>
            <Button className="btn-icon" type="success" to="#" shape="circle">
              <FeatherIcon icon="eye" size={16} />
            </Button>
          </Link>
          <Link to={`blogs/edit/${_id}`}>
            <Button className="btn-icon" type="info" to="#" shape="circle" onClick='blogs'>
              <FeatherIcon icon="edit" size={16} />
            </Button>
          </Link>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(_id)}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    });
  });

  const usersTableColumns = [
    {
      title: 'S.No',
      dataIndex: 'sNo',
      key: 'sNo',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];

  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={newBlogs}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: pageLimit,
              total: totalBlog,
              current: currentPage,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalBlog} items`,
            }}
            onChange={handlePageOrLimitChange}
          />
        </TableWrapper>
        {
          Object.keys(blog).length > 0 && (
            <>
              <DeleteBlog onCancel={onCancel} visible={state.deleteModelVisible} blog={blog} handleDelete={handleDelete} />
            </>
          )
        }
      </UserTableStyleWrapper>
    </Cards>
  )
}

export default BlogTable;