import actions from './actions';

const {
  GET_FAQ_BEGIN, GET_FAQ_SUCCESS, GET_FAQ_FAILED,
  ADD_FAQ_BEGIN, ADD_FAQ_SUCCESS, ADD_FAQ_FAILED,
  UPDATE_FAQ_BEGIN, UPDATE_FAQ_SUCCESS, UPDATE_FAQ_FAILED,
  DELETE_FAQ_BEGIN, DELETE_FAQ_SUCCESS, DELETE_FAQ_FAILED
} = actions;

const initState = {
  faqs: [],
  loading: false,
  error: null,
};


const FaqReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_FAQ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FAQ_SUCCESS:
      return {
        ...state,
        faqs: data,
        loading: false,
      };
    case GET_FAQ_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_FAQ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FAQ_SUCCESS:
      return {
        ...state,
        faqs: data,
        loading: false,
      };
    case ADD_FAQ_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_FAQ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: data,
        loading: false,
      };
    case UPDATE_FAQ_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_FAQ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: data,
        loading: false,
      };
    case DELETE_FAQ_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default FaqReducer;