import actions from './actions';

const {
  GET_CAR_BEGIN,
  GET_CAR_SUCCESS,
  GET_CAR_FAILED,
  GET_CARS_SUCCESS,
  GET_CARS_BEGIN,
  GET_CARS_FAILED,
  VIN_SEARCH_BEGIN,
  VIN_SEARCH_SUCCESS,
  VIN_SEARCH_FAILED,
  ADD_CAR_BEGIN,
  ADD_CAR_SUCCESS,
  ADD_CAR_FAILED,
  UPDATE_CAR_BEGIN,
  UPDATE_CAR_SUCCESS,
  UPDATE_CAR_FAILED,
  DELETE_CAR_BEGIN,
  DELETE_CAR_SUCCESS,
  DELETE_CAR_FAILED,
} = actions;

const initState = {
  total: 0,
  brands: [],
  status: '',
  featured: '',
  currentPage: 1,
  pageLimit: 9,
  cars: [],
  vinData: {},
  allCars: [],
  loading: false,
  error: null,
};

const CarReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VIN_SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIN_SEARCH_SUCCESS:
      return {
        ...state,
        vinData: data,
        loading: false,
      };
    case VIN_SEARCH_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CAR_SUCCESS:
      return {
        ...state,
        total: data.total,
        cars: data.cars,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        brands: data.brands || [],
        status: data.status || null,
        featured: data.featured || '',
        loading: data.currentPage ? false : true,
      };
    case GET_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_CARS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CARS_SUCCESS:
      return {
        ...state,
        allCars: data,
        loading: false,
      };
    case GET_CARS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CAR_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        cars: data,
        loading: false,
      };
    case ADD_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CAR_SUCCESS:
      return {
        ...state,
        cars: data,
        loading: false,
      };
    case UPDATE_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CAR_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        cars: data,
        loading: false,
      };
    case DELETE_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CarReducer;
