const actions = {
  GET_OUR_TEAM_BEGIN: 'GET_OUR_TEAM_BEGIN',
  GET_OUR_TEAM_SUCCESS: 'GET_OUR_TEAM_SUCCESS',
  GET_OUR_TEAM_FAILED: 'GET_OUR_TEAM_FAILED',

  ADD_OUR_TEAM_BEGIN: 'ADD_OUR_TEAM_BEGIN',
  ADD_OUR_TEAM_SUCCESS: 'ADD_OUR_TEAM_SUCCESS',
  ADD_OUR_TEAM_FAILED: 'ADD_OUR_TEAM_FAILED',

  UPDATE_OUR_TEAM_BEGIN: 'UPDATE_OUR_TEAM_BEGIN',
  UPDATE_OUR_TEAM_SUCCESS: 'UPDATE_OUR_TEAM_SUCCESS',
  UPDATE_OUR_TEAM_FAILED: 'UPDATE_OUR_TEAM_FAILED',

  DELETE_OUR_TEAM_BEGIN: 'DELETE_OUR_TEAM_BEGIN',
  DELETE_OUR_TEAM_SUCCESS: 'DELETE_OUR_TEAM_SUCCESS',
  DELETE_OUR_TEAM_FAILED: 'DELETE_OUR_TEAM_FAILED',

  getOurTeamBegin: () => {
    return {
      type: actions.GET_OUR_TEAM_BEGIN,
    };
  },

  getOurTeamSuccess: data => {
    return {
      type: actions.GET_OUR_TEAM_SUCCESS,
      data,
    };
  },

  getOurTeamFailed: err => {
    return {
      type: actions.GET_OUR_TEAM_FAILED,
      err,
    };
  },

  addOurTeamBegin: () => {
    return {
      type: actions.ADD_OUR_TEAM_BEGIN,
    };
  },

  addOurTeamSuccess: data => {
    return {
      type: actions.ADD_OUR_TEAM_SUCCESS,
      data,
    };
  },

  addOurTeamFailed: err => {
    return {
      type: actions.ADD_OUR_TEAM_FAILED,
      err,
    };
  },

  updateOurTeamBegin: () => {
    return {
      type: actions.UPDATE_OUR_TEAM_BEGIN,
    };
  },

  updateOurTeamSuccess: data => {
    return {
      type: actions.UPDATE_OUR_TEAM_SUCCESS,
      data,
    };
  },

  updateOurTeamFailed: err => {
    return {
      type: actions.UPDATE_OUR_TEAM_FAILED,
      err,
    };
  },

  deleteOurTeamBegin: () => {
    return {
      type: actions.DELETE_OUR_TEAM_BEGIN,
    };
  },

  deleteOurTeamSuccess: data => {
    return {
      type: actions.DELETE_OUR_TEAM_SUCCESS,
      data,
    };
  },

  deleteOurTeamFailed: err => {
    return {
      type: actions.DELETE_OUR_TEAM_FAILED,
      err,
    };
  },
};

export default actions;
