import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getCarBodyTypeBegin, getCarBodyTypeSuccess, getCarBodyTypeFailed,
  addCarBodyTypeBegin, addCarBodyTypeSuccess, addCarBodyTypeFailed,
  updateCarBodyTypeBegin, updateCarBodyTypeSuccess, updateCarBodyTypeFailed,
  deleteCarBodyTypeBegin, deleteCarBodyTypeSuccess, deleteCarBodyTypeFailed,
} = actions;


// GETTING ALL CAR BODY TYPES
export const getAllBodyTypes = async (dispatch) => {
  try {
    dispatch(getCarBodyTypeBegin());
    const res = await axios.get('car-body-types', { headers: { ...getHeaders() }});
    dispatch(getCarBodyTypeSuccess(res.data.carBodyTypes));
  } catch (err) {
    dispatch(getCarBodyTypeFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false))
  }
}

// ADDING NEW CAR BODY TYPE
export const addNewCarBodyType = (data, form) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addCarBodyTypeBegin());
    const res = await axios.post('car-body-types', data, { headers: { ...getHeaders() } });
    const newBodyTypes = [res.data.carBodyType, ...state.carBodyType.bodyTypes]

    dispatch(addCarBodyTypeSuccess(newBodyTypes));
    message.success('New car body type created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addCarBodyTypeFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING CAR BODY TYPE BY ID
export const editCarBodyType = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBodyTypeBegin());
    const res = await axios.put(`car-body-types/${id}`, datas, { headers: { ...getHeaders() } });
    const newCarBodyTypes = state.carBodyType.bodyTypes.map(bodyType => {
      if (bodyType._id === id)
        return res.data.carBodyType;
      else
        return bodyType;
    })
    dispatch(updateCarBodyTypeSuccess(newCarBodyTypes));
    message.success('Car body type updated successfully');
    return res;
  } catch (err) {
    dispatch(updateCarBodyTypeFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE CAR BODY TYPE
export const deleteCarBodyType = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteCarBodyTypeBegin());
    const res = await axios.delete(`car-body-types/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteCarBodyTypeSuccess(state.carBodyType.bodyTypes.filter(carBodyType => carBodyType._id !== id)));
    message.success('Car body type deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarBodyTypeFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 