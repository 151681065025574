import actions from './actions';

const {
  GET_SERVICING_HISTORY_BEGIN, GET_SERVICING_HISTORY_SUCCESS, GET_SERVICING_HISTORY_FAILED,
  ADD_SERVICING_HISTORY_BEGIN, ADD_SERVICING_HISTORY_SUCCESS, ADD_SERVICING_HISTORY_FAILED,
  UPDATE_SERVICING_HISTORY_BEGIN, UPDATE_SERVICING_HISTORY_SUCCESS, UPDATE_SERVICING_HISTORY_FAILED,
  DELETE_SERVICING_HISTORY_BEGIN, DELETE_SERVICING_HISTORY_SUCCESS, DELETE_SERVICING_HISTORY_FAILED
} = actions;

const initState = {
  histories: [],
  loading: false,
  error: null,
};


const ServicingHistoryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SERVICING_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICING_HISTORY_SUCCESS:
      return {
        ...state,
        histories: data,
        loading: false,
      };
    case GET_SERVICING_HISTORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_SERVICING_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_SERVICING_HISTORY_SUCCESS:
      return {
        ...state,
        histories: data,
        loading: false,
      };
    case ADD_SERVICING_HISTORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SERVICING_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SERVICING_HISTORY_SUCCESS:
      return {
        ...state,
        histories: data,
        loading: false,
      };
    case UPDATE_SERVICING_HISTORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_SERVICING_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SERVICING_HISTORY_SUCCESS:
      return {
        ...state,
        histories: data,
        loading: false,
      };
    case DELETE_SERVICING_HISTORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ServicingHistoryReducer;