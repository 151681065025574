/* eslint-disable no-underscore-dangle */
import { Col, Row, Switch } from 'antd';
import { FieldArray, Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertToHTML } from 'draft-convert';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { contactInfoValidationSchema } from './contactInfo.schema';
import { Button } from '../../../components/buttons/buttons';
import FormInput from '../../../components/input/input';
import FormSelect from '../../../components/select/FormSelect';
import {
  addNewContactInformation,
  editContactInformation,
  getAllContactInformations,
} from '../../../redux/contact information/actionCreator';
import { validatePhoneNumber } from '../../../utility/utility';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import CountryCode from '../../../utils/countryCode.json';
import { BasicFormWrapper } from '../../styled';
import DivBreak from '../../../components/divBreak/DivBreak';

const ContactInfoForm = props => {
  const { formData, onCancel } = props;
  const dispatch = useDispatch();

  const { loading: contactInfoLoading } = useSelector(state => state.contactInformation);

  const [countries] = useState(CountryCode);

  const [isMultiLangFlag, setIsMultiLangFlag] = useState(false);

  useEffect(() => {
    setIsMultiLangFlag(formData?.isMultiLang.includes('true'));
  }, [formData]);

  function getCountryDropdownOpt() {
    const dropdownOpt = countries?.map(country => ({
      value: country?.dial_code ?? '',
      key: `${country?.name}${country?.dial_code}`,
      label: `${' '}${country?.name}(${country?.dial_code})`,
    }));
    return dropdownOpt ?? [];
  }

  async function handleFormSubmit(requestData, resetForm) {
    try {
      let apiReqData = {
        fullName: requestData.fullName,
        email: requestData.email,
        code: requestData.code,
        number: requestData.number,
        whatsapp: requestData.whatsapp,
        facebook: requestData?.facebook,
        instagram: requestData?.instagram,
        twitter: requestData?.twitter,
        linkedIn: requestData?.linkedIn,
        youtube: requestData?.youtube,
        tiktok: requestData?.tiktok,
        location: requestData?.location,
      };

      const transformedCopyrightAndFooter = {
        transformedCopyright: [],
        transformedFooter: [],
      };

      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          transformedCopyrightAndFooter.transformedFooter.push({
            lang: langItem[1],
            footer: requestData[`footer${langItem[1]}`],
          });
          transformedCopyrightAndFooter.transformedCopyright.push({
            lang: langItem[1],
            copyright: convertToHTML(requestData[`copyright${langItem[1]}`]?.getCurrentContent()),
          });
        }
      });
      apiReqData = {
        ...apiReqData,
        copyright_details: JSON.stringify(transformedCopyrightAndFooter.transformedCopyright),
        footer_details: JSON.stringify(transformedCopyrightAndFooter.transformedFooter),
      };

      // eslint-disable-next-line no-underscore-dangle
      const res = await dispatch(
        requestData?._id ? editContactInformation(requestData?._id, apiReqData) : addNewContactInformation(apiReqData),
      );
      if (res?.data?.status) {
        resetForm();
        onCancel();
        getAllContactInformations(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={contactInfoValidationSchema(isMultiLangFlag)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        // eslint-disable-next-line no-use-before-define
        handleFormSubmit(values, resetForm);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, setFieldValue, setErrors }) => {
        return (
          <Form>
            <BasicFormWrapper>
              <div className="add-product-block">
                <Row gutter={[16, 16]}>
                  {/* language switcher */}
                  <Col xs={24}>
                    <h4>Translate</h4>
                    <Switch
                      checked={values.isMultiLang === 'true'}
                      onChange={checked => {
                        setFieldValue('isMultiLang', String(checked));
                        setIsMultiLangFlag(checked);
                      }}
                    />
                  </Col>

                  {/* fullName */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="fullName"
                      label="Full Name"
                      value={values.fullName}
                      handleChange={handleChange}
                      placeholder="Full Name"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* email */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="email"
                      label="Email"
                      value={values.email}
                      handleChange={handleChange}
                      placeholder="Email"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* country code */}
                  <Col xs={24} sm={12} md={8}>
                    <FormSelect
                      name="code"
                      label="Code"
                      value={values.code}
                      options={getCountryDropdownOpt() ?? []}
                      handleChange={value => {
                        setFieldValue('code', value);
                      }}
                      placeholder="Code"
                      errors={errors}
                      touched={touched}
                      allowClear
                    />
                  </Col>

                  {/*  phoneNumber */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="number"
                      label="Phone Number"
                      value={values.number}
                      handleChange={event => {
                        const errorMsg = 'Number is invalid!';
                        const fieldValue = event?.target?.value;
                        validatePhoneNumber({
                          fieldName: 'number',
                          fieldValue,
                          setFieldValue,
                          errors,
                          setErrors,
                          errorMsg,
                        });
                      }}
                      placeholder="Phone number"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/*  whatsapp */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="whatsapp"
                      label="Whatsapp"
                      value={values.whatsapp}
                      handleChange={event => {
                        const errorMsg = 'Number is invalid!';
                        const fieldValue = event?.target?.value;
                        validatePhoneNumber({
                          fieldName: 'whatsapp',
                          fieldValue,
                          setFieldValue,
                          errors,
                          setErrors,
                          errorMsg,
                        });
                      }}
                      placeholder="Whatsapp"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>

                <h4 style={{ margin: '16px 0' }}>Location</h4>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <FieldArray
                      name="location"
                      render={arrayHelpers => {
                        const { push, remove, form } = arrayHelpers;
                        return (
                          <>
                            {values?.location.map((_item, index) => {
                              const addressError = errors?.location?.[index];
                              const addressTouched = touched?.location?.[index];
                              const mapLinkError = errors?.location?.[index];
                              const mapLinkTouched = touched?.location?.[index];
                              return (
                                <>
                                  <Row key={index} gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                                    <Col xs={24} sm={form.values?.location.length > 1 ? 11 : 12}>
                                      <FormInput
                                        name="address"
                                        label={`Address ${index + 1}`}
                                        value={values.location[index]?.address}
                                        handleChange={event => {
                                          setFieldValue(`location[${index}].address`, event?.target?.value);
                                        }}
                                        placeholder="Address"
                                        errors={addressError}
                                        touched={addressTouched}
                                        isRequired={index === 0}
                                      />
                                    </Col>
                                    <Col xs={24} sm={form.values?.location.length > 1 ? 11 : 12}>
                                      <FormInput
                                        name="mapLink"
                                        label={`Maplink ${index + 1}`}
                                        value={form.values.location[index]?.mapLink}
                                        handleChange={event => {
                                          setFieldValue(`location[${index}].mapLink`, event?.target?.value);
                                        }}
                                        placeholder="Maplink"
                                        errors={mapLinkError}
                                        touched={mapLinkTouched}
                                        isRequired={index === 0}
                                      />
                                    </Col>
                                    {form.values?.location.length > 1 && (
                                      <Col xs={1}>
                                        <MinusCircleOutlined
                                          onClick={() => remove(index)}
                                          style={{ paddingTop: '44px' }}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </>
                              );
                            })}

                            <Row justify="end" align="middle">
                              <Col>
                                <Button
                                  type="default"
                                  onClick={() => push({ address: '', mapLink: '' })}
                                  icon={<PlusOutlined />}
                                >
                                  Add another address
                                </Button>
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                    />
                  </Col>

                  {/*  map */}
                </Row>

                <DivBreak />

                <h3>Social Links</h3>
                <Row gutter={[16, 16]}>
                  {/*  facebook */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="facebook"
                      label="Facebook"
                      value={values.facebook}
                      handleChange={handleChange}
                      placeholder="Facebook Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  instagram */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="instagram"
                      label="Instagram"
                      value={values.instagram}
                      handleChange={handleChange}
                      placeholder="Instagram Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  twitter */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="twitter"
                      label="Twitter"
                      value={values.twitter}
                      handleChange={handleChange}
                      placeholder="Twitter Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  linkedIn */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="linkedIn"
                      label="LinkedIn"
                      value={values.linkedIn}
                      handleChange={handleChange}
                      placeholder="LinkedIn Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  youtube */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="youtube"
                      label="Youtube"
                      value={values.youtube}
                      handleChange={handleChange}
                      placeholder="Youtube Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  tiktok */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="tiktok"
                      label="Tiktok"
                      value={values.tiktok}
                      handleChange={handleChange}
                      placeholder="Tiktok Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>
                </Row>
              </div>

              <DivBreak />

              <h3>Footer Texts</h3>
              <Row gutter={[16, 16]}>
                {/*  footer default */}
                <Col xs={24}>
                  <FormInput
                    type="textarea"
                    name={`footer${DEFAULT_LANG.value}`}
                    label={`Footer Text (${DEFAULT_LANG.label})`}
                    value={values[`footer${DEFAULT_LANG.value}`]}
                    handleChange={handleChange}
                    placeholder="Footer Text"
                    errors={errors}
                    touched={touched}
                  />
                </Col>

                {/* footer dynamic  */}
                {values.isMultiLang === 'true' &&
                  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                    return (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="textarea"
                          name={`footer${value}`}
                          label={`Footer Text (${label})`}
                          value={values[`footer${value}`]}
                          handleChange={handleChange}
                          placeholder="Footer Text"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    );
                  })}

                {/*  copyright description default */}
                <Col xs={24}>
                  <FormInput
                    type="editor"
                    name={`copyright${DEFAULT_LANG.value}`}
                    label={`Description (${DEFAULT_LANG.label})`}
                    editorState={values[`copyright${DEFAULT_LANG.value}`]}
                    handleEditorStateChange={state => {
                      const desc = `copyright${DEFAULT_LANG.value}`;
                      setFieldValue(desc, state);
                    }}
                    placeholder="Description"
                    errors={errors}
                    touched={touched}
                  />
                </Col>

                {/* footer dynamic  */}
                {values.isMultiLang === 'true' &&
                  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                    return (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="editor"
                          name={`copyright${value}`}
                          label={`Description (${label})`}
                          editorState={values[`copyright${value}`]}
                          handleEditorStateChange={state => {
                            const desc = `copyright${value}`;
                            setFieldValue(desc, state);
                          }}
                          placeholder="Description"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    );
                  })}
              </Row>

              <DivBreak />

              <Row justify="end" align="middle">
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#155BDC',
                      color: '#fff',
                      marginRight: 5,
                      height: '40px',
                      fontWeight: '500',
                    }}
                    htmlType="submit"
                    loading={contactInfoLoading}
                  >
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {values?._id ? 'Update Contact Info' : 'Add Contact Info'}
                  </Button>
                  <Button size="default" type="white" key="back" outlined onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

ContactInfoForm.propTypes = {
  formData: propTypes.object,
  onCancel: propTypes.func,
};

export default ContactInfoForm;
