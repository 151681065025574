const actions = {
  GET_OFFER_CAR_BEGIN: 'GET_OFFER_CAR_BEGIN',
  GET_OFFER_CAR_SUCCESS: 'GET_OFFER_CAR_SUCCESS',
  GET_OFFER_CAR_FAILED: 'GET_OFFER_CAR_FAILED',

  GET_ONLY_OFFER_CAR_BEGIN: 'GET_ONLY_OFFER_CAR_BEGIN',
  GET_ONLY_OFFER_CAR_SUCCESS: 'GET_ONLY_OFFER_CAR_SUCCESS',
  GET_ONLY_OFFER_CAR_FAILED: 'GET_ONLY_OFFER_CAR_FAILED',

  ADD_OFFER_CAR_BEGIN: 'ADD_OFFER_CAR_BEGIN',
  ADD_OFFER_CAR_SUCCESS: 'ADD_OFFER_CAR_SUCCESS',
  ADD_OFFER_CAR_FAILED: 'ADD_OFFER_CAR_FAILED',

  UPDATE_OFFER_CAR_BEGIN: 'UPDATE_OFFER_CAR_BEGIN',
  UPDATE_OFFER_CAR_SUCCESS: 'UPDATE_OFFER_CAR_SUCCESS',
  UPDATE_OFFER_CAR_FAILED: 'UPDATE_OFFER_CAR_FAILED',

  DELETE_OFFER_CAR_BEGIN: 'DELETE_OFFER_CAR_BEGIN',
  DELETE_OFFER_CAR_SUCCESS: 'DELETE_OFFER_CAR_SUCCESS',
  DELETE_OFFER_CAR_FAILED: 'DELETE_OFFER_CAR_FAILED',

  getOfferCarBegin: () => {
    return {
      type: actions.GET_OFFER_CAR_BEGIN,
    };
  },

  getOfferCarSuccess: data => {
    return {
      type: actions.GET_OFFER_CAR_SUCCESS,
      data,
    };
  },

  getOfferCarFailed: err => {
    return {
      type: actions.GET_OFFER_CAR_FAILED,
      err,
    };
  },

  addOfferCarBegin: () => {
    return {
      type: actions.ADD_OFFER_CAR_BEGIN,
    };
  },

  addOfferCarSuccess: data => {
    return {
      type: actions.ADD_OFFER_CAR_SUCCESS,
      data,
    };
  },

  addOfferCarFailed: err => {
    return {
      type: actions.ADD_OFFER_CAR_FAILED,
      err,
    };
  },
  updateOfferCarBegin: () => {
    return {
      type: actions.UPDATE_OFFER_CAR_BEGIN,
    };
  },

  updateOfferCarSuccess: data => {
    return {
      type: actions.UPDATE_OFFER_CAR_SUCCESS,
      data,
    };
  },

  updateOfferCarFailed: err => {
    return {
      type: actions.UPDATE_OFFER_CAR_FAILED,
      err,
    };
  },
  deleteOfferCarBegin: () => {
    return {
      type: actions.DELETE_OFFER_CAR_BEGIN,
    };
  },

  deleteOfferCarSuccess: data => {
    return {
      type: actions.DELETE_OFFER_CAR_SUCCESS,
      data,
    };
  },

  deleteOfferCarFailed: err => {
    return {
      type: actions.DELETE_OFFER_CAR_FAILED,
      err,
    };
  },
};

export default actions;
