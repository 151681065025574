import styled from 'styled-components';

const LeadInquiryDetailModal = styled.div`
  .info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .info-title {
    font-weight: 500;
    margin-bottom: 0;
  }
  h3 {
    border-bottom: 1px solid #d9dced;
    padding-bottom: 4px;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
  }
`;

export { LeadInquiryDetailModal };
