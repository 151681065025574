// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import propTypes from 'prop-types';

// USER'S COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

const CreateServicingHistory = ({ visible, onCancel, handleAdd }) => {
  const [form] = Form.useForm();

  //To handle the add service history
  const handleSubmit = (datas) => {
    Object.keys(datas).map(key => {
      if (!datas[key]) delete datas[key];
    })
    handleAdd(datas, form);
    onCancel();
  }

  return (
    <Modal
      type={'primary'}
      title="Add Servicing History"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div className="body-type-modal">
        <BasicFormWrapper>
          <Form
            name="basic"
            initialValues={{}}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            {/* servicing center */}
            <div className="add-product-block">
              <Row>
                <Col xs={24}>
                  <Form.Item
                    name="serviceCenter"
                    label="Service Center"
                    rules={[
                      {
                        required: true,
                        message: 'Please input name of service center!',
                      },
                    ]}
                  >
                    <Input placeholder="Auto24 servicing center" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <br />
            {/* date and driven km */}
            <div className="add-product-block">
              <Row>
                <Col xs={11}>
                  <Form.Item name="drivenKM" label="Driven KM"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the total driven KM!',
                      },
                    ]}>
                    <Input type={'number'} placeholder={123304} />
                  </Form.Item>
                </Col>
                <Col xs={1}></Col>
                <Col xs={11}>
                  <Form.Item name="date" label="Service Date"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the date of service',
                      },
                    ]}>
                    <Input type={'date'} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <br />
            <br />
            <Form.Item
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              <Button style={{ backgroundColor: '#155BDC', color: '#fff' }} htmlType="submit">
                Add Servicing History
              </Button>
            </Form.Item>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
};

CreateServicingHistory.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateServicingHistory;