import actions from './actions';

const {
  GET_COLOR_BEGIN, GET_COLOR_SUCCESS, GET_COLOR_FAILED,
  ADD_COLOR_BEGIN, ADD_COLOR_SUCCESS, ADD_COLOR_FAILED,
  UPDATE_COLOR_BEGIN, UPDATE_COLOR_SUCCESS, UPDATE_COLOR_FAILED,
  DELETE_COLOR_BEGIN, DELETE_COLOR_SUCCESS, DELETE_COLOR_FAILED
} = actions;

const initState = {
  colors: [],
  loading: false,
  error: null,
};


const ColorReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_COLOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_COLOR_SUCCESS:
      return {
        ...state,
        colors: data,
        loading: false,
      };
    case GET_COLOR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_COLOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_COLOR_SUCCESS:
      return {
        ...state,
        colors: data,
        loading: false,
      };
    case ADD_COLOR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_COLOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COLOR_SUCCESS:
      return {
        ...state,
        colors: data,
        loading: false,
      };
    case UPDATE_COLOR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_COLOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COLOR_SUCCESS:
      return {
        ...state,
        colors: data,
        loading: false,
      };
    case DELETE_COLOR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ColorReducer;