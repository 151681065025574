/* eslint-disable no-underscore-dangle */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row } from 'antd';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import InspectionReportFieldForm from './InspectionReportFieldForm';
import {
  IMAGE_AND_CAPTION_LIST,
  SPECS_LABEL,
  SPECS_LIST,
  inspectReportInitialFormData,
  inspectReportValidationSchema,
} from './inspectionReport.schema';
import DivBreak from '../../../components/divBreak/DivBreak';
import FormInput, { INPUT_TYPE } from '../../../components/input/input';
import { Modal } from '../../../components/modals/antd-modals';
import FormSelect from '../../../components/select/FormSelect';
import { getAllCars } from '../../../redux/car/actionCreator';
import { BasicFormWrapper } from '../../styled';
import {
  addNewInspectionReport,
  editInspectionReport,
  getAllInspectionReports,
} from '../../../redux/inspection report/actionCreator';

const InspectionReportForm = props => {
  const { formData, setFormData, pagination } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const { carsData, inspectionReportLoading, searchKeyword } = useSelector(state => {
    return {
      inspectionReportLoading: state.inspectionReport.loading,
      carsData: state.car.cars,
      searchKeyword: state.headerSearch.searchKeyword,
    };
  });

  const [showModal, setShowModal] = useState(false);
  const [keyForCommonModal, setKeyForCommonModal] = useState({ label: null, value: null });

  useEffect(() => {
    dispatch(getAllCars());
  }, []);

  function getYesNoRadioOpt() {
    return [
      {
        value: 'true',
        label: 'Yes',
      },
      {
        value: 'false',
        label: 'No',
      },
    ];
  }

  function getCarsDropdownOpt() {
    const dropdownOpt = carsData?.map(car => ({
      value: car?._id ?? '',
      key: `${car?.name}`,
      label: `${car?.name}`,
    }));
    return dropdownOpt ?? [];
  }

  const toggleModalByType = modalInfo => {
    setShowModal(!showModal);
    setKeyForCommonModal(modalInfo);
  };

  const cancelModal = () => {
    setShowModal(false);
    setKeyForCommonModal({ label: null, value: null });
  };

  async function handleFormSubmit(requestData, resetForm) {
    try {
      const filteredKey = ['leftImages', 'rightImages', 'backImages', 'frontImages', 'interiorControlsAndSwitches'];

      const formValue = new FormData();

      Object.keys(requestData).forEach(key => {
        if (!filteredKey.includes(key)) {
          if (requestData[key]) {
            formValue.append(key, requestData[key]);
          }
        }
      });

      filteredKey.forEach(filteredItem => {
        if (requestData[filteredItem]) {
          requestData[filteredItem].forEach(item => {
            if (item?.originFileObj) formValue.append(`${filteredItem}`, item?.originFileObj);
          });
        }
      });

      const res = await dispatch(
        params?.id ? editInspectionReport(params?.id, formValue, history) : addNewInspectionReport(formValue, history),
      );

      // eslint-disable-next-line no-underscore-dangle
      if (res?.data?.status) {
        resetForm();
        setFormData({ ...inspectReportInitialFormData });
        dispatch(
          getAllInspectionReports(
            `?search=${searchKeyword}&page=${pagination.currentPage || 1}&limit=${pagination.pageLimit || 50}`,
          ),
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  const renderModalComponent = (values, setFieldValue) => {
    return (
      <InspectionReportFieldForm
        values={values}
        setFieldValue={setFieldValue}
        keyNameAndValue={keyForCommonModal}
        cancelModal={cancelModal}
        formData={formData}
      />
    );
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={inspectReportValidationSchema()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          // eslint-disable-next-line no-use-before-define
          handleFormSubmit(values, resetForm);
        }}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <>
              <Modal
                type="primary"
                title={keyForCommonModal?.label}
                visible={showModal}
                width={800}
                onCancel={cancelModal}
                footer={null}
              >
                <div className="body-type-modal">{renderModalComponent(values, setFieldValue)}</div>
              </Modal>

              <Form>
                <BasicFormWrapper>
                  {/* car and date */}
                  <div className="add-product-block">
                    <Row gutter={[32, 8]}>
                      <Col xs={24} sm={12}>
                        <FormSelect
                          name="car"
                          label="Car"
                          value={values.car}
                          options={getCarsDropdownOpt() ?? []}
                          handleChange={value => {
                            setFieldValue('car', value);
                          }}
                          placeholder="Select"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <FormInput
                          type={INPUT_TYPE.date}
                          name="date"
                          label="Date"
                          value={values.date}
                          handleChange={handleChange}
                          placeholder="Date"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                  </div>
                  <DivBreak />

                  {/* specs lists */}
                  {Object.entries(SPECS_LIST).map(([specsLabel, specsValue]) => (
                    <>
                      <h3 style={{ margin: '24px 0', fontWeight: 600 }}>{SPECS_LABEL[specsLabel]}:</h3>
                      <div className="add-product-block">
                        <Row gutter={[16, 16]}>
                          {specsValue.map(item => (
                            <>
                              {item.value === 'interiorControlsAndSwitches' ? (
                                <Col xs={24} md={12} sm={12}>
                                  <FormInput label={item.label} isRequired={false}>
                                    <Button
                                      type="transparent"
                                      onClick={e => {
                                        e.stopPropagation();
                                        toggleModalByType(item);
                                      }}
                                      icon={<PlusOutlined />}
                                      style={{ height: '40px', width: '100%' }}
                                    >
                                      Add {item.label}
                                    </Button>
                                  </FormInput>
                                </Col>
                              ) : (
                                <Col xs={12} md={6}>
                                  <FormInput
                                    type={INPUT_TYPE.radio}
                                    name={item.value}
                                    label={item.label}
                                    value={values[`${item.value}`]}
                                    handleChange={handleChange}
                                    radioOptions={getYesNoRadioOpt()}
                                    errors={errors}
                                    touched={touched}
                                    isRequired={false}
                                  />
                                </Col>
                              )}
                            </>
                          ))}
                        </Row>
                      </div>
                      <DivBreak />
                      <Divider />
                    </>
                  ))}

                  <div className="add-product-block">
                    <Row gutter={[32, 8]}>
                      {IMAGE_AND_CAPTION_LIST.map(item => (
                        <>
                          {!item.value.includes('Caption') ? (
                            <Col xs={24} sm={24} md={8}>
                              <FormInput label={item.label} isRequired={false}>
                                <Button
                                  type="transparent"
                                  onClick={e => {
                                    e.stopPropagation();
                                    toggleModalByType(item);
                                  }}
                                  icon={<PlusOutlined />}
                                  style={{ height: '40px', width: '100%' }}
                                >
                                  Add {item.label}
                                </Button>
                              </FormInput>
                            </Col>
                          ) : (
                            <Col xs={24} sm={24} md={16}>
                              <FormInput
                                name={item.value}
                                label={item.label}
                                value={values[`${item.value}`]}
                                handleChange={handleChange}
                                placeholder="Caption"
                                errors={errors}
                                touched={touched}
                                isRequired={false}
                              />
                            </Col>
                          )}
                        </>
                      ))}
                    </Row>
                  </div>

                  <DivBreak />

                  <Row justify="end" align="middle">
                    <Col>
                      <Button
                        style={{
                          backgroundColor: '#155BDC',
                          color: '#fff',
                          marginRight: 5,
                          height: '40px',
                          fontWeight: '500',
                        }}
                        htmlType="submit"
                        loading={inspectionReportLoading}
                      >
                        {params?.id ? 'Edit Inspection Report' : 'Add New Inspection Report'}
                      </Button>
                    </Col>
                  </Row>
                </BasicFormWrapper>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

InspectionReportForm.propTypes = {
  formData: propTypes.object.isRequired,
  setFormData: propTypes.func.isRequired,
  pagination: propTypes.object,
};

export default InspectionReportForm;
