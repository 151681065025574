import * as Yup from 'yup';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicBlogCategoryFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`category${item[1]}`]: '',
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicBlogCategoryFields();
  const initialFormData = {
    isMultiLang: 'false',
    ...dynamicFields,
  };

  return initialFormData;
}

export const blogCategoryInitialFormData = getInitialFormData();

export function blogCategoryValidationSchema(isMultiLangFlag) {
  let validationFields = {
    [`category${DEFAULT_LANG.value}`]: Yup.string().required(`Category(${DEFAULT_LANG.label}) is required!`),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`category${value}`]: Yup.string().required(`Category(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`category${value}`]: Yup.string(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    ...validationFields,
  });
}

export const blogCategoryTableColumns = [
  {
    title: 'S.No',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
