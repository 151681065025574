import Cookies from 'js-cookie';
import actions from './actions';
import axios from '../axios';
import { message } from 'antd';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  updateProfileBegin,
  updateProfileSuccess,
  updateProfileErr,
  updatePasswordBegin,
  updatePasswordSuccess,
  updatePasswordErr,
  forgotPasswordBegin,
  forgotPasswordSuccess,
  forgotPasswordErr,
  resetPasswordBegin,
  resetPasswordSuccess,
  resetPasswordErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

// FOR LOGIN
const login = (credential, history) => async dispatch => {
  try {
    dispatch(loginBegin());
    const res = await axios.post('auth/login', credential);
    const { authToken, refreshToken, user } = res?.data;
    if (user.role.name === 'admin' || user.role.name === 'writer') {
      Cookies.set('logedIn', true, { expires: 7 });
      Cookies.set('authToken', authToken, { expires: 7 });
      Cookies.set('refreshToken', refreshToken, { expires: 7 });
      dispatch(loginSuccess(user));
      history.push(`/${user.role.name}/dashboard`);
    } else dispatch(loginErr('You must be an admin or a writer.'));
  } catch (err) {
    dispatch(loginErr(typeof err.response.data.msg === 'object' ? err.response.data.msg.en : err.response.data.msg));
    setTimeout(() => {
      dispatch(loginErr(null));
    }, 2000);
  }
};

// FOR CHECKING IF THE USER IS AUTHENTICATED OR NOT
const checkUserAuthenticated = history => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      const res = await axios.get('/auth/check', {
        headers: { ...getHeaders() },
      });
      Cookies.set('logedIn', true);
      dispatch(loginSuccess(res.data.user));
      history.push(Cookies.get('currentPath'));
    } catch (err) {
      dispatch(loginErr(null));
      dispatch(errorHandling(err, false));
    }
  };
};

// FORGOT PASSWORD
export const forgotPassword = datas => async dispatch => {
  try {
    dispatch(forgotPasswordBegin());
    const res = await axios.post(`/auth/forgot-password`, datas);
    dispatch(forgotPasswordSuccess('sometext'));
    message.success('Please check your email!');
  } catch (err) {
    dispatch(forgotPasswordErr(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// RESET PASSWORD
export const resetPassword = (datas, token, form) => async dispatch => {
  try {
    dispatch(resetPasswordBegin());
    const res = await axios.post(`/auth/forgot-password-update`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-User': 'admin',
      },
    });
    dispatch(resetPasswordSuccess('Success'));
    message.success('Password changed successfully. Login to continue.');
    form.resetFields();
    return res;
  } catch (err) {
    dispatch(resetPasswordErr(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// UPDATING PROFILE
export const updateProfile = datas => async dispatch => {
  try {
    dispatch(updateProfileBegin());
    const res = await axios.put(`/users/update/profile`, datas, {
      headers: { ...getHeaders() },
    });
    dispatch(updateProfileSuccess(res.data.user));
    message.success('Profile updated successfully');
  } catch (err) {
    dispatch(updateProfileErr(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// UPDATING PASSWORD
export const updatePassword = (datas, form) => async dispatch => {
  try {
    dispatch(updatePasswordBegin());
    const res = await axios.put(`/users/update/password`, datas, {
      headers: { ...getHeaders() },
    });
    dispatch(updatePasswordSuccess(res.data.user));
    message.success('Password chanaged successfully');
    form.resetFields();
  } catch (err) {
    dispatch(updatePasswordErr(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// FOR LOGOUT
const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('authToken');
      Cookies.remove('refreshToken');
      Cookies.remove('currentPath');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, checkUserAuthenticated };
