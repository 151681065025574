import actions from './actions';

const {
  GET_BOOKINGS_BEGIN,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILED,
  CHANGE_BOOKING_STATUS_BEGIN,
  CHANGE_BOOKING_STATUS_SUCCESS,
  CHANGE_BOOKING_STATUS_FAILED,
  DELETE_BOOKING_BEGIN,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAILED,
} = actions;

const initState = {
  bookings: [],
  loading: false,
  error: null,
};

const bookingsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_BOOKINGS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: data,
        loading: false,
      };
    case GET_BOOKINGS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_BOOKING_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_BOOKING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_BOOKING_STATUS_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BOOKING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: data,
        loading: false,
      };
    case DELETE_BOOKING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default bookingsReducer;
