// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import Cookies from 'js-cookie';
import { LeadInquiryDetailModal } from '../search inquiry/overview/SearchInquiryDetailStyle';

const DisplayBooking = ({ onCancel, booking, visible }) => {
  return (
    <Modal
      title="Booking Details"
      visible={visible}
      onCancel={onCancel}
      width={600}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <LeadInquiryDetailModal>
        <div className="info-block">
          <p className="info-title">Full Name:</p>
          <p> {booking?.fullName}</p>
        </div>
        <div className="info-block">
          <p className="info-title">Email:</p>
          <p>{booking?.email}</p>
        </div>
        <div className="info-block">
          <p className="info-title">Phone Number:</p>
          <p>
            {booking?.phoneNumber?.code}
            {booking?.phoneNumber?.number}
          </p>
        </div>
        <div className="info-block">
          <p className="info-title">Interested Car:</p>
          <a
            href={`/admin/cars/car-detail/${booking?.car?._id}`}
            target="_blank"
            onClick={() => Cookies.set('currentPath', `/admin/cars/car-detail/${booking?.car?._id}`)}
            rel="noreferrer"
          >
            {booking?.car?.name ?? ''}
          </a>
        </div>
        <div className="info-block">
          <p className="info-title">Status:</p>
          <p style={{ textTransform: 'capitalize' }}>{booking?.status}</p>
        </div>
        <div className="info-block">
          <p className="info-title">Created At:</p>
          <p>{moment(booking?.createdAt).format('YYYY-MM-DD, h:mm:ss A')}</p>
        </div>
      </LeadInquiryDetailModal>
    </Modal>
  );
};

export default DisplayBooking;
