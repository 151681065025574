// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import propTypes from 'prop-types';

// USER'S COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

const DeleteServicingHistory = ({ visible, onCancel, history, handleDelete }) => {
  // To handle the delete service history
  const handleSubmit = () => {
    handleDelete(history?._id)
    onCancel();
  }

  return (
    <Modal
      type={'primary'}
      title="Delete Servicing History"
      visible={visible}
      footer={[
        <div key="1" className="model-modal-footer">
          <Button size="default" style={{ backgroundColor: '#155BDC', color: '#fff' }} key="submit" onClick={handleSubmit}>
            Delete
          </Button>
          <Button size="default" type="white" key="back" outlined onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
    >
      <div className="model-modal">
        <BasicFormWrapper>
          <p>Delete servicing history?</p>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
};

DeleteServicingHistory.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default DeleteServicingHistory;