import actions from './actions';

const {
  GET_PRICE_TOOL_BEGIN,
  GET_PRICE_TOOL_SUCCESS,
  GET_PRICE_TOOL_FAILED,
  ADD_PRICE_TOOL_BEGIN,
  ADD_PRICE_TOOL_SUCCESS,
  ADD_PRICE_TOOL_FAILED,
  UPDATE_PRICE_TOOL_BEGIN,
  UPDATE_PRICE_TOOL_SUCCESS,
  UPDATE_PRICE_TOOL_FAILED,
  DELETE_PRICE_TOOL_BEGIN,
  DELETE_PRICE_TOOL_SUCCESS,
  DELETE_PRICE_TOOL_FAILED,
} = actions;

const initState = {
  priceToolCars: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const PriceToolReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PRICE_TOOL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PRICE_TOOL_SUCCESS:
      return {
        ...state,
        priceToolCars: data.pricing,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_PRICE_TOOL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_PRICE_TOOL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRICE_TOOL_SUCCESS:
      return {
        ...state,
        priceToolCars: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_PRICE_TOOL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_PRICE_TOOL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRICE_TOOL_SUCCESS:
      return {
        ...state,
        offerCars: data,
        loading: false,
      };
    case UPDATE_PRICE_TOOL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_PRICE_TOOL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRICE_TOOL_SUCCESS:
      return {
        ...state,
        priceToolCars: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_PRICE_TOOL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default PriceToolReducer;
