import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import InspectionReportForm from './InspectionReportForm';
import { inspectReportInitialFormData } from './inspectionReport.schema';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';

const CreateInspectionReport = () => {
  const history = useHistory();

  const userType = useSelector(state => state.auth.user.role.name);

  const [formData, setFormData] = useState({ ...inspectReportInitialFormData });

  return (
    <>
      <PageHeader
        ghost
        title="Add New Inspection Report"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/inspection-reports`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <div className="body-type-modal">
            <InspectionReportForm formData={formData} setFormData={setFormData} />
          </div>
        </Cards>
      </Main>
    </>
  );
};

export default CreateInspectionReport;
