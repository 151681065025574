// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// USER COMPONENTS
import Blog from '../container/blog/Blog';
import CreateBlog from '../container/blog/overview/CreateBlog';
import EditBlog from '../container/blog/overview/EditBlog';
import DisplayBlog from '../container/blog/overview/DisplayBlog';
import withAdminLayout from '../layout/withAdminLayout';
import BlogCategory from '../container/blogcategory/BlogCategory';

const Dashboard = lazy(() => import('../container/dashboard'));

const Profile = lazy(() => import('../container/profile/myProfile/Index'));
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/" component={Dashboard} />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/blogs`} component={Blog} />
        <Route exact path={`${path}/blogs/add`} component={CreateBlog} />
        <Route exact path={`${path}/blogs/edit/:id`} component={EditBlog} />
        <Route exact path={`${path}/blogs/view/:id`} component={DisplayBlog} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/blog-categories`} component={BlogCategory} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Index, 'writer');
