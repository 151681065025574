const actions = {
  GET_FINANCING_PARTNER_BEGIN: 'GET_FINANCING_PARTNER_BEGIN',
  GET_FINANCING_PARTNER_SUCCESS: 'GET_FINANCING_PARTNER_SUCCESS',
  GET_FINANCING_PARTNER_FAILED: 'GET_FINANCING_PARTNER_FAILED',

  ADD_FINANCING_PARTNER_BEGIN: 'ADD_FINANCING_PARTNER_BEGIN',
  ADD_FINANCING_PARTNER_SUCCESS: 'ADD_FINANCING_PARTNER_SUCCESS',
  ADD_FINANCING_PARTNER_FAILED: 'ADD_FINANCING_PARTNER_FAILED',

  UPDATE_FINANCING_PARTNER_BEGIN: 'UPDATE_FINANCING_PARTNER_BEGIN',
  UPDATE_FINANCING_PARTNER_SUCCESS: 'UPDATE_FINANCING_PARTNER_SUCCESS',
  UPDATE_FINANCING_PARTNER_FAILED: 'UPDATE_FINANCING_PARTNER_FAILED',

  DELETE_FINANCING_PARTNER_BEGIN: 'DELETE_FINANCING_PARTNER_BEGIN',
  DELETE_FINANCING_PARTNER_SUCCESS: 'DELETE_FINANCING_PARTNER_SUCCESS',
  DELETE_FINANCING_PARTNER_FAILED: 'DELETE_FINANCING_PARTNER_FAILED',

  getFinancingPartnerBegin: () => {
    return {
      type: actions.GET_FINANCING_PARTNER_BEGIN,
    };
  },

  getFinancingPartnerSuccess: data => {
    return {
      type: actions.GET_FINANCING_PARTNER_SUCCESS,
      data,
    };
  },

  getFinancingPartnerFailed: err => {
    return {
      type: actions.GET_FINANCING_PARTNER_FAILED,
      err,
    };
  },

  addFinancingPartnerBegin: () => {
    return {
      type: actions.ADD_FINANCING_PARTNER_BEGIN,
    };
  },

  addFinancingPartnerSuccess: data => {
    return {
      type: actions.ADD_FINANCING_PARTNER_SUCCESS,
      data,
    };
  },

  addFinancingPartnerFailed: err => {
    return {
      type: actions.ADD_FINANCING_PARTNER_FAILED,
      err,
    };
  },

  updateFinancingPartnerBegin: () => {
    return {
      type: actions.UPDATE_FINANCING_PARTNER_BEGIN,
    };
  },

  updateFinancingPartnerSuccess: data => {
    return {
      type: actions.UPDATE_FINANCING_PARTNER_SUCCESS,
      data,
    };
  },

  updateFinancingPartnerFailed: err => {
    return {
      type: actions.UPDATE_FINANCING_PARTNER_FAILED,
      err,
    };
  },

  deleteFinancingPartnerBegin: () => {
    return {
      type: actions.DELETE_FINANCING_PARTNER_BEGIN,
    };
  },

  deleteFinancingPartnerSuccess: data => {
    return {
      type: actions.DELETE_FINANCING_PARTNER_SUCCESS,
      data,
    };
  },

  deleteFinancingPartnerFailed: err => {
    return {
      type: actions.DELETE_FINANCING_PARTNER_FAILED,
      err,
    };
  },
};

export default actions;