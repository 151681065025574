const actions = {
  GET_BLOG_BEGIN: 'GET_BLOG_BEGIN',
  GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
  GET_BLOG_FAILED: 'GET_BLOG_FAILED',

  ADD_BLOG_BEGIN: 'ADD_BLOG_BEGIN',
  ADD_BLOG_SUCCESS: 'ADD_BLOG_SUCCESS',
  ADD_BLOG_FAILED: 'ADD_BLOG_FAILED',

  UPDATE_BLOG_BEGIN: 'UPDATE_BLOG_BEGIN',
  UPDATE_BLOG_SUCCESS: 'UPDATE_BLOG_SUCCESS',
  UPDATE_BLOG_FAILED: 'UPDATE_BLOG_FAILED',

  DELETE_BLOG_BEGIN: 'DELETE_BLOG_BEGIN',
  DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
  DELETE_BLOG_FAILED: 'DELETE_BLOG_FAILED',

  getBlogBegin: () => {
    return {
      type: actions.GET_BLOG_BEGIN,
    };
  },

  getBlogSuccess: data => {
    return {
      type: actions.GET_BLOG_SUCCESS,
      data,
    };
  },

  getBlogFailed: err => {
    return {
      type: actions.GET_BLOG_FAILED,
      err,
    };
  },

  addBlogBegin: () => {
    return {
      type: actions.ADD_BLOG_BEGIN,
    };
  },

  addBlogSuccess: data => {
    return {
      type: actions.ADD_BLOG_SUCCESS,
      data,
    };
  },

  addBlogFailed: err => {
    return {
      type: actions.ADD_BLOG_FAILED,
      err,
    };
  },

  updateBlogBegin: () => {
    return {
      type: actions.UPDATE_BLOG_BEGIN,
    };
  },

  updateBlogSuccess: data => {
    return {
      type: actions.UPDATE_BLOG_SUCCESS,
      data,
    };
  },

  updateBlogFailed: err => {
    return {
      type: actions.UPDATE_BLOG_FAILED,
      err,
    };
  },

  deleteBlogBegin: () => {
    return {
      type: actions.DELETE_BLOG_BEGIN,
    };
  },

  deleteBlogSuccess: data => {
    return {
      type: actions.DELETE_BLOG_SUCCESS,
      data,
    };
  },

  deleteBlogFailed: err => {
    return {
      type: actions.DELETE_BLOG_FAILED,
      err,
    };
  },
};

export default actions;