import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getPageBegin, getPageSuccess, getPageFailed,
  addPageBegin, addPageSuccess, addPageFailed,
  updatePageBegin, updatePageSuccess, updatePageFailed,
  deletePageBegin, deletePageSuccess, deletePageFailed,
} = actions;

// GETTING ALL PAGES
export const getAllPages = async (dispatch) => {
  try {
    dispatch(getPageBegin());
    const res = await axios.get('pages', { headers: { ...getHeaders() } });
    dispatch(getPageSuccess(res.data.pages));
  } catch (err) {
    dispatch(getPageFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false))
  }
}

// ADDING NEW PAGE
export const addNewPage = (data, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addPageBegin());
    const res = await axios.post('pages', data, { headers: { ...getHeaders() } });

    dispatch(addPageSuccess([res.data.page, ...state.page.pages]));
    message.success('New page created successfully');
    // form.resetFields();
    history.push('/admin/pages');
    return res;
  } catch (err) {
    dispatch(addPageFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
};

// UPDATING PAGE
export const editPage = (id, datas, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updatePageBegin());
    const res = await axios.put(`pages/${id}`, datas, { headers: { ...getHeaders() } });
    const newPages = state.page.pages.map(page => {
      if (page._id === id) return res.data.page;
      else return page;
    })
    dispatch(updatePageSuccess(newPages));
    message.success('Page updated successfully');
    history.push('/admin/pages');
    return res;
  } catch (err) {
    dispatch(updatePageFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE PAGE
export const deletePage = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deletePageBegin());
    const res = await axios.delete(`pages/${id}`, { headers: { ...getHeaders() } });

    dispatch(deletePageSuccess(state.page.pages.filter(page => page._id !== id)));
    message.success('Page deleted successfully');
    return res;
  } catch (err) {
    dispatch(deletePageFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 