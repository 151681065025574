// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState } from 'react';
import { Table, Image } from 'antd';
import FeatherIcon from 'feather-icons-react';

// USER'S COMPONENT
import { UserTableStyleWrapper } from '../../pages/style';
import { TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import DeleteCarIssue from './DeleteCarIssue';
import { IMAGE_URL } from '../../../utils/constants';

const CarIssueTable = ({ carIssues, handleDeleteIssue }) => {
  const [state, setState] = useState({ visible: false });
  const [issue, setIssue] = useState({});

  if (carIssues && JSON.parse(carIssues).length > 0) {
    let newCarIssues = JSON.parse(carIssues);
    // To display the modal
    const showModal = (file, type) => {
      setIssue(newCarIssues.find(bc => bc.file === file));
      setState({ ...state, visible: true });
    };

    // To hide the modal
    const onCancel = () => {
      setIssue({});
      setState({ ...state, visible: false, });
    };

    const newCarIssueTable = [];

    newCarIssues?.map((issue, index) => {
      const { file, caption } = issue;

      return newCarIssueTable.push({
        key: file,
        sNo: (
          <p>{index + 1}</p>
        ),
        file: (
          <figure className="user-info">
            <Image
              width={100}
              src={`${IMAGE_URL}/${file}`}
            />
            {/* {file} */}
          </figure>
        ),
        caption: (
          <div className="user-info">
            <Heading className="user-name" as="h6">
              {caption}
            </Heading>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(file, 'delete')}>
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </div>
        ),
      });
    });

    const carIssueTableColumns = [
      {
        title: 'S.No',
        dataIndex: 'sNo',
        key: 'sNo',
      },
      {
        title: 'File',
        dataIndex: 'file',
        key: 'file',
      },
      {
        title: 'Caption',
        dataIndex: 'caption',
        key: 'caption',
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];

    return (
      <Cards headless>
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              dataSource={newCarIssueTable}
              columns={carIssueTableColumns}
              pagination={{
                defaultPageSize: 5,
                total: newCarIssueTable?.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
        <DeleteCarIssue onCancel={onCancel} visible={state.visible} issue={issue} handleDeleteIssue={handleDeleteIssue} />
      </Cards>
    );
  }
  else return null;
};

export default CarIssueTable;