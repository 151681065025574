/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import actions from './actions';

const { GET_CONTACT_QUERY_BEGIN, GET_CONTACT_QUERY_SUCCESS, GET_CONTACT_QUERY_FAILED } = actions;

const initialState = {
    status: true,
    contactQueries: [],
    total: 0,
    currentPage: 1,
    pageLimit: 2,
    loading: false,
    error: null,
};

const ContactQueryReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_CONTACT_QUERY_BEGIN:
            return {
                ...state,
                loading: true,
            }
        case GET_CONTACT_QUERY_SUCCESS:
            return {
                ...state,
                status: data.status || true,
                contactQueries: data.contactQueries,
                total: data.total || 0,
                currentPage: data.currentPage || state.currentPage,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false
            }
        case GET_CONTACT_QUERY_FAILED:
            return {
                ...state,
                error: err,
                loading: false
            }
        default:
            return state;
    }
}
export default ContactQueryReducer;