const actions = {
  GET_CUSTOMER_INTEREST_BEGIN: 'GET_CUSTOMER_INTEREST_BEGIN',
  GET_CUSTOMER_INTEREST_SUCCESS: 'GET_CUSTOMER_INTEREST_SUCCESS',
  GET_CUSTOMER_INTEREST_FAILED: 'GET_CUSTOMER_INTEREST_FAILED',

  ADD_CUSTOMER_INTEREST_BEGIN: 'ADD_CUSTOMER_INTEREST_BEGIN',
  ADD_CUSTOMER_INTEREST_SUCCESS: 'ADD_CUSTOMER_INTEREST_SUCCESS',
  ADD_CUSTOMER_INTEREST_FAILED: 'ADD_CUSTOMER_INTEREST_FAILED',

  UPDATE_CUSTOMER_INTEREST_BEGIN: 'UPDATE_CUSTOMER_INTEREST_BEGIN',
  UPDATE_CUSTOMER_INTEREST_SUCCESS: 'UPDATE_CUSTOMER_INTEREST_SUCCESS',
  UPDATE_CUSTOMER_INTEREST_FAILED: 'UPDATE_CUSTOMER_INTEREST_FAILED',

  DELETE_CUSTOMER_INTEREST_BEGIN: 'DELETE_CUSTOMER_INTEREST_BEGIN',
  DELETE_CUSTOMER_INTEREST_SUCCESS: 'DELETE_CUSTOMER_INTEREST_SUCCESS',
  DELETE_CUSTOMER_INTEREST_FAILED: 'DELETE_CUSTOMER_INTEREST_FAILED',

  getCustomerInterestBegin: () => {
    return {
      type: actions.GET_CUSTOMER_INTEREST_BEGIN,
    };
  },

  getCustomerInterestSuccess: data => {
    return {
      type: actions.GET_CUSTOMER_INTEREST_SUCCESS,
      data,
    };
  },

  getCustomerInterestFailed: err => {
    return {
      type: actions.GET_CUSTOMER_INTEREST_FAILED,
      err,
    };
  },

  addCustomerInterestBegin: () => {
    return {
      type: actions.ADD_CUSTOMER_INTEREST_BEGIN,
    };
  },

  addCustomerInterestSuccess: data => {
    return {
      type: actions.ADD_CUSTOMER_INTEREST_SUCCESS,
      data,
    };
  },

  addCustomerInterestFailed: err => {
    return {
      type: actions.ADD_CUSTOMER_INTEREST_FAILED,
      err,
    };
  },

  updateCustomerInterestBegin: () => {
    return {
      type: actions.UPDATE_CUSTOMER_INTEREST_BEGIN,
    };
  },

  updateCustomerInterestSuccess: data => {
    return {
      type: actions.UPDATE_CUSTOMER_INTEREST_SUCCESS,
      data,
    };
  },

  updateCustomerInterestFailed: err => {
    return {
      type: actions.UPDATE_CUSTOMER_INTEREST_FAILED,
      err,
    };
  },

  deleteCustomerInterestBegin: () => {
    return {
      type: actions.DELETE_CUSTOMER_INTEREST_BEGIN,
    };
  },

  deleteCustomerInterestSuccess: data => {
    return {
      type: actions.DELETE_CUSTOMER_INTEREST_SUCCESS,
      data,
    };
  },

  deleteCustomerInterestFailed: err => {
    return {
      type: actions.DELETE_CUSTOMER_INTEREST_FAILED,
      err,
    };
  },
};

export default actions;