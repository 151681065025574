import actions from './actions';

const {
  GET_SUBSCRIBER_BEGIN, GET_SUBSCRIBER_SUCCESS, GET_SUBSCRIBER_FAILED,
  DELETE_SUBSCRIBER_BEGIN, DELETE_SUBSCRIBER_SUCCESS, DELETE_SUBSCRIBER_FAILED
} = actions;

const initState = {
  total: 0,
  subscribers: [],
  currentPage: 1,
  pageLimit: 2,
  loading: false,
  error: null,
};


const SubscriberReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SUBSCRIBER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        total: data.totalSubscribers || 0,
        subscribers: data.subscribers,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.currentPage,
        loading: data.currentPage ? false : true,
      };
    case GET_SUBSCRIBER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_SUBSCRIBER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        subscribers: data,
        loading: false,
      };
    case DELETE_SUBSCRIBER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default SubscriberReducer;