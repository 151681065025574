/* eslint-disable no-underscore-dangle */
import { Col, Row, Spin, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingsTableColumns } from './bookings.schema';
import DisplayBooking from './DisplayBooking';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { PageHeader } from '../../components/page-headers/page-headers';
import { UserTableStyleWrapper } from '../pages/style';
import { BasicFormWrapper, Main, TableWrapper } from '../styled';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';
import { getAllBookings, deleteBookingById, changeBookingStatusById } from '../../redux/bookings/actionCreator';
import { Modal } from '../../components/modals/antd-modals';

const MODAL_TYPE = {
  delete: 'delete',
  statusChange: 'statusChange',
  detail: 'detail',
};

const BookingsMain = () => {
  const dispatch = useDispatch();
  const { bookingsData, bookingsLoading } = useSelector(state => {
    return {
      bookingsData: state.bookingsReducer.bookings,
      bookingsLoading: state.bookingsReducer.loading,
    };
  });

  const [showModal, setShowModal] = useState({
    modalType: null,
    status: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getAllBookings(dispatch);
  }, []);

  // For handling the close model
  const onCancel = () => {
    setShowModal({
      modalType: null,
      status: false,
    });
    setSelectedItem(null);
  };

  const handleDeleteClick = responseData => {
    setSelectedItem({ id: responseData?._id, name: `${responseData?.car?.name}` });
    setShowModal({
      modalType: MODAL_TYPE.delete,
      status: true,
    });
  };

  const handleChangeBookingStatus = responseData => {
    setSelectedItem({ id: responseData?._id, name: `${responseData?.car?.name}` });
    setShowModal({
      modalType: MODAL_TYPE.statusChange,
      status: true,
    });
  };

  const viewDetail = responseData => {
    setSelectedItem(responseData);
    setShowModal({
      modalType: MODAL_TYPE.detail,
      status: true,
    });
  };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteBookingById(selectedItem && selectedItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllBookings(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setSelectedItem(null);
    }
  };

  // To handle confirm change status of booking
  const handleChangeBookingStatusConfirmation = async () => {
    try {
      const res = await dispatch(changeBookingStatusById(selectedItem && selectedItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllBookings(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setSelectedItem(null);
    }
  };

  const bookingsTableData = bookingsData
    ? bookingsData.map((bookingRow, index) => {
        const { _id, fullName, email, phoneNumber, status } = bookingRow;

        return {
          key: _id,
          sNo: (
            <figure>
              <p>{index + 1}</p>
            </figure>
          ),
          fullName: (
            <div className="user-info">
              <Heading className="user-name" as="h6">
                {fullName}
              </Heading>
            </div>
          ),
          email: (
            <div className="user-info">
              <Heading className="user-name" as="h6">
                {email}
              </Heading>
            </div>
          ),
          phoneNumber: (
            <div className="user-info">
              <Heading className="user-name" as="h6">
                {`${phoneNumber.code}-${phoneNumber.number}`}
              </Heading>
            </div>
          ),
          status: (
            <div className="user-info">
              <Heading className="user-name" as="h6">
                {status && status.toUpperCase()}
              </Heading>
            </div>
          ),
          action: (
            <div className="table-actions">
              <Button className="btn-icon" type="primary" to="#" shape="circle" onClick={() => viewDetail(bookingRow)}>
                <FeatherIcon icon="eye" size={16} />
              </Button>
              <Button
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
                onClick={() => {
                  handleChangeBookingStatus(bookingRow);
                }}
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
              <Button
                className="btn-icon"
                type="danger"
                to="#"
                shape="circle"
                onClick={() => {
                  handleDeleteClick(bookingRow);
                }}
              >
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </div>
          ),
        };
      })
    : [];

  return (
    <>
      <DeleteConfirmationModal
        title="Booking"
        visible={showModal.status && showModal.modalType === MODAL_TYPE.delete}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={bookingsLoading}
        itemName={selectedItem?.name ?? ''}
      />

      <DisplayBooking
        onCancel={onCancel}
        visible={showModal.status && showModal.modalType === MODAL_TYPE.detail}
        booking={selectedItem}
      />

      <Modal
        type="primary"
        title="Change Booking Status"
        visible={showModal.status && showModal.modalType === MODAL_TYPE.statusChange}
        footer={[
          <div key="1" className="brand-modal-footer">
            <Button
              size="default"
              style={{ backgroundColor: '#155BDC', color: '#fff' }}
              key="submit"
              onClick={handleChangeBookingStatusConfirmation}
              loading={bookingsLoading}
            >
              Change Booking Status
            </Button>
            <Button size="default" type="white" key="back" outlined onClick={onCancel} disabled={bookingsLoading}>
              Cancel
            </Button>
          </div>,
        ]}
        onCancel={onCancel}
      >
        <div className="brand-modal">
          <BasicFormWrapper>
            <p>{`Change status of Booking ${selectedItem?.name}`}</p>
          </BasicFormWrapper>
        </div>
      </Modal>

      <PageHeader ghost title="Bookings" />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <>
              {bookingsLoading ? (
                <div className="spin">
                  <Spin />
                </div>
              ) : (
                <Cards headless>
                  <UserTableStyleWrapper>
                    <TableWrapper className="table-responsive">
                      <Table
                        dataSource={bookingsTableData}
                        columns={bookingsTableColumns}
                        pagination={{
                          defaultPageSize: 50,
                          total: bookingsTableData?.length,
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                      />
                    </TableWrapper>
                  </UserTableStyleWrapper>
                </Cards>
              )}
            </>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default BookingsMain;
