import * as Yup from 'yup';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicCarColorFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`color${item[1]}`]: '',
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicCarColorFields();
  const initialFormData = {
    isMultiLang: 'false',
    ...dynamicFields,
  };

  return initialFormData;
}

export const carColorInitialFormData = getInitialFormData();

export function carColorValidationSchema(isMultiLangFlag) {
  let validationFields = {
    [`color${DEFAULT_LANG.value}`]: Yup.string().required(`Car Color(${DEFAULT_LANG.label}) is required!`),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`color${value}`]: Yup.string().required(`Car Color(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`color${value}`]: Yup.string(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    ...validationFields,
  });
}

export const carColorTableColumns = [
  {
    title: 'S.No',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
