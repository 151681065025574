const actions = {
  GET_BODY_TYPE_BEGIN: 'GET_BODY_TYPE_BEGIN',
  GET_BODY_TYPE_SUCCESS: 'GET_BODY_TYPE_SUCCESS',
  GET_BODY_TYPE_FAILED: 'GET_BODY_TYPE_FAILED',

  ADD_BODY_TYPE_BEGIN: 'ADD_BODY_TYPE_BEGIN',
  ADD_BODY_TYPE_SUCCESS: 'ADD_BODY_TYPE_SUCCESS',
  ADD_BODY_TYPE_FAILED: 'ADD_BODY_TYPE_FAILED',

  UPDATE_BODY_TYPE_BEGIN: 'UPDATE_BODY_TYPE_BEGIN',
  UPDATE_BODY_TYPE_SUCCESS: 'UPDATE_BODY_TYPE_SUCCESS',
  UPDATE_BODY_TYPE_FAILED: 'UPDATE_BODY_TYPE_FAILED',

  DELETE_BODY_TYPE_BEGIN: 'DELETE_BODY_TYPE_BEGIN',
  DELETE_BODY_TYPE_SUCCESS: 'DELETE_BODY_TYPE_SUCCESS',
  DELETE_BODY_TYPE_FAILED: 'DELETE_BODY_TYPE_FAILED',

  getCarBodyTypeBegin: () => {
    return {
      type: actions.GET_BODY_TYPE_BEGIN,
    };
  },

  getCarBodyTypeSuccess: data => {
    return {
      type: actions.GET_BODY_TYPE_SUCCESS,
      data,
    };
  },

  getCarBodyTypeFailed: err => {
    return {
      type: actions.GET_BODY_TYPE_FAILED,
      err,
    };
  },

  addCarBodyTypeBegin: () => {
    return {
      type: actions.ADD_BODY_TYPE_BEGIN,
    };
  },

  addCarBodyTypeSuccess: data => {
    return {
      type: actions.ADD_BODY_TYPE_SUCCESS,
      data,
    };
  },

  addCarBodyTypeFailed: err => {
    return {
      type: actions.ADD_BODY_TYPE_FAILED,
      err,
    };
  },

  updateCarBodyTypeBegin: () => {
    return {
      type: actions.UPDATE_BODY_TYPE_BEGIN,
    };
  },

  updateCarBodyTypeSuccess: data => {
    return {
      type: actions.UPDATE_BODY_TYPE_SUCCESS,
      data,
    };
  },

  updateCarBodyTypeFailed: err => {
    return {
      type: actions.UPDATE_BODY_TYPE_FAILED,
      err,
    };
  },

  deleteCarBodyTypeBegin: () => {
    return {
      type: actions.DELETE_BODY_TYPE_BEGIN,
    };
  },

  deleteCarBodyTypeSuccess: data => {
    return {
      type: actions.DELETE_BODY_TYPE_SUCCESS,
      data,
    };
  },

  deleteCarBodyTypeFailed: err => {
    return {
      type: actions.DELETE_BODY_TYPE_FAILED,
      err,
    };
  },
};

export default actions;
