const actions = {
  GET_COLOR_BEGIN: 'GET_COLOR_BEGIN',
  GET_COLOR_SUCCESS: 'GET_COLOR_SUCCESS',
  GET_COLOR_FAILED: 'GET_COLOR_FAILED',

  ADD_COLOR_BEGIN: 'ADD_COLOR_BEGIN',
  ADD_COLOR_SUCCESS: 'ADD_COLOR_SUCCESS',
  ADD_COLOR_FAILED: 'ADD_COLOR_FAILED',

  UPDATE_COLOR_BEGIN: 'UPDATE_COLOR_BEGIN',
  UPDATE_COLOR_SUCCESS: 'UPDATE_COLOR_SUCCESS',
  UPDATE_COLOR_FAILED: 'UPDATE_COLOR_FAILED',

  DELETE_COLOR_BEGIN: 'DELETE_COLOR_BEGIN',
  DELETE_COLOR_SUCCESS: 'DELETE_COLOR_SUCCESS',
  DELETE_COLOR_FAILED: 'DELETE_COLOR_FAILED',

  getCarColorBegin: () => {
    return {
      type: actions.GET_COLOR_BEGIN,
    };
  },

  getCarColorSuccess: data => {
    return {
      type: actions.GET_COLOR_SUCCESS,
      data,
    };
  },

  getCarColorFailed: err => {
    return {
      type: actions.GET_COLOR_FAILED,
      err,
    };
  },

  addCarColorBegin: () => {
    return {
      type: actions.ADD_COLOR_BEGIN,
    };
  },

  addCarColorSuccess: data => {
    return {
      type: actions.ADD_COLOR_SUCCESS,
      data,
    };
  },

  addCarColorFailed: err => {
    return {
      type: actions.ADD_COLOR_FAILED,
      err,
    };
  },
  updateCarColorBegin: () => {
    return {
      type: actions.UPDATE_COLOR_BEGIN,
    };
  },

  updateCarColorSuccess: data => {
    return {
      type: actions.UPDATE_COLOR_SUCCESS,
      data,
    };
  },

  updateCarColorFailed: err => {
    return {
      type: actions.UPDATE_COLOR_FAILED,
      err,
    };
  },
  deleteCarColorBegin: () => {
    return {
      type: actions.DELETE_COLOR_BEGIN,
    };
  },

  deleteCarColorSuccess: data => {
    return {
      type: actions.DELETE_COLOR_SUCCESS,
      data,
    };
  },

  deleteCarColorFailed: err => {
    return {
      type: actions.DELETE_COLOR_FAILED,
      err,
    };
  },
};

export default actions;
