const actions = {
  GET_PRICE_TOOL_BEGIN: 'GET_PRICE_TOOL_BEGIN',
  GET_PRICE_TOOL_SUCCESS: 'GET_PRICE_TOOL_SUCCESS',
  GET_PRICE_TOOL_FAILED: 'GET_PRICE_TOOL_FAILED',

  GET_ONLY_PRICE_TOOL_BEGIN: 'GET_ONLY_PRICE_TOOL_BEGIN',
  GET_ONLY_PRICE_TOOL_SUCCESS: 'GET_ONLY_PRICE_TOOL_SUCCESS',
  GET_ONLY_PRICE_TOOL_FAILED: 'GET_ONLY_PRICE_TOOL_FAILED',

  ADD_PRICE_TOOL_BEGIN: 'ADD_PRICE_TOOL_BEGIN',
  ADD_PRICE_TOOL_SUCCESS: 'ADD_PRICE_TOOL_SUCCESS',
  ADD_PRICE_TOOL_FAILED: 'ADD_PRICE_TOOL_FAILED',

  UPDATE_PRICE_TOOL_BEGIN: 'UPDATE_PRICE_TOOL_BEGIN',
  UPDATE_PRICE_TOOL_SUCCESS: 'UPDATE_PRICE_TOOL_SUCCESS',
  UPDATE_PRICE_TOOL_FAILED: 'UPDATE_PRICE_TOOL_FAILED',

  DELETE_PRICE_TOOL_BEGIN: 'DELETE_PRICE_TOOL_BEGIN',
  DELETE_PRICE_TOOL_SUCCESS: 'DELETE_PRICE_TOOL_SUCCESS',
  DELETE_PRICE_TOOL_FAILED: 'DELETE_PRICE_TOOL_FAILED',

  getPriceToolBegin: () => {
    return {
      type: actions.GET_PRICE_TOOL_BEGIN,
    };
  },

  getPriceToolSuccess: data => {
    return {
      type: actions.GET_PRICE_TOOL_SUCCESS,
      data,
    };
  },

  getPriceToolFailed: err => {
    return {
      type: actions.GET_PRICE_TOOL_FAILED,
      err,
    };
  },

  addPriceToolBegin: () => {
    return {
      type: actions.ADD_PRICE_TOOL_BEGIN,
    };
  },

  addPriceToolSuccess: data => {
    return {
      type: actions.ADD_PRICE_TOOL_SUCCESS,
      data,
    };
  },

  addPriceToolFailed: err => {
    return {
      type: actions.ADD_PRICE_TOOL_FAILED,
      err,
    };
  },
  updatePriceToolBegin: () => {
    return {
      type: actions.UPDATE_PRICE_TOOL_BEGIN,
    };
  },

  updatePriceToolSuccess: data => {
    return {
      type: actions.UPDATE_PRICE_TOOL_SUCCESS,
      data,
    };
  },

  updatePriceToolFailed: err => {
    return {
      type: actions.UPDATE_PRICE_TOOL_FAILED,
      err,
    };
  },
  deletePriceToolBegin: () => {
    return {
      type: actions.DELETE_PRICE_TOOL_BEGIN,
    };
  },

  deletePriceToolSuccess: data => {
    return {
      type: actions.DELETE_PRICE_TOOL_SUCCESS,
      data,
    };
  },

  deletePriceToolFailed: err => {
    return {
      type: actions.DELETE_PRICE_TOOL_FAILED,
      err,
    };
  },
};

export default actions;
