const actions = {
  GET_STORE_BEGIN: 'GET_STORE_BEGIN',
  GET_STORE_SUCCESS: 'GET_STORE_SUCCESS',
  GET_STORE_FAILED: 'GET_STORE_FAILED',

  ADD_STORE_BEGIN: 'ADD_STORE_BEGIN',
  ADD_STORE_SUCCESS: 'ADD_STORE_SUCCESS',
  ADD_STORE_FAILED: 'ADD_STORE_FAILED',

  UPDATE_STORE_BEGIN: 'UPDATE_STORE_BEGIN',
  UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILED: 'UPDATE_STORE_FAILED',

  DELETE_STORE_BEGIN: 'DELETE_STORE_BEGIN',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_FAILED: 'DELETE_STORE_FAILED',

  getStoreBegin: () => {
    return {
      type: actions.GET_STORE_BEGIN,
    };
  },

  getStoreSuccess: data => {
    return {
      type: actions.GET_STORE_SUCCESS,
      data,
    };
  },

  getStoreFailed: err => {
    return {
      type: actions.GET_STORE_FAILED,
      err,
    };
  },

  addStoreBegin: () => {
    return {
      type: actions.ADD_STORE_BEGIN,
    };
  },

  addStoreSuccess: data => {
    return {
      type: actions.ADD_STORE_SUCCESS,
      data,
    };
  },

  addStoreFailed: err => {
    return {
      type: actions.ADD_STORE_FAILED,
      err,
    };
  },

  updateStoreBegin: () => {
    return {
      type: actions.UPDATE_STORE_BEGIN,
    };
  },

  updateStoreSuccess: data => {
    return {
      type: actions.UPDATE_STORE_SUCCESS,
      data,
    };
  },

  updateStoreFailed: err => {
    return {
      type: actions.UPDATE_STORE_FAILED,
      err,
    };
  },

  deleteStoreBegin: () => {
    return {
      type: actions.DELETE_STORE_BEGIN,
    };
  },

  deleteStoreSuccess: data => {
    return {
      type: actions.DELETE_STORE_SUCCESS,
      data,
    };
  },

  deleteStoreFailed: err => {
    return {
      type: actions.DELETE_STORE_FAILED,
      err,
    };
  },
};

export default actions;