/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import CarForm from './CarForm';
import { carInitialFormData } from './car.schema';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Button } from '../../../components/buttons/buttons';

const CreateCar = () => {
  const history = useHistory();
  // const { colorsData, carColorLoading } = useSelector(state => ({
  //   colorsData: state.carColor.colors,
  //   carColorLoading: state.carColor.loading,
  // }));
  const { userType } = useSelector(state => {
    return {
      carLoading: state.car.loading,
      userType: state.auth.user.role.name,
    };
  });

  const [formData, setFormData] = useState({ ...carInitialFormData });

  // useEffect(() => {
  //   getAllColors(dispatch);
  // }, []);

  return (
    <>
      <PageHeader
        ghost
        title="Add New Car"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/cars`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <div className="body-type-modal">
            <CarForm formData={formData} setFormData={setFormData} />
          </div>
        </Cards>
      </Main>
    </>
  );
};

export default CreateCar;
