import actions from './actions';

const {
  GET_CAR_MODEL_BEGIN, GET_CAR_MODEL_SUCCESS, GET_CAR_MODEL_FAILED,
  GET_CAR_MODEL_BY_BRAND_BEGIN, GET_CAR_MODEL_BY_BRAND_SUCCESS, GET_CAR_MODEL_BY_BRAND_FAILED,
  GET_BRAND_YEAR_BEGIN, GET_BRAND_YEAR_SUCCESS, GET_BRAND_YEAR_FAILED,
  ADD_MODEL_BEGIN, ADD_MODEL_SUCCESS, ADD_MODEL_FAILED,
  UPDATE_MODEL_BEGIN, UPDATE_MODEL_SUCCESS, UPDATE_MODEL_FAILED,
  DELETE_MODEL_BEGIN, DELETE_MODEL_SUCCESS, DELETE_MODEL_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  models: [],
  modelsByBrand: [],
  years: [],
  loading: false,
  error: null,
};


const CarModelReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CAR_MODEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CAR_MODEL_SUCCESS:
      return {
        ...state,
        models: data.carModels,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_CAR_MODEL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_CAR_MODEL_BY_BRAND_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CAR_MODEL_BY_BRAND_SUCCESS:
      return {
        ...state,
        modelsByBrand: data,
        loading: false,
      };
    case GET_CAR_MODEL_BY_BRAND_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_BRAND_YEAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BRAND_YEAR_SUCCESS:
      return {
        ...state,
        years: data,
        loading: false,
      };
    case GET_BRAND_YEAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_MODEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_MODEL_SUCCESS:
      return {
        ...state,
        models: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_MODEL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_MODEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        models: data,
        loading: false,
      };
    case UPDATE_MODEL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_MODEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MODEL_SUCCESS:
      return {
        ...state,
        models: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_MODEL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CarModelReducer;