const actions = {
  GET_CAR_MODEL_BEGIN: 'GET_CAR_MODEL_BEGIN',
  GET_CAR_MODEL_SUCCESS: 'GET_CAR_MODEL_SUCCESS',
  GET_CAR_MODEL_FAILED: 'GET_CAR_MODEL_FAILED',

  GET_CAR_MODEL_BY_BRAND_BEGIN: 'GET_CAR_MODEL_BY_BRAND_BEGIN',
  GET_CAR_MODEL_BY_BRAND_SUCCESS: 'GET_CAR_MODEL_BY_BRAND_SUCCESS',
  GET_CAR_MODEL_BY_BRAND_FAILED: 'GET_CAR_MODEL_BY_BRAND_FAILED',

  GET_BRAND_YEAR_BEGIN: 'GET_BRAND_YEAR_BEGIN',
  GET_BRAND_YEAR_SUCCESS: 'GET_BRAND_YEAR_SUCCESS',
  GET_BRAND_YEAR_FAILED: 'GET_BRAND_YEAR_FAILED',

  ADD_MODEL_BEGIN: 'ADD_MODEL_BEGIN',
  ADD_MODEL_SUCCESS: 'ADD_MODEL_SUCCESS',
  ADD_MODEL_FAILED: 'ADD_MODEL_FAILED',

  UPDATE_MODEL_BEGIN: 'UPDATE_MODEL_BEGIN',
  UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
  UPDATE_MODEL_FAILED: 'UPDATE_MODEL_FAILED',

  DELETE_MODEL_BEGIN: 'DELETE_MODEL_BEGIN',
  DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
  DELETE_MODEL_FAILED: 'DELETE_MODEL_FAILED',

  getCarModelBegin: () => {
    return {
      type: actions.GET_CAR_MODEL_BEGIN,
    };
  },

  getCarModelSuccess: data => {
    return {
      type: actions.GET_CAR_MODEL_SUCCESS,
      data,
    };
  },

  getCarModelFailed: err => {
    return {
      type: actions.GET_CAR_MODEL_FAILED,
      err,
    };
  },

  getCarModelByBrandBegin: () => {
    return {
      type: actions.GET_CAR_MODEL_BY_BRAND_BEGIN,
    };
  },

  getCarModelByBrandSuccess: data => {
    return {
      type: actions.GET_CAR_MODEL_BY_BRAND_SUCCESS,
      data,
    };
  },

  getCarModelByBrandFailed: err => {
    return {
      type: actions.GET_CAR_MODEL_BY_BRAND_FAILED,
      err,
    };
  },

  getBrandYearBegin: () => {
    return {
      type: actions.GET_BRAND_YEAR_BEGIN,
    };
  },

  getBrandYearSuccess: data => {
    return {
      type: actions.GET_BRAND_YEAR_SUCCESS,
      data,
    };
  },

  getBrandYearFailed: err => {
    return {
      type: actions.GET_BRAND_YEAR_FAILED,
      err,
    };
  },

  addCarModelBegin: () => {
    return {
      type: actions.ADD_MODEL_BEGIN,
    };
  },

  addCarModelSuccess: data => {
    return {
      type: actions.ADD_MODEL_SUCCESS,
      data,
    };
  },

  addCarModelFailed: err => {
    return {
      type: actions.ADD_MODEL_FAILED,
      err,
    };
  },

  updateCarModelBegin: () => {
    return {
      type: actions.UPDATE_MODEL_BEGIN,
    };
  },

  updateCarModelSuccess: data => {
    return {
      type: actions.UPDATE_MODEL_SUCCESS,
      data,
    };
  },

  updateCarModelFailed: err => {
    return {
      type: actions.UPDATE_MODEL_FAILED,
      err,
    };
  },
  deleteCarModelBegin: () => {
    return {
      type: actions.DELETE_MODEL_BEGIN,
    };
  },

  deleteCarModelSuccess: data => {
    return {
      type: actions.DELETE_MODEL_SUCCESS,
      data,
    };
  },

  deleteCarModelFailed: err => {
    return {
      type: actions.DELETE_MODEL_FAILED,
      err,
    };
  },
};

export default actions;
