/* eslint-disable no-underscore-dangle */
import { Col, Row, Spin, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import CarColorForm from './overview/CarColorForm';
import { carColorInitialFormData, carColorTableColumns } from './overview/carColor.schema';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';
import { Modal } from '../../components/modals/antd-modals';
import { PageHeader } from '../../components/page-headers/page-headers';
import { deleteCarColor, getAllColors } from '../../redux/car color/actionCreator';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../utils/constants';
import { UserTableStyleWrapper } from '../pages/style';
import { Main, TableWrapper } from '../styled';
import Heading from '../../components/heading/heading';

const CarColor = () => {
  const dispatch = useDispatch();
  const { colorsData, carColorLoading } = useSelector(state => ({
    colorsData: state.carColor.colors,
    carColorLoading: state.carColor.loading,
  }));
  const currentLang = Cookies.get('lang');

  const [formData, setFormData] = useState({ ...carColorInitialFormData });
  const [showModal, setShowModal] = useState({
    status: false,
    modalType: 'form', // modalType can be either 'form' or 'delete'
  });
  const [deleteItem, setDeleteItem] = useState(null);
  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 50 });
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    getAllColors(dispatch);
  }, []);

  useEffect(() => {
    const faqWithLang = colorsData?.find(faq => faq.details.length > 1);
    if (faqWithLang) {
      const indexOfData = faqWithLang?.details?.findIndex(faq => faq?.lang === currentLang);
      // eslint-disable-next-line no-unused-expressions
      indexOfData > -1 ? setDataIndex(indexOfData) : setDataIndex(0);
    }
  }, [currentLang, colorsData]);

  // For toggling model
  const handleToggleModal = modalType => {
    setShowModal({
      status: !showModal.status,
      modalType,
    });
  };

  // For handling the close model
  const onCancel = () => {
    setShowModal({
      status: false,
      modalType: 'form',
    });
    setDeleteItem(null);
    setFormData({ ...carColorInitialFormData });
  };

  const handleEditClick = responseData => {
    const apiResData = {
      _id: responseData?._id,
      isMultiLang: responseData?.details && responseData?.details.length > 1 ? 'true' : 'false',
    };

    let bodyColorFields = {};
    if (responseData?.details.length > 1) {
      // eslint-disable-next-line no-unused-vars
      LANGUAGE_BY_DOMAIN.forEach((langItem, index) => {
        bodyColorFields = {
          ...bodyColorFields,
          [`color${langItem[1]}`]: responseData?.details[index]?.color ?? '',
        };
      });
    } else {
      bodyColorFields = {
        ...bodyColorFields,
        [`color${DEFAULT_LANG.value}`]: responseData?.details[0]?.color ?? '',
      };
    }

    setFormData({
      ...formData,
      ...apiResData,
      ...bodyColorFields,
    });

    setShowModal({ status: true, modalType: 'form' });
  };

  const handleDeleteClick = responseData => {
    setDeleteItem({ id: responseData?._id, name: responseData?.details[dataIndex].color });
    setShowModal({ status: true, modalType: 'delete' });
  };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteCarColor(deleteItem && deleteItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllColors(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setDeleteItem(null);
    }
  };

  const carColorTableData = colorsData?.map((color, index) => {
    const { _id, details } = color;

    return {
      key: _id,
      sNo: <p>{index + 1}</p>,
      color: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {details[dataIndex]?.color ?? ' NA'}
          </Heading>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Button className="btn-icon" type="info" to="#" shape="circle" onClick={() => handleEditClick(color)}>
            <FeatherIcon icon="edit" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => handleDeleteClick(color)}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    };
  });

  return (
    <>
      <DeleteConfirmationModal
        title="Car Color"
        visible={showModal.modalType === 'delete' && showModal.status}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={carColorLoading}
        itemName={deleteItem?.name ?? ''}
      />
      <Modal
        type="primary"
        title={formData?._id ? 'Edit Car Color' : 'Create Car Color'}
        visible={showModal.modalType === 'form' && showModal.status}
        width={800}
        onCancel={onCancel}
        footer={null}
      >
        <div className="body-type-modal">
          <CarColorForm formData={formData} onCancel={onCancel} />{' '}
        </div>
      </Modal>
      <PageHeader
        ghost
        title="Car Color"
        buttons={[
          <Button
            onClick={() => handleToggleModal('form')}
            key="1"
            style={{ backgroundColor: '#155BDC', color: '#fff' }}
            size="default"
          >
            <FeatherIcon icon="plus" size={16} /> Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {carColorLoading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <Cards headless>
                <UserTableStyleWrapper>
                  <TableWrapper className="table-responsive">
                    <Table
                      dataSource={carColorTableData}
                      columns={carColorTableColumns}
                      pagination={{
                        defaultPageSize: pagination.pageLimit,
                        current: pagination.currentPage,
                        total: carColorTableData?.length,
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                      }}
                      onChange={data =>
                        setPagination(prev => ({ ...prev, currentPage: data.current, pageLimit: data.pageSize }))
                      }
                    />
                  </TableWrapper>
                </UserTableStyleWrapper>
              </Cards>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default CarColor;
