import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getReportBegin, getReportSuccess, getReportFailed,
  addReportBegin, addReportSuccess, addReportFailed,
  updateReportBegin, updateReportSuccess, updateReportFailed,
  deleteReportBegin, deleteReportSuccess, deleteReportFailed,
} = actions;

// GETTING ALL CAR INSPECTION_REPORTS
let cancelToken;
export const getAllInspectionReports = filter => async (dispatch) => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getReportSuccess({ inspectionReports: [] }));
    dispatch(getReportBegin());
    const res = await axios.get(`inspection-reports${filter ? filter : ''}`, { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    dispatch(getReportSuccess(res.data));
  } catch (err) {
    dispatch(getReportFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false))
  }
}

// ADDING NEW CAR INSPECTION_REPORT
export const addNewInspectionReport = (datas, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addReportBegin());
    const res = await axios.post('inspection-reports', datas, { headers: { ...getHeaders() } })
    const newInspectionReports = [res.data.inspectionReport, ...state.inspectionReport.reports]

    dispatch(addReportSuccess(newInspectionReports));
    message.success('New car inspection report created successfully');
    history.push('/admin/inspection-reports');
    return res;
  } catch (err) {
    dispatch(addReportFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING INSPECTION_REPORT BY ID
export const editInspectionReport = (id, datas, history) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateReportBegin());
    const res = await axios.put(`inspection-reports/${id}`, datas, { headers: { ...getHeaders() } });
    const newInspectionReports = state.inspectionReport.reports.map(report => {
      if (report._id === id) return res.data.inspectionReport;
      else return report;
    })
    dispatch(updateReportSuccess(newInspectionReports));
    message.success('Car inspection report updated successfully');
    history.push('/admin/inspection-reports');
    return res;
  } catch (err) {
    dispatch(updateReportFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE INSPECTION REPORT FILE
export const deleteInspectionReportFile = (id, file, type) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateReportBegin());
    const res = await axios.delete(`inspection-reports/${id}/${file}?type=${type}`, {
      headers: { ...getHeaders() },
    });
    const newInspectionReports = state.inspectionReport.reports.map(c => {
      if (c._id === id) return res.data.inspectionReport;
      else return c;
    });
    dispatch(updateReportSuccess(newInspectionReports));
    message.success(`File removed successfully`);
    return res;
  } catch (err) {
    dispatch(updateReportFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CAR INSPECTION_REPORT
export const deleteInspectionReport = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteReportBegin());
    const res = await axios.delete(`inspection-reports/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteReportSuccess(state.inspectionReport.reports.filter(report => report._id !== id)));
    message.success('Car inspection report deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteReportFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 