/* eslint-disable no-underscore-dangle */
import { Col, Row, Switch } from 'antd';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blogCategoryValidationSchema } from './blogCategory.schema';
import { Button } from '../../../components/buttons/buttons';
import FormInput from '../../../components/input/input';
import { addNewBlogCategory, editBlogCategory, getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import { BasicFormWrapper } from '../../styled';
import DivBreak from '../../../components/divBreak/DivBreak';

const BlogCategoryForm = props => {
  const { formData, onCancel } = props;
  const dispatch = useDispatch();

  const { loading: blogCategoryLoading } = useSelector(state => state.blogCategory);

  const [isMultiLangFlag, setIsMultiLangFlag] = useState(false);

  useEffect(() => {
    setIsMultiLangFlag(formData?.isMultiLang.includes('true'));
  }, [formData]);

  async function handleFormSubmit(requestData, resetForm) {
    try {
      const transformedBlogCategory = [];
      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          transformedBlogCategory.push({
            lang: langItem[1],
            category: requestData[`category${langItem[1]}`],
          });
        }
      });

      const apiReqData = {
        details: transformedBlogCategory,
      };

      // eslint-disable-next-line no-underscore-dangle
      const res = await dispatch(
        requestData?._id ? editBlogCategory(requestData?._id, apiReqData) : addNewBlogCategory(apiReqData),
      );
      if (res?.data?.status) {
        resetForm();
        onCancel();
        getAllBlogCategories(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={blogCategoryValidationSchema(isMultiLangFlag)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        // eslint-disable-next-line no-use-before-define
        handleFormSubmit(values, resetForm);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form>
            <BasicFormWrapper>
              <div className="add-product-block">
                <Row gutter={[16, 16]}>
                  {/* language switcher */}
                  <Col xs={24}>
                    <h4>Translate</h4>
                    <Switch
                      checked={values.isMultiLang === 'true'}
                      onChange={checked => {
                        setFieldValue('isMultiLang', String(checked));
                        setIsMultiLangFlag(checked);
                      }}
                    />
                  </Col>

                  {/* category default  */}
                  <Col xs={24}>
                    <FormInput
                      name={`category${DEFAULT_LANG.value}`}
                      label={`Category (${DEFAULT_LANG.label})`}
                      value={values[`category${DEFAULT_LANG.value}`]}
                      handleChange={handleChange}
                      placeholder="Category"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* category dynamic  */}
                  {values.isMultiLang === 'true' &&
                    LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                      return (
                        <Col xs={24} key={index}>
                          <FormInput
                            name={`category${value}`}
                            label={`Category (${label})`}
                            value={values[`category${value}`]}
                            handleChange={handleChange}
                            placeholder="Category"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>

              <DivBreak />

              <Row justify="end" align="middle">
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#155BDC',
                      color: '#fff',
                      marginRight: 5,
                      height: '40px',
                      fontWeight: '500',
                    }}
                    htmlType="submit"
                    loading={blogCategoryLoading}
                  >
                    {values?._id ? 'Edit Blog Category' : 'Add Blog Category'}
                  </Button>
                  <Button size="default" type="white" key="back" outlined onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

BlogCategoryForm.propTypes = {
  formData: propTypes.object,
  onCancel: propTypes.func,
};

export default BlogCategoryForm;
