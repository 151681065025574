/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { UserTableStyleWrapper } from '../../pages/style';
import { TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import Truncate from '../../../components/utilities/truncate';

const ContactQueryTable = ({ contactQueries, totalQuery, queryState, currentPage, pageLimit }) => {

    
    const handlePageOrLimitChange = (data) => { queryState({ currentPage: data.current, pageLimit: data.pageSize }) };

    
    const newCustomerQueries = [];
    const [data, setData] = useState([])
    useEffect(() => {
        setData(contactQueries)

    }, [contactQueries])

    data.map((query, index) => {
        const { _id, fullName, email, phoneNumber, message } = query;

        return newCustomerQueries.push({
            key: _id,
            sNo: (
                <figure>
                    <p>{index + 1}</p>
                </figure>
            ),
            fullName: (
                <div className="user-info">
                    <Heading className="user-name" as="h6">
                        {fullName}
                    </Heading>
                </div>
            ),
            message: (
                <div className="user-info" style={{ whiteSpace: "pre-wrap" }}>
                    <Heading className="user-name" as="h6">
                        <Truncate ln={500} text={message} />
                    </Heading>
                </div>
            ),
            phoneNumber: (
                <div className="user-info">
                    <Heading className="user-name" as="h6">
                        {phoneNumber?.code}{phoneNumber.number}
                    </Heading>
                </div>
            ),
            emailAddress: (
                <div className="user-info">
                    <Heading className="user-name" as="h6">
                        {email}
                    </Heading>
                </div>
            ),

        });
    });


    const contactQueryTableColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNo',
            key: 'sNo',
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
    ];
    return (
        <Cards headless>
            <UserTableStyleWrapper>
                <TableWrapper className="table-responsive">
                    <Table
                        dataSource={newCustomerQueries}
                        columns={contactQueryTableColumns}
                        pagination={{
                            defaultPageSize: pageLimit,
                            total: totalQuery,
                            current: currentPage,
                            showSizeChanger: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalQuery} items`,
                        }}
                        onChange={handlePageOrLimitChange}
                    />
                </TableWrapper>
            </UserTableStyleWrapper>
        </Cards>
    );
}

export default ContactQueryTable