import { combineReducers } from 'redux';
import CarBrandReducer from './carbrand/reducers';
import CarModelReducer from './carmodel/reducers';
import CarBodyTypeReducer from './carbodytype/reducers';
import CarReducer from './car/reducers';
import InspectionReport from './inspection report/reducers';
import CarColorReducer from './car color/reducers';
import UserReducer from './users/reducers';
import RoleReducer from './role/reducers';
import BlogCategory from './blogcategory/reducers';
import Blog from './blog/reducers';
import Page from './page/reducers';
import bookingsReducer from './bookings/reducers';
import SellingCar from './sell car/reducers';
import FAQ from './faq/reducers';
import CustomerInterest from './customer interest/reducers';
import Testimonial from './testimonial/reducers';
import CustomerQuery from './customer query/reducers';
import FinancingPartner from './financing partner/reducers';
import Financing from './financing/reducers';
import Store from './store/reducers';
import Subscriber from './subscriber/reducers';
import ServicingHistory from './servicing history/reducers';
import Dashboard from './dashboard/reducers';
import SearchInquiry from './search inquiry/reducers';
import ContactInformation from './contact information/reducers';
import headerSearchReducer from './headerSearch/reducers';

import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import galleryReducer from './gallery/reducers';
import HomepageContentReducers from './homePageContent';
import ContactQueryReducer from './contact query/reducers';
import OfferCar from './offerCar/reducers';
import PriceToolReducer from './priceTool/reducers';
import OurTeamReducer from './ourTeam/reducers';
import PressReducer from './press/reducers';
import CarPriceRangeReducer from './carPriceRange/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  headerSearch: headerSearchReducer,
  carBrand: CarBrandReducer,
  carPriceRange: CarPriceRangeReducer,
  carModel: CarModelReducer,
  carBodyType: CarBodyTypeReducer,
  carColor: CarColorReducer,
  car: CarReducer,
  inspectionReport: InspectionReport,
  user: UserReducer,
  role: RoleReducer,
  blogCategory: BlogCategory,
  blog: Blog,
  page: Page,
  bookingsReducer,
  sellingCar: SellingCar,
  faq: FAQ,
  customerInterest: CustomerInterest,
  testimonial: Testimonial,
  gallery: galleryReducer,
  customerQuery: CustomerQuery,
  contactQueries: ContactQueryReducer,
  financingPartner: FinancingPartner,
  financing: Financing,
  store: Store,
  subscriber: Subscriber,
  servicingHistory: ServicingHistory,
  dashboard: Dashboard,
  searchInquiry: SearchInquiry,
  contactInformation: ContactInformation,

  notification: readNotificationReducer,
  ChangeLayoutMode,
  homepageContent: HomepageContentReducers,
  offerCar: OfferCar,
  priceTool: PriceToolReducer,
  ourTeam: OurTeamReducer,
  press: PressReducer,
});

export default rootReducers;
