import { Select } from 'antd';
import React from 'react';
import { PropTypes } from 'prop-types';
import './formSelect.style.css';

const FormSelect = props => {
  const {
    label,
    name,
    isDisabled = false,
    isRequired = true,
    id,
    value,
    style,
    errorMsg,
    helperText,
    className,
    handleChange,
    options,
    showSearch = true,
    ...rest
  } = props;
  return (
    <div className={isRequired ? 'input-required' : 'form-input'} id="select-container">
      <div className="input-label">
        <label htmlFor={id ?? name}>{label}</label>
      </div>
      <div className={`${rest.errors?.[`${name}`] ? 'fieldError' : ''}`}>
        <Select
          style={{ width: '100%', ...style }}
          showSearch={showSearch}
          placeholder={rest.placeholder}
          defaultValue={rest.defaultValue}
          value={value}
          onChange={handleChange}
          optionFilterProp="items"
          filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
          disabled={isDisabled}
          allowClear={rest.allowClear}
          {...rest}
          getPopupContainer={() => document.getElementById('select-container')}
          dropdownStyle={{ zIndex: 998 }}
        >
          {options?.map(opt => (
            <Select.Option value={opt?.value} key={`${opt?.key}`}>
              {opt?.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="input-required-msg">
        {rest?.errors && rest?.touched && rest.errors?.[`${name}`] && rest.touched?.[`${name}`] && (
          <span>{rest.errors?.[`${name}`]}</span>
        )}
      </div>
    </div>
  );
};

export default FormSelect;

FormSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  showSearch: PropTypes.bool,
  value: PropTypes.string.isRequired,
  style: PropTypes.string,
  errorMsg: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array.isRequired,
};
