/* eslint-disable no-underscore-dangle */
import { Col, Row, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { contactInfoInitialFormData } from './overview/contactInfo.schema';
import ContactInfoForm from './overview/ContactInfoForm';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { Modal } from '../../components/modals/antd-modals';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';
import { PageHeader } from '../../components/page-headers/page-headers';
import { deleteContactInformation, getAllContactInformations } from '../../redux/contact information/actionCreator';
import { UserBioBox } from '../profile/myProfile/style';
import { Main } from '../styled';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../utils/constants';
import { convertToEditorFormat } from '../../utility/utility';

const ContactInfoMain = () => {
  const dispatch = useDispatch();
  const { information: contactInfoData, loading: contactInfoLoading } = useSelector(state => {
    return {
      information: state.contactInformation.information,
      loading: state.contactInformation.loading,
    };
  });

  const [formData, setFormData] = useState({ ...contactInfoInitialFormData });
  const [showModal, setShowModal] = useState({
    status: false,
    modalType: 'form', // modalType can be either 'form' or 'delete'
  });
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    getAllContactInformations(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For toggling model
  const handleToggleModal = modalType => {
    setShowModal({
      status: !showModal.status,
      modalType,
    });
  };

  // For handling the close model
  const onCancel = () => {
    setShowModal({
      status: false,
      modalType: 'form',
    });
    setDeleteItem(null);
    setFormData({ ...contactInfoInitialFormData });
  };

  const handleEditClick = responseData => {
    const apiResData = {
      _id: responseData?._id,
      fullName: responseData?.fullName,
      email: responseData?.email,
      code: responseData?.phoneNumber?.code,
      number: responseData?.phoneNumber?.number,
      whatsapp: responseData?.whatsapp,
      facebook: responseData?.social?.facebook ?? '',
      instagram: responseData?.social?.instagram ?? '',
      twitter: responseData?.social?.twitter ?? '',
      linkedIn: responseData?.social?.linkedIn ?? '',
      youtube: responseData?.social?.youtube ?? '',
      tiktok: responseData?.social?.tiktok ?? '',
      isMultiLang: responseData?.copyright_details && responseData?.copyright_details.length > 1 ? 'true' : 'false',
      location: responseData?.location,
    };

    let copyRightAndFooterFields = {};
    if (responseData?.copyright_details.length > 1) {
      // eslint-disable-next-line no-unused-vars
      LANGUAGE_BY_DOMAIN.forEach((langItem, index) => {
        copyRightAndFooterFields = {
          ...copyRightAndFooterFields,
          [`footer${langItem[1]}`]: responseData?.footer_details[index]?.footer ?? '',
          [`copyright${langItem[1]}`]: convertToEditorFormat(responseData?.copyright_details[index]?.copyright) ?? '',
        };
      });
    } else {
      copyRightAndFooterFields = {
        ...copyRightAndFooterFields,
        [`footer${DEFAULT_LANG.value}`]: responseData?.footer_details[0]?.footer ?? '',
        [`copyright${DEFAULT_LANG.value}`]: convertToEditorFormat(responseData?.copyright_details[0]?.copyright) ?? '',
      };
    }

    setFormData({
      ...formData,
      ...apiResData,
      ...copyRightAndFooterFields,
    });

    handleToggleModal('form');
  };

  // const handleDeleteClick = responseData => {
  //   setDeleteItem({ id: responseData?._id, name: responseData?.fullName });
  //   setShowModal({ status: true, modalType: 'delete' });
  // };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteContactInformation(deleteItem && deleteItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllContactInformations(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setDeleteItem(null);
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Contact Information"
        visible={showModal.modalType === 'delete' && showModal.status}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={contactInfoLoading}
        itemName={deleteItem?.name ?? ''}
      />
      <Modal
        type="primary"
        title={formData?._id ? 'Edit Contact Information' : 'Create Contact Information'}
        visible={showModal.modalType === 'form' && showModal.status}
        // visible={true}
        width={800}
        onCancel={onCancel}
        footer={null}
      >
        <div className="body-type-modal">
          <ContactInfoForm formData={formData} onCancel={onCancel} />
        </div>
      </Modal>

      <PageHeader
        ghost
        title="Contact Information"
        buttons={
          Object.keys(contactInfoData).length === 0 ? (
            [
              <Button
                onClick={() => handleToggleModal('form')}
                key="1"
                style={{ backgroundColor: '#155BDC', color: '#fff' }}
                size="default"
              >
                <FeatherIcon icon="plus" size={16} /> Add New
              </Button>,
            ]
          ) : (
            <>
              <Button
                onClick={() => handleEditClick(contactInfoData)}
                key="1"
                style={{ backgroundColor: '#155BDC', color: '#fff' }}
                size="default"
              >
                <FeatherIcon icon="edit" size={16} /> Edit
              </Button>
            </>
          )
        }
      />
      {contactInfoLoading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <>
          {Object.keys(contactInfoData).length > 0 ? (
            <Main>
              <Row gutter={15}>
                <Col xxl={24} lg={24} md={24} xs={24}>
                  <UserBioBox>
                    <Cards headless>
                      <address className="user-info">
                        <Heading as="h4">{contactInfoData.fullName}</Heading>
                        <br />
                        <h5 className="user-info__title">Contact Info</h5>
                        <ul className="user-info__contact">
                          <li>
                            <FeatherIcon icon="mail" size={14} /> <span>{contactInfoData?.email}</span>
                          </li>
                          <li>
                            <FeatherIcon icon="phone" size={14} />{' '}
                            <span>
                              {contactInfoData?.phoneNumber?.code}
                              {contactInfoData?.phoneNumber?.number}
                            </span>
                          </li>
                          <li>
                            <FeatherIcon icon="globe" size={14} /> <span>https://auto24.com</span>
                          </li>
                          {contactInfoData?.location?.map((item, index) => (
                            <li>
                              <FeatherIcon icon="navigation" size={14} /> <span key={index}>{item?.address}</span>
                            </li>
                          ))}
                        </ul>
                      </address>
                      <div className="information-info" style={{ marginLeft: 20 }}>
                        <h5 className="information-info__title" style={{ fontSize: 20 }}>
                          Social Profiles
                        </h5>
                        <div className="card__social">
                          <a
                            className="btn-icon facebook"
                            href={contactInfoData?.social?.facebook ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesome name="facebook" />
                          </a>
                          <a
                            className="btn-icon twitter"
                            href={contactInfoData?.social?.twitter ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesome name="twitter" />
                          </a>
                          <a
                            className="btn-icon dribble"
                            href={contactInfoData?.social?.linkedIn ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesome name="linkedin" />
                          </a>
                          <a
                            className="btn-icon instagram"
                            href={contactInfoData?.social?.instagram ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesome name="instagram" />
                          </a>
                          <a
                            className="btn-icon instagram"
                            href={contactInfoData?.social?.youtube ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesome name="youtube" />
                          </a>
                          <a
                            className="btn-icon tiktok"
                            href={contactInfoData?.social?.tiktok ?? '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../static/img/icon/tiktok.svg')}
                              style={{ width: 15, height: 15 }}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </Cards>
                  </UserBioBox>
                </Col>
              </Row>
            </Main>
          ) : (
            <p style={{ fontSize: 16, textAlign: 'center' }}>Contact Information Not Found</p>
          )}
        </>
      )}
    </>
  );
};

export default ContactInfoMain;
