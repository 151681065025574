const actions = {
  GET_FOOTER_CONTENT_BEGIN: 'GET_FOOTER_CONTENT_BEGIN',
  GET_FOOTER_CONTENT_SUCCESS: 'GET_FOOTER_CONTENT_SUCCESS',
  GET_FOOTER_CONTENT_FAILED: 'GET_FOOTER_CONTENT_FAILED',

  ADD_FOOTER_CONTENT_BEGIN: 'ADD_FOOTER_CONTENT_BEGIN',
  ADD_FOOTER_CONTENT_SUCCESS: 'ADD_FOOTER_CONTENT_SUCCESS',
  ADD_FOOTER_CONTENT_FAILED: 'ADD_FOOTER_CONTENT_FAILED',

  getFooterContentBegin: () => {
    return {
      type: actions.GET_FOOTER_CONTENT_BEGIN,
    };
  },

  getFooterContentSuccess: data => {
    return {
      type: actions.GET_FOOTER_CONTENT_SUCCESS,
      data,
    };
  },

  getFooterContentFailed: err => {
    return {
      type: actions.GET_FOOTER_CONTENT_FAILED,
      err,
    };
  },

  addFooterContentBegin: () => {
    return {
      type: actions.ADD_FOOTER_CONTENT_BEGIN,
    };
  },

  addFooterContentSuccess: data => {
    return {
      type: actions.ADD_FOOTER_CONTENT_SUCCESS,
      data,
    };
  },

  addFooterContentFailed: err => {
    return {
      type: actions.ADD_FOOTER_CONTENT_FAILED,
      err,
    };
  },
};

export default actions;