import actions from './actions';

const {
  GET_FINANCING_BEGIN, GET_FINANCING_SUCCESS, GET_FINANCING_FAILED,
  ADD_FINANCING_BEGIN, ADD_FINANCING_SUCCESS, ADD_FINANCING_FAILED,
  UPDATE_FINANCING_BEGIN, UPDATE_FINANCING_SUCCESS, UPDATE_FINANCING_FAILED,
  DELETE_FINANCING_BEGIN, DELETE_FINANCING_SUCCESS, DELETE_FINANCING_FAILED
} = actions;

const initState = {
  total: 0,
  financings: [],
  loading: false,
  error: null,
};


const FinancingReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_FINANCING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FINANCING_SUCCESS:
      return {
        ...state,
        total: data.total,
        financings: data.financings,
        loading: false,
      };
    case GET_FINANCING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_FINANCING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FINANCING_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        financings: data,
        loading: false,
      };
    case ADD_FINANCING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_FINANCING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FINANCING_SUCCESS:
      return {
        ...state,
        financings: data,
        loading: false,
      };
    case UPDATE_FINANCING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_FINANCING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FINANCING_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        financings: data,
        loading: false,
      };
    case DELETE_FINANCING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default FinancingReducer;