const actions = {
  GET_DASHBOARD_DATA_BEGIN: 'GET_DASHBOARD_DATA_BEGIN',
  GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  GET_DASHBOARD_DATA_FAILED: 'GET_DASHBOARD_DATA_FAILED',

  getDashboardDataBegin: () => {
    return {
      type: actions.GET_DASHBOARD_DATA_BEGIN,
    };
  },

  getDashboardDataSuccess: data => {
    return {
      type: actions.GET_DASHBOARD_DATA_SUCCESS,
      data,
    };
  },

  getDashboardDataFailed: err => {
    return {
      type: actions.GET_DASHBOARD_DATA_FAILED,
      err,
    };
  },
};

export default actions;