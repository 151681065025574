import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import BlogForm from './BlogForm';
import { blogInitialFormData } from './blog.schema';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import '../Blog.css';
import { Main } from '../../styled';
import { getAllUsers } from '../../../redux/users/actionCreator';
import { getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { Button } from '../../../components/buttons/buttons';

const AddBlog = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { blogLoading, userType } = useSelector(state => {
    return {
      blogLoading: state.blog.loading,
      userType: state.auth.user.role.name,
    };
  });

  const [formData, setFormData] = useState({ ...blogInitialFormData });

  useEffect(() => {
    dispatch(getAllUsers('?role=writer'));
    getAllBlogCategories(dispatch);
  }, []);

  return blogLoading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader
        ghost
        title="Add Blog"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/blogs`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={1} />
            <Col xs={22}>
              <div className="edit-blog">
                <BlogForm formData={formData} setFormData={setFormData} />
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddBlog;
