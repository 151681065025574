import React from 'react';
import propTypes from 'prop-types';
import { Modal } from './antd-modals';
import { Button } from '../buttons/buttons';
import { BasicFormWrapper } from '../../container/styled';

const DeleteConfirmationModal = props => {
  const { title, visible, onCancel, handleDeleteConfirmation, deleteButtonLoading, itemName, children } = props;
  return (
    <Modal
      type="primary"
      title={`Delete ${title ?? ''}`}
      visible={visible}
      footer={[
        <div key="1" className="brand-modal-footer">
          <Button
            size="default"
            style={{ backgroundColor: '#155BDC', color: '#fff' }}
            key="submit"
            onClick={handleDeleteConfirmation}
            loading={deleteButtonLoading}
          >
            {`Delete ${title ?? ''}`}
          </Button>
          <Button size="default" type="white" key="back" outlined onClick={onCancel} disabled={deleteButtonLoading}>
            Cancel
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
    >
      {children ?? (
        <div className="brand-modal">
          <BasicFormWrapper>
            <p>{`Delete ${title ?? ''} ${itemName ? `"${itemName}"` : ''}`}</p>
          </BasicFormWrapper>
        </div>
      )}
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  title: propTypes.string.isRequired,
  visible: propTypes.bool.isRequired,
  deleteButtonLoading: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
  handleDeleteConfirmation: propTypes.func.isRequired,
  itemName: propTypes.string,
  children: propTypes.any,
};

export default DeleteConfirmationModal;
