import { Col, Divider, Row } from 'antd';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons/buttons';
import DivBreak from '../../../components/divBreak/DivBreak';
import FormUpload from '../../../components/upload/FormUpload';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal';
import { deleteCarFile } from '../../../redux/car/actionCreator';
import { IMAGE_URL } from '../../../utils/constants';

const CarImagesForm = props => {
  const { values, setFieldValue, cancelModal, formData } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const deleteImageLoading = useSelector(state => state.car.loading);

  const [deleteFile, setDeleteFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onCancel = () => {
    setShowDeleteModal(false);
    setDeleteFile(null);
    setFieldValue('images', formData?.images);
  };

  const handleDeleteClick = file => {
    // eslint-disable-next-line no-underscore-dangle
    if (values?._id) {
      if (file?.originFileObj) return;
      setShowDeleteModal(true);
      setDeleteFile(file);
    }
  };

  const handleDeleteConfirmation = async () => {
    const res = await dispatch(deleteCarFile(params?.id, deleteFile?.name, 'image'));
    if (res?.data && res?.data?.status) {
      const remainingImages = JSON.parse(res?.data?.car?.images);
      if (Array.isArray(remainingImages)) {
        const remainingImagesInFile = remainingImages.map((item, index) => {
          return {
            uid: index + 1,
            name: `${item}`,
            status: 'done',
            url: `${IMAGE_URL}/${item}`,
          };
        });
        setFieldValue('images', remainingImagesInFile);
        setShowDeleteModal(false);
      }
    }
    setDeleteFile(null);
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Car Image"
        visible={showDeleteModal}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={deleteImageLoading}
        itemName={deleteFile?.name}
      />
      {/* images */}
      <div className="add-product-block">
        <Row>
          <Col xs={24}>
            <FormUpload
              name="images"
              label="Car Images"
              value={values.images}
              handleChange={info => {
                const fileList = [...info.fileList];
                setFieldValue('images', fileList.length > 0 ? fileList : null);
              }}
              accept="image/*"
              isMulti
              isRequired={false}
              onRemove={handleDeleteClick}
            />
          </Col>
        </Row>

        <DivBreak />
        <Divider />
        <Row justify="center" align="middle">
          <Button
            type="default"
            style={{ backgroundColor: '#155BDC', color: '#fff', height: '40px' }}
            onClick={e => {
              e.stopPropagation();
              cancelModal();
            }}
          >
            Save Car Images
          </Button>
        </Row>
      </div>
    </>
  );
};

CarImagesForm.propTypes = {
  values: propTypes.object,
  setFieldValue: propTypes.func,
  cancelModal: propTypes.func,
  formData: propTypes.object,
};

export default CarImagesForm;
