// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Pluralize from 'pluralize';
import FeatherIcon from 'feather-icons-react';

// USER COMPONENTS
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import BlogTable from './overview/BlogTable';
import { Button } from '../../components/buttons/buttons';
import { deleteBlog, getAllBlogs } from '../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../redux/blogcategory/actionCreator';
import { getAllUsers } from '../../redux/users/actionCreator';

const Blog = () => {
  const dispatch = useDispatch();
  const { total, blogs, categories, role, currentPage, pageLimit, searchKeyword, path, loading } = useSelector(
    state => {
      return {
        total: state.blog.total,
        blogs: state.blog.blogs,
        categories: state.blogCategory.categories,
        role: state.auth.user.role.name,
        currentPage: state.blog.currentPage,
        pageLimit: state.blog.pageLimit,
        loading: state.blog.loading,
        searchKeyword: state.headerSearch.searchKeyword,
        path: state.headerSearch.path,
      };
    },
  );
  const [state, setState] = useState({ currentPage: 1, pageLimit: 50, searchKeyword });

  useEffect(() => {
    dispatch(getAllUsers('?all=true'));
    if (categories.length === 0) getAllBlogCategories(dispatch);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchKeyword && path === 'blogs')
      dispatch(getAllBlogs(`?search=${searchKeyword}&page=${state.currentPage || 1}&limit=${state.pageLimit || 50}`));
    else dispatch(getAllBlogs(`?page=${state.currentPage || 1}&limit=${state.pageLimit || 50}`));

    // eslint-disable-next-line
  }, [searchKeyword, state.currentPage, state.pageLimit]);

  useEffect(() => {
    if (state.searchKeyword !== searchKeyword) {
      if (path === 'blogs') setState({ currentPage: 1, pageLimit: 50, searchKeyword });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  // Handling delete blog
  const handleDelete = id => {
    dispatch(deleteBlog(id));
  };

  return (
    <>
      <PageHeader
        ghost
        title="Blogs"
        subTitle={
          total > 0 && (
            <span className="title-counter">
              {total} {Pluralize('Blog', total)}{' '}
            </span>
          )
        }
        buttons={[
          <Link to={`../${role}/blogs/add`}>
            <Button key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
              <FeatherIcon icon="plus" size={16} /> Add New
            </Button>
          </Link>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            <BlogTable
              totalBlog={total}
              blogs={blogs}
              blogState={setState}
              handleDelete={handleDelete}
              loading={loading}
              currentPage={currentPage}
              pageLimit={pageLimit}
            />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Blog;
