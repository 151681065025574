import React from 'react';
import { PropTypes } from 'prop-types';
import { Input, Radio } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import './input.style.css';

export const INPUT_TYPE = {
  text: 'text',
  number: 'number',
  editor: 'editor',
  textarea: 'textarea',
  radio: 'radio',
  search: 'search',
  date: 'date',
  file: 'file',
  password: 'password',
};

const FormInput = props => {
  const {
    label,
    type = INPUT_TYPE.text,
    name,
    isDisabled = false,
    isRequired = true,
    id,
    value,
    style,
    errorMsg,
    helperText,
    className,
    handleChange,
    ...rest
  } = props;

  function renderField() {
    switch (type) {
      case INPUT_TYPE.editor:
        return (
          <Editor
            name={name}
            value={value}
            editorState={rest.editorState}
            onEditorStateChange={rest.handleEditorStateChange}
            placeholder={rest.placeholder}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        );

      case INPUT_TYPE.textarea:
        return (
          <Input.TextArea
            name={name}
            type={type}
            value={value}
            onChange={handleChange}
            rows={rest?.rows ?? 2}
            placeholder={rest.placeholder || `Please enter ${label?.toLowerCase()}`}
            disabled={isDisabled}
          />
        );

      case INPUT_TYPE.search:
        return (
          <Input.Search
            name={name}
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={rest.placeholder || `Please enter ${label?.toLowerCase()}`}
            disabled={isDisabled}
            enterButton
            onSearch={rest?.handleSearch}
          />
        );

      case INPUT_TYPE.radio:
        return (
          <Radio.Group
            name={name}
            value={value}
            disabled={isDisabled}
            onChange={handleChange}
            defaultValue={rest.defaultValue}
          >
            {rest.radioOptions &&
              rest.radioOptions.map(radioOpt => <Radio value={radioOpt?.value}>{radioOpt?.label}</Radio>)}
          </Radio.Group>
        );

      case INPUT_TYPE.date:
        return (
          <Input
            name={name}
            type="date"
            value={value}
            onChange={handleChange}
            placeholder={rest.placeholder || `Please enter ${label?.toLowerCase()}`}
            disabled={isDisabled}
          />
        );

      case INPUT_TYPE.password:
        return (
          <Input.Password
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={rest.placeholder || `Please enter ${label?.toLowerCase()}`}
            disabled={isDisabled}
            {...rest}
          />
        );

      default:
        return (
          <Input
            name={name}
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={rest.placeholder || `Please enter ${label?.toLowerCase()}`}
            disabled={isDisabled}
            suffix={rest.suffix ?? null}
            {...rest}
          />
        );
    }
  }

  return (
    <div className={isRequired ? 'input-required' : 'form-input'}>
      <div className="input-label">
        <label htmlFor={id ?? name}>{label}</label>
      </div>
      <div className={`${rest.errors?.[`${name}`] ? 'fieldError' : ''}`}>{rest.children ?? renderField()}</div>
      {rest?.errors && rest?.touched && rest.touched?.[`${name}`] && rest.errors?.[`${name}`] && (
        <div className="input-required-msg">
          <span>{rest.errors?.[`${name}`]}</span>
        </div>
      )}
    </div>
  );
};

export default FormInput;

FormInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  style: PropTypes.string,
  errorMsg: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
};
