const actions = {
  SEARCH_HEADER_SUCCESS: 'SEARCH_HEADER_SUCCESS',

  searchHeaderSuccess: data => {
    return {
      type: actions.SEARCH_HEADER_SUCCESS,
      data,
    };
  },

};

export default actions;
