import actions from './actions';

const {
  GET_BODY_TYPE_BEGIN, GET_BODY_TYPE_SUCCESS, GET_BODY_TYPE_FAILED,
  ADD_BODY_TYPE_BEGIN, ADD_BODY_TYPE_SUCCESS, ADD_BODY_TYPE_FAILED,
  UPDATE_BODY_TYPE_BEGIN, UPDATE_BODY_TYPE_SUCCESS, UPDATE_BODY_TYPE_FAILED,
  DELETE_BODY_TYPE_BEGIN, DELETE_BODY_TYPE_SUCCESS, DELETE_BODY_TYPE_FAILED
} = actions;

const initState = {
  bodyTypes: [],
  loading: false,
  error: null,
};


const CarBodyTypeReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_BODY_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BODY_TYPE_SUCCESS:
      return {
        ...state,
        bodyTypes: data,
        loading: false,
      };
    case GET_BODY_TYPE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_BODY_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_BODY_TYPE_SUCCESS:
      return {
        ...state,
        bodyTypes: data,
        loading: false,
      };
    case ADD_BODY_TYPE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_BODY_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BODY_TYPE_SUCCESS:
      return {
        ...state,
        bodyTypes: data,
        loading: false,
      };
    case UPDATE_BODY_TYPE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BODY_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BODY_TYPE_SUCCESS:
      return {
        ...state,
        bodyTypes: data,
        loading: false,
      };
    case DELETE_BODY_TYPE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CarBodyTypeReducer;