const actions = {
  GET_CUSTOMER_QUERY_BEGIN: 'GET_CUSTOMER_QUERY_BEGIN',
  GET_CUSTOMER_QUERY_SUCCESS: 'GET_CUSTOMER_QUERY_SUCCESS',
  GET_CUSTOMER_QUERY_FAILED: 'GET_CUSTOMER_QUERY_FAILED',

  ADD_CUSTOMER_QUERY_BEGIN: 'ADD_CUSTOMER_QUERY_BEGIN',
  ADD_CUSTOMER_QUERY_SUCCESS: 'ADD_CUSTOMER_QUERY_SUCCESS',
  ADD_CUSTOMER_QUERY_FAILED: 'ADD_CUSTOMER_QUERY_FAILED',

  UPDATE_CUSTOMER_QUERY_BEGIN: 'UPDATE_CUSTOMER_QUERY_BEGIN',
  UPDATE_CUSTOMER_QUERY_SUCCESS: 'UPDATE_CUSTOMER_QUERY_SUCCESS',
  UPDATE_CUSTOMER_QUERY_FAILED: 'UPDATE_CUSTOMER_QUERY_FAILED',

  DELETE_CUSTOMER_QUERY_BEGIN: 'DELETE_CUSTOMER_QUERY_BEGIN',
  DELETE_CUSTOMER_QUERY_SUCCESS: 'DELETE_CUSTOMER_QUERY_SUCCESS',
  DELETE_CUSTOMER_QUERY_FAILED: 'DELETE_CUSTOMER_QUERY_FAILED',

  getCustomerQueryBegin: () => {
    return {
      type: actions.GET_CUSTOMER_QUERY_BEGIN,
    };
  },

  getCustomerQuerySuccess: data => {
    return {
      type: actions.GET_CUSTOMER_QUERY_SUCCESS,
      data,
    };
  },

  getCustomerQueryFailed: err => {
    return {
      type: actions.GET_CUSTOMER_QUERY_FAILED,
      err,
    };
  },

  addCustomerQueryBegin: () => {
    return {
      type: actions.ADD_CUSTOMER_QUERY_BEGIN,
    };
  },

  addCustomerQuerySuccess: data => {
    return {
      type: actions.ADD_CUSTOMER_QUERY_SUCCESS,
      data,
    };
  },

  addCustomerQueryFailed: err => {
    return {
      type: actions.ADD_CUSTOMER_QUERY_FAILED,
      err,
    };
  },

  updateCustomerQueryBegin: () => {
    return {
      type: actions.UPDATE_CUSTOMER_QUERY_BEGIN,
    };
  },

  updateCustomerQuerySuccess: data => {
    return {
      type: actions.UPDATE_CUSTOMER_QUERY_SUCCESS,
      data,
    };
  },

  updateCustomerQueryFailed: err => {
    return {
      type: actions.UPDATE_CUSTOMER_QUERY_FAILED,
      err,
    };
  },

  deleteCustomerQueryBegin: () => {
    return {
      type: actions.DELETE_CUSTOMER_QUERY_BEGIN,
    };
  },

  deleteCustomerQuerySuccess: data => {
    return {
      type: actions.DELETE_CUSTOMER_QUERY_SUCCESS,
      data,
    };
  },

  deleteCustomerQueryFailed: err => {
    return {
      type: actions.DELETE_CUSTOMER_QUERY_FAILED,
      err,
    };
  },
};

export default actions;