import React, { useState } from 'react';
import { Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import EditBlogCategory from '../../blogcategory/overview/EditBlogCategory';
import DeleteBlogCategory from '../../blogcategory/overview/DeleteBlogCategory';
import Cookies from 'js-cookie';

const CarBodyType = ({ blogCategories, handleDelete, handleEdit, categoryState, currentPage, pageLimit }) => {
  const dataIndex = (Cookies.get('lang') == 'fr' ? 0 : 1);

  const [state, setState] = useState({
    editModalVisible: false,
    deleteModelVisible: false,
  })

  const [blogCategory, setBlogCategory] = useState({});

  const showModal = (id, type) => {
    setBlogCategory(blogCategories.find(bc => bc._id === id));
    if (type === 'edit') setState({ ...state, editModalVisible: true });
    else setState({ ...state, deleteModelVisible: true });
  };

  const onCancel = () => {
    setBlogCategory({});
    setState({ ...state, editModalVisible: false, deleteModelVisible: false, });
  };

  const newBlogCategories = [];

  blogCategories.map((blogC, index) => {
    const { _id, details } = blogC;

    return newBlogCategories.push({
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      category: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {details[dataIndex]?.category ?? 'NA'}
          </Heading>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Button className="btn-icon" type="info" to="#" shape="circle" onClick={() => showModal(_id, 'edit')}>
            <FeatherIcon icon="edit" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(_id, 'delete')}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    });
  });

  const usersTableColumns = [
    {
      title: 'S.No',
      dataIndex: 'sNo',
      key: 'sNo',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];


  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={newBlogCategories}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: pageLimit,
              total: newBlogCategories?.length,
              current: currentPage,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={(data) => categoryState(prev => ({ ...prev, currentPage: data.current, pageLimit: data.pageSize }))}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      {
        Object.keys(blogCategory).length > 0 && (
          <>
            <EditBlogCategory onCancel={onCancel} visible={state.editModalVisible} blogCategory={blogCategory} handleEdit={handleEdit} />
            <DeleteBlogCategory onCancel={onCancel} visible={state.deleteModelVisible} blogCategory={blogCategory} handleDelete={handleDelete} />
          </>
        )
      }
    </Cards>
  );
};

export default CarBodyType;