// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import propTypes from 'prop-types';

// USER'S COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

const DeleteCarIssue = ({ visible, onCancel, issue, handleDeleteIssue }) => {

  // To handle the delete 
  const handleSubmit = () => {
    handleDeleteIssue(issue.file)
    onCancel();
  }

  return issue ? (
    <Modal
      type={'primary'}
      title="Delete Car Issue"
      visible={visible}
      footer={[
        <div key="1" className="car-modal-footer">
          <Button size="default" style={{ backgroundColor: '#155BDC', color: '#fff' }} key="submit" onClick={handleSubmit}>
            Delete Car Issue
          </Button>
          <Button size="default" type="white" key="back" outlined onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
    >
      <div className="car-modal">
        <BasicFormWrapper>
          <p>Delete car issue of caption "{issue.caption}"</p>
        </BasicFormWrapper>
      </div>
    </Modal>
  ) : null;
};

DeleteCarIssue.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default DeleteCarIssue;