import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getStoreBegin,
  getStoreSuccess,
  getStoreFailed,
  addStoreBegin,
  addStoreSuccess,
  addStoreFailed,
  updateStoreBegin,
  updateStoreSuccess,
  updateStoreFailed,
  deleteStoreBegin,
  deleteStoreSuccess,
  deleteStoreFailed,
} = actions;

// GETTING ALL STORES
export const getAllStores = () => async dispatch => {
  try {
    dispatch(getStoreBegin());
    const res = await axios.get('stores', { headers: { ...getHeaders() } });
    dispatch(getStoreSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(getStoreFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

// ADDING NEW STORE
export const addNewStore = (data, form) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addStoreBegin());
    const res = await axios.post('stores', data, { headers: { ...getHeaders() } });
    dispatch(addStoreSuccess([res.data.store, ...state.store.stores]));
    message.success('New store created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addStoreFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
};

// UPDATING STORE
export const editStore = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateStoreBegin());
    const res = await axios.put(`stores/${id}`, datas, {
      headers: { ...getHeaders() },
    });
    const newStores = state.store.stores.map(store => {
      if (store._id === id) return res.data.store;
      else return store;
    });
    dispatch(updateStoreSuccess(newStores));
    message.success('Store updated successfully');
    return res;
  } catch (err) {
    dispatch(updateStoreFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE STORE
export const deleteStore = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteStoreBegin());
    const res = await axios.delete(`stores/${id}`, {
      headers: { ...getHeaders() },
    });

    dispatch(deleteStoreSuccess(state.store.stores.filter(store => store._id !== id)));
    message.success('Store deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteStoreFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
