const actions = {
  GET_SELL_CAR_BEGIN: 'GET_SELL_CAR_BEGIN',
  GET_SELL_CAR_SUCCESS: 'GET_SELL_CAR_SUCCESS',
  GET_SELL_CAR_FAILED: 'GET_SELL_CAR_FAILED',

  ADD_SELL_CAR_BEGIN: 'ADD_SELL_CAR_BEGIN',
  ADD_SELL_CAR_SUCCESS: 'ADD_SELL_CAR_SUCCESS',
  ADD_SELL_CAR_FAILED: 'ADD_SELL_CAR_FAILED',

  UPDATE_SELL_CAR_BEGIN: 'UPDATE_SELL_CAR_BEGIN',
  UPDATE_SELL_CAR_SUCCESS: 'UPDATE_SELL_CAR_SUCCESS',
  UPDATE_SELL_CAR_FAILED: 'UPDATE_SELL_CAR_FAILED',

  DELETE_SELL_CAR_BEGIN: 'DELETE_SELL_CAR_BEGIN',
  DELETE_SELL_CAR_SUCCESS: 'DELETE_SELL_CAR_SUCCESS',
  DELETE_SELL_CAR_FAILED: 'DELETE_SELL_CAR_FAILED',

  getSellingCarBegin: () => {
    return {
      type: actions.GET_SELL_CAR_BEGIN,
    };
  },

  getSellingCarSuccess: data => {
    return {
      type: actions.GET_SELL_CAR_SUCCESS,
      data,
    };
  },

  getSellingCarFailed: err => {
    return {
      type: actions.GET_SELL_CAR_FAILED,
      err,
    };
  },

  addSellingCarBegin: () => {
    return {
      type: actions.ADD_SELL_CAR_BEGIN,
    };
  },

  addSellingCarSuccess: data => {
    return {
      type: actions.ADD_SELL_CAR_SUCCESS,
      data,
    };
  },

  addSellingCarFailed: err => {
    return {
      type: actions.ADD_SELL_CAR_FAILED,
      err,
    };
  },

  updateSellingCarBegin: () => {
    return {
      type: actions.UPDATE_SELL_CAR_BEGIN,
    };
  },

  updateSellingCarSuccess: data => {
    return {
      type: actions.UPDATE_SELL_CAR_SUCCESS,
      data,
    };
  },

  updateSellingCarFailed: err => {
    return {
      type: actions.UPDATE_SELL_CAR_FAILED,
      err,
    };
  },

  deleteSellingCarBegin: () => {
    return {
      type: actions.DELETE_SELL_CAR_BEGIN,
    };
  },

  deleteSellingCarSuccess: data => {
    return {
      type: actions.DELETE_SELL_CAR_SUCCESS,
      data,
    };
  },

  deleteSellingCarFailed: err => {
    return {
      type: actions.DELETE_SELL_CAR_FAILED,
      err,
    };
  },
};

export default actions;