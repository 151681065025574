/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import { getHeaders } from '../../utils/common';

const { getContactQueryBegin, getContactQuerySuccess, getContactQueryFailed } = actions;

// FETCHING ALL CONTACT QUERIES
let cancelToken;
export const getAllContactQueries = filter => async (dispatch) => {
    if (cancelToken) cancelToken.cancel();
    cancelToken = CancelToken.source();
    try {
        dispatch(getContactQuerySuccess({ contactQueries: [] }));
        dispatch(getContactQueryBegin());
        const res = await axios.get(`contact-queries${filter ?? ''}`, {
            headers: { ...getHeaders() },
            cancelToken: cancelToken.token
        });
        dispatch(getContactQuerySuccess(res.data));
    }
    catch (err) {
        dispatch(getContactQueryFailed(err?.response?.data?.msg))
    }
}