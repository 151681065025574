// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import propTypes from 'prop-types';

// USER COMPONENTS
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

const DeleteBlogCategory = ({ visible, onCancel, blogCategory, handleDelete }) => {
  // To handle the delete
  const handleSubmit = () => {
    handleDelete(blogCategory._id)
    onCancel();
  }

  return (
    <Modal
      type={'primary'}
      title="Delete Blog Category"
      visible={visible}
      footer={[
        <div key="1" className="category-modal-footer">
          <Button size="default" style={{ backgroundColor: '#155BDC', color: '#fff' }} key="submit" onClick={handleSubmit}>
            Delete Blog Category
          </Button>
          <Button size="default" type="white" key="back" outlined onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
    >
      <div className="category-modal">
        <BasicFormWrapper>
          <p>Delete blog category "{blogCategory.details[0].category}"</p>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
};

DeleteBlogCategory.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default DeleteBlogCategory;