import axios from 'axios';
import { SERVER_URL } from '../utils/constants';

const instance = axios.create({
  baseURL: SERVER_URL,
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json';
const CancelToken = axios.CancelToken;

export default instance;
export { CancelToken };
