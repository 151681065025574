import actions from './actions';

const {
  GET_CONTACT_INFORMATION_BEGIN, GET_CONTACT_INFORMATION_SUCCESS, GET_CONTACT_INFORMATION_FAILED,
  ADD_CONTACT_INFORMATION_BEGIN, ADD_CONTACT_INFORMATION_SUCCESS, ADD_CONTACT_INFORMATION_FAILED,
  UPDATE_CONTACT_INFORMATION_BEGIN, UPDATE_CONTACT_INFORMATION_SUCCESS, UPDATE_CONTACT_INFORMATION_FAILED,
  DELETE_CONTACT_INFORMATION_BEGIN, DELETE_CONTACT_INFORMATION_SUCCESS, DELETE_CONTACT_INFORMATION_FAILED
} = actions;

const initState = {
  information: {},
  loading: false,
  error: null,
};


const BlogReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CONTACT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        information: data,
        loading: false,
      };
    case GET_CONTACT_INFORMATION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CONTACT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        information: data,
        loading: false,
      };
    case ADD_CONTACT_INFORMATION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CONTACT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        information: data,
        loading: false,
      };
    case UPDATE_CONTACT_INFORMATION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CONTACT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        information: data,
        loading: false,
      };
    case DELETE_CONTACT_INFORMATION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default BlogReducer;