// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Button, Input, Spin } from 'antd';
import Cookies from 'js-cookie';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';
import axios from '../../../redux/axios';
import FeatherIcon from 'feather-icons-react';
import { getHeaders } from '../../../utils/common';

const BulkUpload = ({ visible, onCancel }) => {
  const [file, setFile] = useState(null);
  const [uploadLoad, setUploadLoad] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [uploadRes, setUploadRes] = useState({ isUploaded: false, msg: '', errors: [] });

  const handleSubmit = async () => {
    setUploadLoad(true);
    try {
      const formData = new FormData();
      formData.append('csv', file);
      const res = await axios.post('cars/bulkCarData', formData, {
        headers: { ...getHeaders() },
      });
      setUploadRes({ isUploaded: true, msg: res.data.msg, errors: res.data.errors });
      setUploadLoad(false);
    } catch (err) {
      if (err.response.status < 500) {
        setUploadRes({ isUploaded: true, msg: err.response.data.msg, errors: err.response.data.errors });
      } else {
        setUploadRes({ isUploaded: true, msg: 'Something is wrong. please check your file.', errors: [] });
      }
      setUploadLoad(false);
    }
  };

  const handleFileChange = e => {
    const vFile = e.target.files[0];

    if (vFile) {
      // Check if the file type is CSV
      if (vFile.type === 'text/csv') {
        setFile(vFile);
      } else {
        // Display an error message for invalid file type
        setFileError('You can only upload CSV files!');
      }
    }
  };

  const handleCancel = () => {
    setUploadRes({ isUploaded: false, msg: '', errors: [] });
    setFileError(null);
    setFile(null);
    onCancel();
  };

  return (
    <Modal
      type="primary"
      title="Cars Bulk Upload"
      visible={visible}
      footer={[
        <div key="1" className="blog-modal-footer">
          <Button
            disabled={!!fileError || !file}
            size="default"
            style={{ backgroundColor: '#155BDC', color: '#fff' }}
            key="submit"
            onClick={uploadRes.isUploaded ? handleCancel : handleSubmit}
          >
            {uploadRes.isUploaded ? 'Ok' : 'Submit'}
          </Button>
          <Button size="default" type="white" key="back" outlined onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
    >
      <div className="blog-modal">
        {uploadLoad ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="44" />
          </div>
        ) : uploadRes.isUploaded ? (
          <>
            <div>
              <h3>{uploadRes.msg}</h3>
              <ul>
                {uploadRes.errors.map((error, index) => (
                  <li key={index}>
                    Row {error.row}: {error.error || error.message}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <BasicFormWrapper>
            <Input
              type="file"
              required
              name="images"
              onChange={handleFileChange}
              accept=".csv"
              style={{ cursor: 'pointer' }}
            />
            <p style={{ color: 'red' }}>{fileError}</p>
            <div style={{ marginTop: '1rem' }}>
              <a
                href={`${process.env.PUBLIC_URL}/bulkupload_sample.csv`}
                style={{ display: 'flex', alignItems: 'center' }}
                download="bulkupload_sample.csv"
              >
                <FeatherIcon icon="download-cloud" size={14} style={{ marginRight: '4px' }} />
                <span style={{ lineHeight: '142%' }}> Download the sample file for bulk upload.</span>
              </a>
            </div>
          </BasicFormWrapper>
        )}
      </div>
    </Modal>
  );
};

BulkUpload.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default BulkUpload;
