import actions from './actions';

const {
  GET_BLOG_BEGIN, GET_BLOG_SUCCESS, GET_BLOG_FAILED,
  ADD_BLOG_BEGIN, ADD_BLOG_SUCCESS, ADD_BLOG_FAILED,
  UPDATE_BLOG_BEGIN, UPDATE_BLOG_SUCCESS, UPDATE_BLOG_FAILED,
  DELETE_BLOG_BEGIN, DELETE_BLOG_SUCCESS, DELETE_BLOG_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  blogs: [],
  loading: false,
  error: null,
};


const BlogReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_BLOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BLOG_SUCCESS:
      return {
        ...state,
        total: data.totalBlogs,
        blogs: data.blogs,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_BLOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_BLOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        blogs: data,
        loading: false,
      };
    case ADD_BLOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_BLOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: data,
        loading: false,
      };
    case UPDATE_BLOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BLOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        blogs: data,
        loading: false,
      };
    case DELETE_BLOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default BlogReducer;