/* eslint-disable no-underscore-dangle */
import { Col, Row, Spin, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import Pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import BlogCategoryForm from './overview/BlogCategoryForm';
import { blogCategoryInitialFormData, blogCategoryTableColumns } from './overview/blogCategory.schema';
import { Main, TableWrapper } from '../styled';
import { UserTableStyleWrapper } from '../pages/style';
import { Button } from '../../components/buttons/buttons';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal';
import { Modal } from '../../components/modals/antd-modals';
import { PageHeader } from '../../components/page-headers/page-headers';
import { deleteBlogCategory, getAllBlogCategories } from '../../redux/blogcategory/actionCreator';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../utils/constants';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';

const BlogCategoryMain = () => {
  const dispatch = useDispatch();
  const { blogCategories, blogCategoryloading } = useSelector(state => {
    return {
      blogCategoryloading: state.blogCategory.loading,
      blogCategories: state.blogCategory.categories,
    };
  });
  const currentLang = Cookies.get('lang');

  const [formData, setFormData] = useState({ ...blogCategoryInitialFormData });
  const [showModal, setShowModal] = useState({
    status: false,
    modalType: 'form', // modalType can be either 'form' or 'delete'
  });
  const [deleteItem, setDeleteItem] = useState(null);
  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 50 });
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    getAllBlogCategories(dispatch);
  }, []);

  useEffect(() => {
    const faqWithLang = blogCategories?.find(faq => faq.details.length > 1);
    if (faqWithLang) {
      const indexOfData = faqWithLang?.details?.findIndex(faq => faq?.lang === currentLang);
      // eslint-disable-next-line no-unused-expressions
      indexOfData > -1 ? setDataIndex(indexOfData) : setDataIndex(0);
    }
  }, [currentLang, blogCategories]);

  // For toggling model
  const handleToggleModal = modalType => {
    setShowModal({
      status: !showModal.status,
      modalType,
    });
  };

  // For handling the close model
  const onCancel = () => {
    setShowModal({
      status: false,
      modalType: 'form',
    });
    setDeleteItem(null);
    setFormData({ ...blogCategoryInitialFormData });
  };

  const handleEditClick = responseData => {
    const apiResData = {
      _id: responseData?._id,
      isMultiLang: responseData?.details && responseData?.details.length > 1 ? 'true' : 'false',
    };

    let categoryFields = {};
    if (responseData?.details.length > 1) {
      // eslint-disable-next-line no-unused-vars
      LANGUAGE_BY_DOMAIN.forEach((langItem, index) => {
        categoryFields = {
          ...categoryFields,
          [`category${langItem[1]}`]: responseData?.details[index]?.category ?? '',
        };
      });
    } else {
      categoryFields = {
        ...categoryFields,
        [`category${DEFAULT_LANG.value}`]: responseData?.details[0]?.category ?? '',
      };
    }

    setFormData({
      ...formData,
      ...apiResData,
      ...categoryFields,
    });

    setShowModal({ status: true, modalType: 'form' });
  };

  const handleDeleteClick = responseData => {
    setDeleteItem({ id: responseData?._id, name: responseData?.customerName });
    setShowModal({ status: true, modalType: 'delete' });
  };

  // To handle confirm delete
  const handleDeleteConfirmation = async () => {
    try {
      const res = await dispatch(deleteBlogCategory(deleteItem && deleteItem?.id));
      if (res?.data?.status) {
        onCancel();
        getAllBlogCategories(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setDeleteItem(null);
    }
  };

  const blogCategoriesTableData = blogCategories.map((blogC, index) => {
    const { _id, details } = blogC;

    return {
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      category: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {details[dataIndex]?.category ?? 'NA'}
          </Heading>
        </div>
      ),
      action: (
        <div className="table-actions">
          <Button className="btn-icon" type="info" to="#" shape="circle" onClick={() => handleEditClick(blogC)}>
            <FeatherIcon icon="edit" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => handleDeleteClick(blogC)}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    };
  });

  return (
    <>
      <DeleteConfirmationModal
        title="Blog Category"
        visible={showModal.modalType === 'delete' && showModal.status}
        onCancel={onCancel}
        handleDeleteConfirmation={handleDeleteConfirmation}
        deleteButtonLoading={blogCategoryloading}
        itemName={deleteItem?.name ?? ''}
      />
      <Modal
        type="primary"
        title={formData?._id ? 'Edit Blog Category' : 'Create Blog Category'}
        visible={showModal.modalType === 'form' && showModal.status}
        width={800}
        onCancel={onCancel}
        footer={null}
      >
        <div className="body-type-modal">
          <BlogCategoryForm formData={formData} onCancel={onCancel} />
        </div>
      </Modal>

      <PageHeader
        ghost
        title="Blog Category"
        subTitle={
          blogCategories.length > 0 && (
            <span className="title-counter">
              {blogCategories.length} {Pluralize('Category', blogCategories.length)}{' '}
            </span>
          )
        }
        buttons={[
          <Button
            onClick={() => handleToggleModal('form')}
            key="1"
            style={{ backgroundColor: '#155BDC', color: '#fff' }}
            size="default"
          >
            <FeatherIcon icon="plus" size={16} /> Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {blogCategoryloading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <>
                <Cards headless>
                  <UserTableStyleWrapper>
                    <TableWrapper className="table-responsive">
                      <Table
                        dataSource={blogCategoriesTableData}
                        columns={blogCategoryTableColumns}
                        pagination={{
                          defaultPageSize: pagination.pageLimit,
                          total: blogCategoriesTableData?.length,
                          current: pagination.currentPage,
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        onChange={data =>
                          setPagination(prev => ({ ...prev, currentPage: data.current, pageLimit: data.pageSize }))
                        }
                      />
                    </TableWrapper>
                  </UserTableStyleWrapper>
                </Cards>
              </>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default BlogCategoryMain;
