import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getBlogBegin, getBlogSuccess, getBlogFailed,
  addBlogBegin, addBlogSuccess, addBlogFailed,
  updateBlogBegin, updateBlogSuccess, updateBlogFailed,
  deleteBlogBegin, deleteBlogSuccess, deleteBlogFailed,
} = actions;

// GETTING ALL BLOGS
let cancelToken;
export const getAllBlogs = (filter) => async (dispatch) => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getBlogSuccess({ totalBlogs: 0, blogs: [] }));
    dispatch(getBlogBegin());
    const res = await axios.get(`blogs${filter ? filter : ''}`, { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    dispatch(getBlogSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(getBlogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
}

// GETTING SINGLE BLOG
export const getSingleBlog = id => async (dispatch) => {
  try {
    dispatch(getBlogBegin());
    const res = await axios.get(`blogs/${id}`, { headers: { ...getHeaders() } });
    dispatch(getBlogSuccess({ blogs: [res.data.blog], total: 1 }));
    return res;
  } catch (err) {
    dispatch(getBlogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// ADDING NEW BLOG
export const addNewBlog = (data, history) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addBlogBegin());
    const res = await axios.post('blogs', data, { headers: { ...getHeaders() } });

    dispatch(addBlogSuccess([res.data.blog, ...state.blog.blogs]));
    message.success('New blog created successfully');
    history.push(`/${state.auth.user.role.name}/blogs`);
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addBlogFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING BLOG
export const editBlog = (id, datas, history) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateBlogBegin());
    const res = await axios.put(`blogs/${id}`, datas, { headers: { ...getHeaders() } });
    const newBlogs = state.blog.blogs.map(blog => {
      if (blog._id === id) return res.data.blog;
      else return blog;
    })
    dispatch(updateBlogSuccess(newBlogs));
    message.success('Blog updated successfully');
    history.push(`/${state.auth.user.role.name}/blogs`);
    return res;
  } catch (err) {
    dispatch(updateBlogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err))
    return err;
  }
}

// DELETE BLOG
export const deleteBlog = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteBlogBegin());
    const res = await axios.delete(`blogs/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteBlogSuccess(state.blog.blogs.filter(blog => blog._id !== id)));
    message.success('Blog deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteBlogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
