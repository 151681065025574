// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState } from 'react';
import { Table } from 'antd';
import FeatherIcon from 'feather-icons-react';

// USER'S COMPONENT
import { UserTableStyleWrapper } from '../../pages/style';
import { TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import EditServicingHistory from './EditServicingHistory';
import DeleteServicingHistory from './DeleteServicingHistory';

const ServicingHistoryTable = ({ histories, handleDelete, handleEdit }) => {
  const [state, setState] = useState({ editModalVisible: false, deleteModelVisible: false });
  const [history, setHistory] = useState({});

  // To display the modal
  const showModal = (id, type) => {
    setHistory(histories.find(bc => bc._id === id));
    if (type === 'edit') setState({ ...state, editModalVisible: true });
    else setState({ ...state, deleteModelVisible: true });
  };

  // To hide the modal
  const onCancel = () => {
    setHistory({});
    setState({ ...state, editModalVisible: false, deleteModelVisible: false, });
  };

  const newServicingHistories = [];

  histories?.map((history, index) => {
    const { _id, serviceCenter, date, drivenKM } = history;

    return newServicingHistories.push({
      key: _id,
      sNo: (
        <p>{index + 1}</p>
      ),
      name: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {serviceCenter}
          </Heading>
        </div>
      ),
      date: (<p>{new Date(date).toDateString()}</p>),
      drivenKM: (<p>{drivenKM}</p>),
      action: (
        <div className="table-actions">
          <Button className="btn-icon" type="info" to="#" shape="circle" onClick={() => showModal(_id, 'edit')}>
            <FeatherIcon icon="edit" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(_id, 'delete')}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    });
  });

  const fPTableColumns = [
    {
      title: 'S.No',
      dataIndex: 'sNo',
      key: 'sNo',
    },
    {
      title: 'Service Center',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Driven KM',
      dataIndex: 'drivenKM',
      key: 'drivenKM',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];


  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={newServicingHistories}
            columns={fPTableColumns}
            pagination={{
              defaultPageSize: 5,
              total: newServicingHistories?.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      <EditServicingHistory onCancel={onCancel} visible={state.editModalVisible} history={history} handleEdit={handleEdit} />
      <DeleteServicingHistory onCancel={onCancel} visible={state.deleteModelVisible} history={history} handleDelete={handleDelete} />
    </Cards>
  );
};

export default ServicingHistoryTable;