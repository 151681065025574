/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Button, Col, Divider, Image, Row, Spin } from 'antd';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { SPECS_LABEL, SPECS_LIST, inspectReportInitialFormData } from './inspectionReport.schema';
import { getAllInspectionReports } from '../../../redux/inspection report/actionCreator';
import { IMAGE_URL } from '../../../utils/constants';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ProductDetailsWrapper } from '../../car/Style';
import Heading from '../../../components/heading/heading';
import DivBreak from '../../../components/divBreak/DivBreak';

const ViewInspectionReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [formData, setFormData] = useState({ ...inspectReportInitialFormData });

  const { inspectionReportsLoading, inspectionReportsData, userType } = useSelector(state => {
    return {
      inspectionReportsData: state.inspectionReport.reports,
      inspectionReportsLoading: state.inspectionReport.loading,
      userType: state.auth.user.role.name,
    };
  });

  useEffect(() => {
    if (inspectionReportsData.length === 0) dispatch(getAllInspectionReports());
  }, []);

  useEffect(() => {
    if (inspectionReportsData && inspectionReportsData.length > 0 && params?.id) {
      const inspectionReportEditDataById = inspectionReportsData.find(item => item?._id === params?.id);
      let radioInspectionReportEditDataById = {};
      let imageInspectionReportEditDataById = {};

      Object.entries(SPECS_LIST).forEach(([specKey]) => {
        let specsOnSpecKey = {};
        if (inspectionReportEditDataById[specKey]) {
          Object.entries(inspectionReportEditDataById[specKey]).forEach(([key, value]) => {
            if (typeof value === 'boolean') {
              specsOnSpecKey = {
                ...specsOnSpecKey,
                [key]: String(value),
              };
              return;
            }
            if (typeof value === 'number' || value) {
              specsOnSpecKey = {
                ...specsOnSpecKey,
                [key]: value ?? '',
              };
            }
          });
        }
        radioInspectionReportEditDataById = {
          ...radioInspectionReportEditDataById,
          ...specsOnSpecKey,
        };
      });

      const getFiles = imageValue => {
        if (Array.isArray(imageValue))
          return imageValue.map((item, index) => {
            return {
              uid: index + 1,
              name: `${item}`,
              status: 'done',
              url: `${IMAGE_URL}/${item}`,
            };
          });
        return [];
      };

      const bodyInspectionSpecs = inspectionReportEditDataById?.bodyInspection;

      Object.entries(bodyInspectionSpecs).forEach(([key, value]) => {
        if (key === 'leftSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            leftImages: getFiles(value?.images),
            leftCaption: value?.caption ?? '',
          };
        } else if (key === 'rightSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            rightImages: getFiles(value?.images),
            rightCaption: value?.caption ?? '',
          };
        } else if (key === 'frontSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            frontImages: getFiles(value?.images),
            frontCaption: value?.caption ?? '',
          };
        } else if (key === 'backSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            backImages: getFiles(value?.images),
            backCaption: value?.caption ?? '',
          };
        }
      });

      if (inspectionReportEditDataById?.interior?.interiorControlsAndSwitches) {
        imageInspectionReportEditDataById = {
          ...imageInspectionReportEditDataById,
          interiorControlsAndSwitches: getFiles(inspectionReportEditDataById?.interior?.interiorControlsAndSwitches),
        };
      }

      const editFormData = {
        ...radioInspectionReportEditDataById,
        ...imageInspectionReportEditDataById,
        car: inspectionReportEditDataById?.car?._id,
        carName: inspectionReportEditDataById?.car?.name,
        date: moment(inspectionReportEditDataById?.date).format('YYYY-MM-DD'),
      };

      ['createdAt', 'bodyInspection', ...Object.keys(SPECS_LIST)].forEach(item => {
        delete editFormData[item];
      });

      setFormData({
        ...formData,
        ...editFormData,
      });
    }
  }, [inspectionReportsData]);

  return (
    <>
      <PageHeader
        ghost
        title={`${formData?.carName} Inspection Report`}
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/inspection-reports`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      {inspectionReportsLoading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <Main>
          <Cards title={`Inspection Date:  ${moment(formData?.date).format('MMM Do YYYY')}`}>
            <ProductDetailsWrapper>
              {Object.entries(SPECS_LIST).map(([specKey, specValue]) => (
                <>
                  <div className="product-details-box__right pdbr">
                    <Heading className="pdbr__title" as="h4">
                      {SPECS_LABEL[specKey]}
                    </Heading>
                    <Row>
                      {specValue.map(item => (
                        <>
                          {item?.value === 'interiorControlsAndSwitches' ? (
                            <>
                              <Col xs={24}>
                                <span className="pdbr__brand-text" style={{ paddingTop: '10px', marginRight: 10 }}>
                                  {item?.label}
                                </span>
                                <div
                                  className="pdbr__brand-name"
                                  style={{ flex: 1, alignItems: 'flex-start', flexDirection: 'row' }}
                                >
                                  {Array.isArray(formData[item?.value]) && formData[item?.value].length > 0
                                    ? formData[item?.value].map(i => {
                                        return (
                                          <div style={{ marginRight: 10, position: 'relative' }}>
                                            <Image src={i?.url} width={80} />
                                          </div>
                                        );
                                      })
                                    : 'No Image'}
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col xs={7}>
                                <p
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '-10px',
                                  }}
                                >
                                  <span className="pdbr__brand-text" style={{ flex: 2, paddingTop: '10px' }}>
                                    {item?.label}
                                  </span>
                                  <span className="pdbr__brand-name" style={{ flex: 1, alignItems: 'flex-start' }}>
                                    {formData[item?.value] === 'true' ? (
                                      <FeatherIcon icon="check-circle" size={18} color="green" />
                                    ) : (
                                      <FeatherIcon icon="x-circle" size={18} color="red" />
                                    )}
                                  </span>
                                </p>
                              </Col>
                              <Col xs={1} />
                            </>
                          )}
                        </>
                      ))}
                    </Row>
                  </div>
                  <DivBreak />
                  <Divider />
                </>
              ))}

              <div className="product-details-box__right pdbr">
                <Heading className="pdbr__title" as="h4">
                  Body Inspection
                </Heading>
                {['Left', 'Right', 'Front', 'Back'].map(item => (
                  <div>
                    <h3>{item} Part</h3>
                    <p style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
                      <span className="pdbr__brand-text" style={{ paddingTop: '10px', marginRight: 10 }}>
                        Caption:{' '}
                      </span>
                      <span className="pdbr__brand-name" style={{ alignItems: 'flex-start' }}>
                        {formData[`${item.toLowerCase()}Caption`] || 'NA'}
                      </span>
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
                      <span className="pdbr__brand-text" style={{ paddingTop: '10px', marginRight: 10 }}>
                        Images:{' '}
                      </span>
                      <div className="pdbr__brand-name" style={{ alignItems: 'flex-start', flexDirection: 'row' }}>
                        {Array.isArray(formData[`${item.toLowerCase()}Images`]) &&
                        formData[`${item.toLowerCase()}Images`].length > 0
                          ? formData[`${item.toLowerCase()}Images`].map(i => {
                              return (
                                <div style={{ marginRight: 10, position: 'relative' }}>
                                  <Image src={i?.url} width={80} />
                                </div>
                              );
                            })
                          : 'No Image'}
                      </div>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
            </ProductDetailsWrapper>
          </Cards>
        </Main>
      )}
    </>
  );
};

export default ViewInspectionReport;
