const actions = {
  GET_FAQ_BEGIN: 'GET_FAQ_BEGIN',
  GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
  GET_FAQ_FAILED: 'GET_FAQ_FAILED',

  ADD_FAQ_BEGIN: 'ADD_FAQ_BEGIN',
  ADD_FAQ_SUCCESS: 'ADD_FAQ_SUCCESS',
  ADD_FAQ_FAILED: 'ADD_FAQ_FAILED',

  UPDATE_FAQ_BEGIN: 'UPDATE_FAQ_BEGIN',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_FAQ_FAILED: 'UPDATE_FAQ_FAILED',

  DELETE_FAQ_BEGIN: 'DELETE_FAQ_BEGIN',
  DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
  DELETE_FAQ_FAILED: 'DELETE_FAQ_FAILED',

  getFaqBegin: () => {
    return {
      type: actions.GET_FAQ_BEGIN,
    };
  },

  getFaqSuccess: data => {
    return {
      type: actions.GET_FAQ_SUCCESS,
      data,
    };
  },

  getFaqFailed: err => {
    return {
      type: actions.GET_FAQ_FAILED,
      err,
    };
  },

  addFaqBegin: () => {
    return {
      type: actions.ADD_FAQ_BEGIN,
    };
  },

  addFaqSuccess: data => {
    return {
      type: actions.ADD_FAQ_SUCCESS,
      data,
    };
  },

  addFaqFailed: err => {
    return {
      type: actions.ADD_FAQ_FAILED,
      err,
    };
  },

  updateFaqBegin: () => {
    return {
      type: actions.UPDATE_FAQ_BEGIN,
    };
  },

  updateFaqSuccess: data => {
    return {
      type: actions.UPDATE_FAQ_SUCCESS,
      data,
    };
  },

  updateFaqFailed: err => {
    return {
      type: actions.UPDATE_FAQ_FAILED,
      err,
    };
  },

  deleteFaqBegin: () => {
    return {
      type: actions.DELETE_FAQ_BEGIN,
    };
  },

  deleteFaqSuccess: data => {
    return {
      type: actions.DELETE_FAQ_SUCCESS,
      data,
    };
  },

  deleteFaqFailed: err => {
    return {
      type: actions.DELETE_FAQ_FAILED,
      err,
    };
  },
};

export default actions;