/* eslint-disable */

import React, { useState } from "react";


const Truncate = ({ ln, text }) => {

    const [isMore, setIsMore] = useState(false);
    const [length, setLength] = useState(
        ln
    );

    const handleMoreContent = () => {
        setIsMore(!isMore);
        setLength(
            isMore ? ln : text.length
        );
    };

    return (
        <>
            {text?.length > (ln) ? (
                <>

                    {isMore ? text : text.substring(0, length) + "..."}
                    <span onClick={handleMoreContent} style={{ cursor: "pointer", color: "#0066ff", fontSize: "12px" }}>
                        {isMore ? ' View Less' : ' View More'}
                    </span>
                </>
            ) : (
                text
            )}
        </>
    );
};

export default Truncate;