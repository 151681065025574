import actions from './actions';
import store from '../store';

const { searchHeaderSuccess } = actions;

const headerSearchAction = (searchKeyword, path, pathLength) => async dispatch => {
  dispatch(searchHeaderSuccess({ searchKeyword, path, pathLength }));
};

const clearHeaderSearch = (path, pathLength) => async dispatch => {
  dispatch(searchHeaderSuccess({ searchKeyword: '', path, pathLength }));
};

export { headerSearchAction, clearHeaderSearch };