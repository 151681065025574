import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getContactInformationBegin,
  getContactInformationSuccess,
  getContactInformationFailed,
  addContactInformationBegin,
  addContactInformationSuccess,
  addContactInformationFailed,
  updateContactInformationBegin,
  updateContactInformationSuccess,
  updateContactInformationFailed,
  deleteContactInformationBegin,
  deleteContactInformationSuccess,
  deleteContactInformationFailed,
} = actions;

// GETTING ALL CONTACT_INFORMATIONS
export const getAllContactInformations = async dispatch => {
  try {
    dispatch(getContactInformationBegin());
    const res = await axios.get(`contact-information`, {
      headers: { ...getHeaders() },
    });
    dispatch(getContactInformationSuccess(res.data.contactInformation));
    return res;
  } catch (err) {
    dispatch(getContactInformationFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

// ADDING NEW CONTACT_INFORMATION
export const addNewContactInformation = data => async dispatch => {
  try {
    dispatch(addContactInformationBegin());
    const res = await axios.post('contact-information', data, {
      headers: { ...getHeaders() },
    });

    dispatch(addContactInformationSuccess(res.data.contactInformation));
    message.success('New Contact information created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addContactInformationFailed(err?.message));
    dispatch(errorHandling(err));
    return err;
  }
};

// UPDATING CONTACT_INFORMATION
export const editContactInformation = (id, datas) => async dispatch => {
  try {
    dispatch(updateContactInformationBegin());
    const res = await axios.put(`contact-information`, datas, {
      headers: { ...getHeaders() },
    });
    dispatch(updateContactInformationSuccess(res.data.contactInformation));
    message.success('Contact information updated successfully');
    return res;
  } catch (err) {
    dispatch(updateContactInformationFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CONTACT_INFORMATION
export const deleteContactInformation = () => async dispatch => {
  try {
    dispatch(deleteContactInformationBegin());
    const res = await axios.delete(`contact-information`, {
      headers: { ...getHeaders() },
    });

    dispatch(deleteContactInformationSuccess({}));
    message.success('Contact information deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteContactInformationFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
