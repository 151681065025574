const actions = {
  GET_CAR_BRAND_BEGIN: 'GET_CAR_BRAND_BEGIN',
  GET_CAR_BRAND_SUCCESS: 'GET_CAR_BRAND_SUCCESS',
  GET_CAR_BRAND_FAILED: 'GET_CAR_BRAND_FAILED',

  GET_ONLY_CAR_BRAND_BEGIN: 'GET_ONLY_CAR_BRAND_BEGIN',
  GET_ONLY_CAR_BRAND_SUCCESS: 'GET_ONLY_CAR_BRAND_SUCCESS',
  GET_ONLY_CAR_BRAND_FAILED: 'GET_ONLY_CAR_BRAND_FAILED',

  ADD_BRAND_BEGIN: 'ADD_BRAND_BEGIN',
  ADD_BRAND_SUCCESS: 'ADD_BRAND_SUCCESS',
  ADD_BRAND_FAILED: 'ADD_BRAND_FAILED',

  UPDATE_BRAND_BEGIN: 'UPDATE_BRAND_BEGIN',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_FAILED: 'UPDATE_BRAND_FAILED',

  DELETE_BRAND_BEGIN: 'DELETE_BRAND_BEGIN',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILED: 'DELETE_BRAND_FAILED',

  getCarBrandBegin: () => {
    return {
      type: actions.GET_CAR_BRAND_BEGIN,
    };
  },

  getCarBrandSuccess: data => {
    return {
      type: actions.GET_CAR_BRAND_SUCCESS,
      data,
    };
  },

  getCarBrandFailed: err => {
    return {
      type: actions.GET_CAR_BRAND_FAILED,
      err,
    };
  },

  getCarBrandBasedOnCarBegin: () => {
    return {
      type: actions.GET_ONLY_CAR_BRAND_BEGIN,
    };
  },

  getCarBrandBasedOnCarSuccess: data => {
    return {
      type: actions.GET_ONLY_CAR_BRAND_SUCCESS,
      data,
    };
  },

  getCarBrandBasedOnCarFailed: err => {
    return {
      type: actions.GET_ONLY_CAR_BRAND_FAILED,
      err,
    };
  },

  addCarBrandBegin: () => {
    return {
      type: actions.ADD_BRAND_BEGIN,
    };
  },

  addCarBrandSuccess: data => {
    return {
      type: actions.ADD_BRAND_SUCCESS,
      data,
    };
  },

  addCarBrandFailed: err => {
    return {
      type: actions.ADD_BRAND_FAILED,
      err,
    };
  },
  updateCarBrandBegin: () => {
    return {
      type: actions.UPDATE_BRAND_BEGIN,
    };
  },

  updateCarBrandSuccess: data => {
    return {
      type: actions.UPDATE_BRAND_SUCCESS,
      data,
    };
  },

  updateCarBrandFailed: err => {
    return {
      type: actions.UPDATE_BRAND_FAILED,
      err,
    };
  },
  deleteCarBrandBegin: () => {
    return {
      type: actions.DELETE_BRAND_BEGIN,
    };
  },

  deleteCarBrandSuccess: data => {
    return {
      type: actions.DELETE_BRAND_SUCCESS,
      data,
    };
  },

  deleteCarBrandFailed: err => {
    return {
      type: actions.DELETE_BRAND_FAILED,
      err,
    };
  },
};

export default actions;
