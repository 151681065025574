const actions = {
  GET_SUBSCRIBER_BEGIN: 'GET_SUBSCRIBER_BEGIN',
  GET_SUBSCRIBER_SUCCESS: 'GET_SUBSCRIBER_SUCCESS',
  GET_SUBSCRIBER_FAILED: 'GET_SUBSCRIBER_FAILED',

  DELETE_SUBSCRIBER_BEGIN: 'DELETE_SUBSCRIBER_BEGIN',
  DELETE_SUBSCRIBER_SUCCESS: 'DELETE_SUBSCRIBER_SUCCESS',
  DELETE_SUBSCRIBER_FAILED: 'DELETE_SUBSCRIBER_FAILED',

  getSubscriberBegin: () => {
    return {
      type: actions.GET_SUBSCRIBER_BEGIN,
    };
  },

  getSubscriberSuccess: data => {
    return {
      type: actions.GET_SUBSCRIBER_SUCCESS,
      data,
    };
  },

  getSubscriberFailed: err => {
    return {
      type: actions.GET_SUBSCRIBER_FAILED,
      err,
    };
  },

  deleteSubscriberBegin: () => {
    return {
      type: actions.DELETE_SUBSCRIBER_BEGIN,
    };
  },

  deleteSubscriberSuccess: data => {
    return {
      type: actions.DELETE_SUBSCRIBER_SUCCESS,
      data,
    };
  },

  deleteSubscriberFailed: err => {
    return {
      type: actions.DELETE_SUBSCRIBER_FAILED,
      err,
    };
  },
};

export default actions;
