const actions = {
  GET_CAR_BEGIN: 'GET_CAR_BEGIN',
  GET_CAR_SUCCESS: 'GET_CAR_SUCCESS',
  GET_CAR_FAILED: 'GET_CAR_FAILED',

  GET_CARS_BEGIN: 'GET_CARS_BEGIN',
  GET_CARS_SUCCESS: 'GET_CARS_SUCCESS',
  GET_CARS_FAILED: 'GET_CARS_FAILED',

  VIN_SEARCH_BEGIN: 'VIN_SEARCH_BEGIN',
  VIN_SEARCH_SUCCESS: 'VIN_SEARCH_SUCCESS',
  VIN_SEARCH_FAILED: 'VIN_SEARCH_FAILED',

  ADD_CAR_BEGIN: 'ADD_CAR_BEGIN',
  ADD_CAR_SUCCESS: 'ADD_CAR_SUCCESS',
  ADD_CAR_FAILED: 'ADD_CAR_FAILED',

  UPDATE_CAR_BEGIN: 'UPDATE_CAR_BEGIN',
  UPDATE_CAR_SUCCESS: 'UPDATE_CAR_SUCCESS',
  UPDATE_CAR_FAILED: 'UPDATE_CAR_FAILED',

  DELETE_CAR_BEGIN: 'DELETE_CAR_BEGIN',
  DELETE_CAR_SUCCESS: 'DELETE_CAR_SUCCESS',
  DELETE_CAR_FAILED: 'DELETE_CAR_FAILED',

  CLONE_CAR_BEGIN: 'CLONE_CAR_BEGIN',
  CLONE_CAR_SUCCESS: 'CLONE_CAR_SUCCESS',
  CLONE_CAR_FAILED: 'CLONE_CAR_FAILED',

  vinSearchBegin: () => {
    return {
      type: actions.VIN_SEARCH_BEGIN,
    };
  },

  vinSearchSuccess: data => {
    return {
      type: actions.VIN_SEARCH_SUCCESS,
      data,
    };
  },

  vinSearchFailed: err => {
    return {
      type: actions.VIN_SEARCH_FAILED,
      err,
    };
  },

  getCarBegin: () => {
    return {
      type: actions.GET_CAR_BEGIN,
    };
  },

  getCarSuccess: data => {
    return {
      type: actions.GET_CAR_SUCCESS,
      data,
    };
  },

  getCarFailed: err => {
    return {
      type: actions.GET_CAR_FAILED,
      err,
    };
  },

  getCarsBegin: () => {
    return {
      type: actions.GET_CARS_BEGIN,
    };
  },

  getCarsSuccess: data => {
    return {
      type: actions.GET_CARS_SUCCESS,
      data,
    };
  },

  getCarsFailed: err => {
    return {
      type: actions.GET_CARS_FAILED,
      err,
    };
  },

  addCarBegin: () => {
    return {
      type: actions.ADD_CAR_BEGIN,
    };
  },

  addCarSuccess: data => {
    return {
      type: actions.ADD_CAR_SUCCESS,
      data,
    };
  },

  addCarFailed: err => {
    return {
      type: actions.ADD_CAR_FAILED,
      err,
    };
  },
  updateCarBegin: () => {
    return {
      type: actions.UPDATE_CAR_BEGIN,
    };
  },

  updateCarSuccess: data => {
    return {
      type: actions.UPDATE_CAR_SUCCESS,
      data,
    };
  },

  updateCarFailed: err => {
    return {
      type: actions.UPDATE_CAR_FAILED,
      err,
    };
  },
  deleteCarBegin: () => {
    return {
      type: actions.DELETE_CAR_BEGIN,
    };
  },

  deleteCarSuccess: data => {
    return {
      type: actions.DELETE_CAR_SUCCESS,
      data,
    };
  },

  deleteCarFailed: err => {
    return {
      type: actions.DELETE_CAR_FAILED,
      err,
    };
  },
  cloneCarBegin: () => {
    return {
      type: actions.CLONE_CAR_BEGIN,
    };
  },

  cloneCarSuccess: data => {
    return {
      type: actions.CLONE_CAR_SUCCESS,
      data,
    };
  },

  cloneCarFailed: err => {
    return {
      type: actions.CLONE_CAR_FAILED,
      err,
    };
  },
};

export default actions;
