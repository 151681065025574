import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));

const NotFound = () => {
  return <Redirect to="/" />;
};

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPass} />
        <Route exact path="/forgot-password/:token" component={ResetPass} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default AuthLayout(FrontendRoutes);
