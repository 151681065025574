const actions = {
  GET_GALLERY_BEGIN: 'GET_GALLERY_BEGIN',
  GET_GALLERY_SUCCESS: 'GET_GALLERY_SUCCESS',
  GET_GALLERY_FAILED: 'GET_GALLERY_FAILED',

  ADD_GALLERY_BEGIN: 'ADD_GALLERY_BEGIN',
  ADD_GALLERY_SUCCESS: 'ADD_GALLERY_SUCCESS',
  ADD_GALLERY_FAILED: 'ADD_GALLERY_FAILED',

  UPDATE_GALLERY_BEGIN: 'UPDATE_GALLERY_BEGIN',
  UPDATE_GALLERY_SUCCESS: 'UPDATE_GALLERY_SUCCESS',
  UPDATE_GALLERY_FAILED: 'UPDATE_GALLERY_FAILED',

  DELETE_GALLERY_BEGIN: 'DELETE_GALLERY_BEGIN',
  DELETE_GALLERY_SUCCESS: 'DELETE_GALLERY_SUCCESS',
  DELETE_GALLERY_FAILED: 'DELETE_GALLERY_FAILED',

  getGalleryBegin: () => {
    return {
      type: actions.GET_GALLERY_BEGIN,
    };
  },

  getGallerySuccess: data => {
    return {
      type: actions.GET_GALLERY_SUCCESS,
      data,
    };
  },

  getGalleryFailed: err => {
    return {
      type: actions.GET_GALLERY_FAILED,
      err,
    };
  },

  addGalleryBegin: () => {
    return {
      type: actions.ADD_GALLERY_BEGIN,
    };
  },

  addGallerySuccess: data => {
    return {
      type: actions.ADD_GALLERY_SUCCESS,
      data,
    };
  },

  addGalleryFailed: err => {
    return {
      type: actions.ADD_GALLERY_FAILED,
      err,
    };
  },
  updateGalleryBegin: () => {
    return {
      type: actions.UPDATE_GALLERY_BEGIN,
    };
  },

  updateGallerySuccess: data => {
    return {
      type: actions.UPDATE_GALLERY_SUCCESS,
      data,
    };
  },

  updateGalleryFailed: err => {
    return {
      type: actions.UPDATE_GALLERY_FAILED,
      err,
    };
  },
  deleteGalleryBegin: () => {
    return {
      type: actions.DELETE_GALLERY_BEGIN,
    };
  },

  deleteGallerySuccess: data => {
    return {
      type: actions.DELETE_GALLERY_SUCCESS,
      data,
    };
  },

  deleteGalleryFailed: err => {
    return {
      type: actions.DELETE_GALLERY_FAILED,
      err,
    };
  },
};

export default actions;
