/* eslint-disable no-underscore-dangle */
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import InspectionReportForm from './InspectionReportForm';
import { SPECS_LIST, inspectReportInitialFormData } from './inspectionReport.schema';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { getAllInspectionReports } from '../../../redux/inspection report/actionCreator';
import { Main } from '../../styled';
import { IMAGE_URL } from '../../../utils/constants';

const EditInspectionReport = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { inspectionReportsData, userType } = useSelector(state => {
    return {
      inspectionReportsData: state.inspectionReport.reports,
      userType: state.auth.user.role.name,
    };
  });

  const [formData, setFormData] = useState({ ...inspectReportInitialFormData });

  useEffect(() => {
    if (inspectionReportsData.length === 0) dispatch(getAllInspectionReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inspectionReportsData && inspectionReportsData.length > 0 && match) {
      const inspectionReportEditDataById = inspectionReportsData.find(item => item?._id === match?.params?.id);
      let radioInspectionReportEditDataById = {};
      let imageInspectionReportEditDataById = {};

      Object.entries(SPECS_LIST).forEach(([specKey]) => {
        let specsOnSpecKey = {};
        if (inspectionReportEditDataById[specKey]) {
          Object.entries(inspectionReportEditDataById[specKey]).forEach(([key, value]) => {
            if (typeof value === 'boolean') {
              specsOnSpecKey = {
                ...specsOnSpecKey,
                [key]: String(value),
              };
              return;
            }
            if (typeof value === 'number' || value) {
              specsOnSpecKey = {
                ...specsOnSpecKey,
                [key]: value ?? '',
              };
            }
          });
        }
        radioInspectionReportEditDataById = {
          ...radioInspectionReportEditDataById,
          ...specsOnSpecKey,
        };
      });

      const getFiles = imageValue => {
        if (Array.isArray(imageValue))
          return imageValue.map((item, index) => {
            return {
              uid: index + 1,
              name: `${item}`,
              status: 'done',
              url: `${IMAGE_URL}/${item}`,
            };
          });
        return [];
      };

      const bodyInspectionSpecs = inspectionReportEditDataById?.bodyInspection;

      Object.entries(bodyInspectionSpecs).forEach(([key, value]) => {
        if (key === 'leftSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            leftImages: getFiles(value?.images),
            leftCaption: value?.caption ?? '',
          };
        } else if (key === 'rightSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            rightImages: getFiles(value?.images),
            rightCaption: value?.caption ?? '',
          };
        } else if (key === 'frontSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            frontImages: getFiles(value?.images),
            frontCaption: value?.caption ?? '',
          };
        } else if (key === 'backSide') {
          imageInspectionReportEditDataById = {
            ...imageInspectionReportEditDataById,
            backImages: getFiles(value?.images),
            backCaption: value?.caption ?? '',
          };
        }
      });

      if (inspectionReportEditDataById?.interior?.interiorControlsAndSwitches) {
        imageInspectionReportEditDataById = {
          ...imageInspectionReportEditDataById,
          interiorControlsAndSwitches: getFiles(inspectionReportEditDataById?.interior?.interiorControlsAndSwitches),
        };
      }

      const editFormData = {
        ...radioInspectionReportEditDataById,
        ...imageInspectionReportEditDataById,
        car: inspectionReportEditDataById?.car?._id,
        date: moment(inspectionReportEditDataById?.date).format('YYYY-MM-DD'),
      };

      ['createdAt', 'bodyInspection', ...Object.keys(SPECS_LIST)].forEach(item => {
        delete editFormData[item];
      });

      setFormData({
        ...formData,
        ...editFormData,
      });
    }
  }, [inspectionReportsData]);

  return (
    <>
      <PageHeader
        ghost
        title="Edit Inspection Report"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/inspection-reports`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <div className="body-type-modal">
            <InspectionReportForm formData={formData} setFormData={setFormData} />
          </div>
        </Cards>
      </Main>
    </>
  );
};
EditInspectionReport.propTypes = {
  match: propTypes.string,
};

export default EditInspectionReport;
