import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getCarColorBegin, getCarColorSuccess, getCarColorFailed,
  addCarColorBegin, addCarColorSuccess, addCarColorFailed,
  updateCarColorBegin, updateCarColorSuccess, updateCarColorFailed,
  deleteCarColorBegin, deleteCarColorSuccess, deleteCarColorFailed,
} = actions;

// GETTING ALL CAR COLORS
export const getAllColors = async (dispatch) => {
  try {
    dispatch(getCarColorBegin());
    const res = await axios.get('car-colors', { headers: { ...getHeaders() }});
    dispatch(getCarColorSuccess(res.data.carColors));
    return res;
  } catch (err) {
    dispatch(getCarColorFailed(err.response.data.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
}

// ADDING NEW CAR COLOR
export const addNewColor = (datas) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(addCarColorBegin());
    const res = await axios.post('car-colors', datas, { headers: { ...getHeaders() } })
    const newColors = [res.data.carColor, ...state.carColor.colors]

    dispatch(addCarColorSuccess(newColors));
    message.success('New car color created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addCarColorFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING COLOR BY ID
export const editCarColor = (id, datas) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateCarColorBegin());
    const res = await axios.put(`car-colors/${id}`, datas, { headers: { ...getHeaders() } });
    const newCarColor = state.carColor.colors.map(color => {
      if (color._id === id)
        return res.data.carColor;
      else
        return color;
    })
    dispatch(updateCarColorSuccess(newCarColor));
    message.success('Car color updated successfully');
    return res;
  } catch (err) {
    dispatch(updateCarColorFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE CAR COLOR
export const deleteCarColor = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteCarColorBegin());
    const res = await axios.delete(`car-colors/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteCarColorSuccess(state.carColor.colors.filter(color => color._id !== id)));
    message.success('Car color deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarColorFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 