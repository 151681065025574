import actions from './actions';

const { GET_CAR_PRICE_RANGE_BEGIN, GET_CAR_PRICE_RANGE_SUCCESS, GET_CAR_PRICE_RANGE_FAILED } = actions;

const initState = {
  minPrice: 0,
  maxPrice: 0,
  error: null,
};

const CarPriceRangeReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CAR_PRICE_RANGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CAR_PRICE_RANGE_SUCCESS:
      return {
        ...state,
        minPrice: data.minPrice,
        maxPrice: data.maxPrice,
      };
    case GET_CAR_PRICE_RANGE_FAILED:
      return {
        ...state,
        error: err,
      };

    default:
      return state;
  }
};
export default CarPriceRangeReducer;
