import actions from './actions';

const {
  GET_GALLERY_BEGIN, GET_GALLERY_SUCCESS, GET_GALLERY_FAILED,
  ADD_GALLERY_BEGIN, ADD_GALLERY_SUCCESS, ADD_GALLERY_FAILED,
  UPDATE_GALLERY_BEGIN, UPDATE_GALLERY_SUCCESS, UPDATE_GALLERY_FAILED,
  DELETE_GALLERY_BEGIN, DELETE_GALLERY_SUCCESS, DELETE_GALLERY_FAILED
} = actions;

const initState = {
  galleries: [],
  loading: false,
  error: null,
};


const GalleryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_GALLERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: data,
        loading: false,
      };
    case GET_GALLERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_GALLERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: data,
        loading: false,
      };
    case ADD_GALLERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_GALLERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: data,
        loading: false,
      };
    case UPDATE_GALLERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_GALLERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: data,
        loading: false,
      };
    case DELETE_GALLERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default GalleryReducer;