import actions from './actions';

const {
  GET_ROLE_BEGIN, GET_ROLE_SUCCESS, GET_ROLE_FAILED,
  ADD_ROLE_BEGIN, ADD_ROLE_SUCCESS, ADD_ROLE_FAILED,
  UPDATE_ROLE_BEGIN, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILED,
  DELETE_ROLE_BEGIN, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILED
} = actions;

const initState = {
  roles: [],
  loading: false,
  error: null,
};


const RoleReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        roles: data,
        loading: false,
      };
    case GET_ROLE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        roles: data,
        loading: false,
      };
    case ADD_ROLE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: data,
        loading: false,
      };
    case UPDATE_ROLE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: data,
        loading: false,
      };
    case DELETE_ROLE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default RoleReducer;