import actions from './actions';
import axios from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';
import { getHeaders } from '../../utils/common';

const {
  getServicingHistoryBegin, getServicingHistorySuccess, getServicingHistoryFailed,
  addServicingHistoryBegin, addServicingHistorySuccess, addServicingHistoryFailed,
  updateServicingHistoryBegin, updateServicingHistorySuccess, updateServicingHistoryFailed,
  deleteServicingHistoryBegin, deleteServicingHistorySuccess, deleteServicingHistoryFailed,
} = actions;

// GETTING ALL SERVICING_HISTORIES
export const getAllServicingHistories = async (dispatch) => {
  try {
    dispatch(getServicingHistoryBegin());
    const res = await axios.get('service-histories', { headers: { ...getHeaders() } });
    dispatch(getServicingHistorySuccess(res.data.serviceHistories));
  } catch (err) {
    dispatch(getServicingHistoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
}

// ADDING NEW SERVICING_HISTORY
export const addNewServicingHistorie = (data, form) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(addServicingHistoryBegin());
    const res = await axios.post('service-histories', data, { headers: { ...getHeaders() } });
    const newServicingHistories = [res.data.serviceHistory, ...state.servicingHistory.histories]

    dispatch(addServicingHistorySuccess(newServicingHistories));
    message.success('New servicing history created successfully');
    form.resetFields();
  } catch (err) {
    dispatch(addServicingHistoryFailed(err?.message));
    dispatch(errorHandling(err));
  }
}

// UPDATING SERVICING_HISTORIES
export const editServicingHistorie = (id, datas) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateServicingHistoryBegin());
    const res = await axios.put(`service-histories/${id}`, datas, { headers: { ...getHeaders() } });
    const newServicingHistories = state.servicingHistory.histories.map(servicingHistory => {
      if (servicingHistory._id === id) return res.data.serviceHistory;
      else return servicingHistory;
    })
    dispatch(updateServicingHistorySuccess(newServicingHistories));
    message.success('Servicing history updated successfully')
  } catch (err) {
    dispatch(updateServicingHistoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
}

// DELETE SERVICING_HISTORIES
export const deleteServicingHistorie = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteServicingHistoryBegin());
    await axios.delete(`service-histories/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteServicingHistorySuccess(state.servicingHistory.histories.filter(servicingHistory => servicingHistory._id !== id)));
    message.success('Servicing history deleted successfully')
  } catch (err) {
    dispatch(deleteServicingHistoryFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
} 