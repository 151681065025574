import { message } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getCarBrandBegin,
  getCarBrandSuccess,
  getCarBrandFailed,
  getCarBrandBasedOnCarBegin,
  getCarBrandBasedOnCarSuccess,
  getCarBrandBasedOnCarFailed,
  addCarBrandBegin,
  addCarBrandSuccess,
  addCarBrandFailed,
  updateCarBrandBegin,
  updateCarBrandSuccess,
  updateCarBrandFailed,
  deleteCarBrandBegin,
  deleteCarBrandSuccess,
  deleteCarBrandFailed,
} = actions;

// GETTING ALL CAR BRANDS
let cancelToken;
export const getAllBrands = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getCarBrandSuccess({ carBrands: [] }));
    dispatch(getCarBrandBegin());
    const res = await axios.get(`car-brands${filter || ''}`, { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    const { carBrands: brands, total, currentPage, pageLimit } = res.data;

    dispatch(getCarBrandSuccess({ brands, total, currentPage, pageLimit }));
    return res;
  } catch (err) {
    dispatch(getCarBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

// GETTING ALL CAR BRAND BASED ON CAR
export const getCarBrands = () => async dispatch => {
  try {
    dispatch(getCarBrandBasedOnCarBegin());
    const res = await axios.get('car-brands/by/car', { headers: { ...getHeaders() } });
    dispatch(getCarBrandBasedOnCarSuccess(res.data.carBrands));
    return res;
  } catch (err) {
    dispatch(getCarBrandBasedOnCarFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

// ADDING NEW CAR BRAND
export const addNewBrand = datas => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addCarBrandBegin());
    const res = await axios.post('car-brands', datas, {
      headers: { ...getHeaders() },
    });
    const newBrands = [res.data.carBrand, ...state.carBrand.brands];

    dispatch(addCarBrandSuccess(newBrands));
    message.success('New car brand created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addCarBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// UPDATING CAR BY ID
export const editCarBrand = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateCarBrandBegin());
    const res = await axios.put(`car-brands/${id}`, datas, {
      headers: { ...getHeaders() },
    });
    const newCarBrand = state.carBrand.brands.map(brand => {
      if (brand._id === id) return res.data.carBrand;
      return brand;
    });
    dispatch(updateCarBrandSuccess(newCarBrand));
    message.success('Car brand updated successfully');
    return res;
  } catch (err) {
    dispatch(updateCarBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

// DELETE CAR BRAND
export const deleteCarBrand = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteCarBrandBegin());
    const res = await axios.delete(`car-brands/${id}`, {
      headers: { ...getHeaders() },
    });

    dispatch(deleteCarBrandSuccess(state.carBrand.brands.filter(brand => brand._id !== id)));
    message.success('Car brand deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
