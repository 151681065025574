import actions from './actions';

const {
  GET_CUSTOMER_QUERY_BEGIN, GET_CUSTOMER_QUERY_SUCCESS, GET_CUSTOMER_QUERY_FAILED,
  ADD_CUSTOMER_QUERY_BEGIN, ADD_CUSTOMER_QUERY_SUCCESS, ADD_CUSTOMER_QUERY_FAILED,
  UPDATE_CUSTOMER_QUERY_BEGIN, UPDATE_CUSTOMER_QUERY_SUCCESS, UPDATE_CUSTOMER_QUERY_FAILED,
  DELETE_CUSTOMER_QUERY_BEGIN, DELETE_CUSTOMER_QUERY_SUCCESS, DELETE_CUSTOMER_QUERY_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  queries: [],
  loading: false,
  error: null,
};


const CustomerQueryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CUSTOMER_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_QUERY_SUCCESS:
      return {
        ...state,
        total: data.total || 0,
        queries: data.customerQueries,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.pageLimit ? false : true,
      };
    case GET_CUSTOMER_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CUSTOMER_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CUSTOMER_QUERY_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        queries: data,
        loading: false,
      };
    case ADD_CUSTOMER_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CUSTOMER_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CUSTOMER_QUERY_SUCCESS:
      return {
        ...state,
        queries: data,
        loading: false,
      };
    case UPDATE_CUSTOMER_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CUSTOMER_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CUSTOMER_QUERY_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        queries: data,
        loading: false,
      };
    case DELETE_CUSTOMER_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CustomerQueryReducer;