export const SERVER_URL = `https://${process.env.REACT_APP_HOST}/api`;
export const IMAGE_URL = `https://${process.env.REACT_APP_HOST}`;

// Need to remove language and lang_code
export const LANGUAGE = `${process.env.REACT_APP_LANGUAGE}`;
export const LANG_CODE = `${process.env.REACT_APP_LANGUAGE_CODE}`;
// ..

// Need to add here if you want to add new country domain
export const COUNTRY_TOP_LEVEL_DOMAIN = {
  asterdio: 'asterdio.xyz',
  bj: 'bj',
  ci: 'ci',
  coke: 'co.ke',
  coza: 'co.za',
  ma: 'ma',
  rw: 'rw',
  sn: 'sn',
  tg: 'tg',
  tz: 'tz',
  ug: 'ug',
};

// All the languages
export const ALL_LANGUAGES = {
  english: {
    label: 'English',
    value: 'en',
    code: 'US',
    includedInDomain: [
      COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
      COUNTRY_TOP_LEVEL_DOMAIN.bj,
      COUNTRY_TOP_LEVEL_DOMAIN.ci,
      COUNTRY_TOP_LEVEL_DOMAIN.coke,
      COUNTRY_TOP_LEVEL_DOMAIN.coza,
      COUNTRY_TOP_LEVEL_DOMAIN.ma,
      COUNTRY_TOP_LEVEL_DOMAIN.rw,
      COUNTRY_TOP_LEVEL_DOMAIN.sn,
      COUNTRY_TOP_LEVEL_DOMAIN.tg,
      COUNTRY_TOP_LEVEL_DOMAIN.tz,
      COUNTRY_TOP_LEVEL_DOMAIN.ug,
    ],
  },
  french: {
    label: 'French',
    value: 'fr',
    code: 'FR',
    includedInDomain: [
      COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
      COUNTRY_TOP_LEVEL_DOMAIN.bj,
      COUNTRY_TOP_LEVEL_DOMAIN.ci,
      COUNTRY_TOP_LEVEL_DOMAIN.coke,
      COUNTRY_TOP_LEVEL_DOMAIN.ma,
      COUNTRY_TOP_LEVEL_DOMAIN.rw,
      COUNTRY_TOP_LEVEL_DOMAIN.sn,
      COUNTRY_TOP_LEVEL_DOMAIN.tg,
      COUNTRY_TOP_LEVEL_DOMAIN.tz,
      COUNTRY_TOP_LEVEL_DOMAIN.ug,
    ],
  },
  arabic: {
    label: 'Arabic',
    value: 'ar',
    code: 'MA',
    includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.ma, COUNTRY_TOP_LEVEL_DOMAIN.tg],
  },
  kinyarnwanda: {
    label: 'Kinyarnwanda',
    value: 'rw',
    code: 'RW',
    includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.asterdio, COUNTRY_TOP_LEVEL_DOMAIN.rw],
  },
  zulu: {
    label: 'Zulu',
    value: 'zu',
    code: 'ZA',
    includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza],
  },
  xhosa: {
    label: 'Xhosa',
    value: 'xh',
    code: 'ZA',
    includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza],
  },
  wolof: {
    label: 'Wolof',
    value: 'sn',
    code: 'SN',
    includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.sn],
  },
};

// Filter language on the basis of top level domain nFame.
export const FILTERED_LANG = Object.values(ALL_LANGUAGES);

export function getTopLevelDomain() {
  // const topLevelDomainArr = window.location.hostname.split('.auto24.');
  const topLevelDomainArr = window.location.hostname.split('auto24.');
  let topLevelDomain = '';

  if (topLevelDomainArr.includes('localhost')) topLevelDomain = topLevelDomainArr[0] ?? '';
  else if (topLevelDomainArr.includes('https://admin.dev-')) topLevelDomain = topLevelDomainArr[1] ?? '';
  else topLevelDomain = topLevelDomainArr[topLevelDomainArr.length - 1];

  return topLevelDomain;
}

function getDefaultLang() {
  const topLevelDomain = getTopLevelDomain();
  let defaultLang = {};

  switch (topLevelDomain) {
    case COUNTRY_TOP_LEVEL_DOMAIN.bj:
      defaultLang = ALL_LANGUAGES.french;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.ci:
      defaultLang = ALL_LANGUAGES.french;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.coza:
      defaultLang = ALL_LANGUAGES.english;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.ma:
      defaultLang = ALL_LANGUAGES.french;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.rw:
      defaultLang = ALL_LANGUAGES.french;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.sn:
      defaultLang = ALL_LANGUAGES.english;
      break;
    case COUNTRY_TOP_LEVEL_DOMAIN.tg:
      defaultLang = ALL_LANGUAGES.french;
      break;

    default:
      defaultLang = ALL_LANGUAGES.english;
  }
  return defaultLang;
}

export const DEFAULT_LANG = getDefaultLang();

function languagesList() {
  const topLevelDomain = getTopLevelDomain();
  let langList = {};
  FILTERED_LANG.forEach(item => {
    if (item.includedInDomain.includes(topLevelDomain)) {
      langList = {
        ...langList,
        [item.label]: item.value,
      };
    }
  });
  return langList;
}

// export const LANGUAGE_BY_DOMAIN = Object.entries(languagesList()).filter(item => DEFAULT_LANG.value !== item[1]);
export const LANGUAGE_BY_DOMAIN = Object.entries(languagesList());
