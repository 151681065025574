import { DEFAULT_LANG, FILTERED_LANG, getTopLevelDomain } from '../../utils/constants';

// export interface LaguageType {
//   code: string;
//   name: string;
//   langCode: string;
// }

// function getLanguageWithCode(): LaguageType[] {
function getLanguageWithCode() {
  const topLevelDomain = getTopLevelDomain();

  // const languagesList: LaguageType[] = [];
  const languagesList = [];
  FILTERED_LANG.forEach(item => {
    if (item.includedInDomain.includes(topLevelDomain)) {
      if (DEFAULT_LANG?.value === item?.value)
        languagesList.unshift({
          name: item?.label,
          code: item?.code,
          langCode: item?.value,
        });
      else
        languagesList.push({
          name: item?.label,
          code: item?.code,
          langCode: item?.value,
        });
    }
  });
  return languagesList;
}

// export const LanguageWithCode: LaguageType[] = getLanguageWithCode();
export const LanguageWithCode = getLanguageWithCode();
