import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Admin from '../../routes/admin';
import Writer from '../../routes';

const ProtectedRoute = () => {
  const { isLoggedIn, user } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login,
      user: state.auth.user,
    };
  });
  return isLoggedIn ? (
    user?.role?.name === 'admin' ? (
      <Switch>
        <Route path="/admin" component={Admin} />
      </Switch>
    ) : user?.role?.name === 'writer' ? (
      <Switch>
        <Route path="/writer" component={Writer} />
      </Switch>
    ) : (
      <Redirect to="/" />
    )
  ) : (
    <Redirect to="/" />
  );
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
