// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// USER COMPONENTS
import Pages from './pages';
import withAdminLayout from '../../layout/withAdminLayout';
import CarDetail from '../../container/car/overview/CarDetail';
import Blog from '../../container/blog/BlogMain';
import CreateBlog from '../../container/blog/overview/CreateBlog';
import EditBlog from '../../container/blog/overview/EditBlog';
import DisplayBlog from '../../container/blog/overview/DisplayBlog';
import BlogCategory from '../../container/blogcategory/BlogCategoryMain';
import ContactInformation from '../../container/contact information/ContactInfoMain';
import DisplayPage from '../../container/page/overview/DisplayPage';
import CarColor from '../../container/carcolor/CarColorMain';
import CreateCar from '../../container/car/components/CreateCar';
import EditCar from '../../container/car/components/EditCar';
import InspectionReport from '../../container/inspectionReport/InspectionReportMain';
import CreateInspectionReport from '../../container/inspectionReport/components/CreateInspectionReport';
import EditInspectionReport from '../../container/inspectionReport/components/EditInspectionReport';
import DisplayInspectionReport from '../../container/inspectionReport/components/DisplayInspectionReport';
import ContactQuery from '../../container/contact information/ContactQuery';
import BookingsMain from '../../container/bookings/BookingsMain';
import ScrollToTop from '../../components/utilities/ScrollToTop';

const Dashboard = lazy(() => import('../../container/dashboard'));
const User = lazy(() => import('../../container/user/UserMain'));
const Car = lazy(() => import('../../container/car/Car'));
const CarBodyType = lazy(() => import('../../container/carbody/CarBodyTypeMain'));
const CarModel = lazy(() => import('../../container/carmodel/CarModelMain'));
const CarBrand = lazy(() => import('../../container/carbrand/CarBrandMain'));
const Faq = lazy(() => import('../../container/faq/FaqMain'));
const CustomerQuery = lazy(() => import('../../container/customer query/CustomerQuery'));
const UserRequirements = lazy(() => import('../../container/customer interest/UserRequirements'));
const Testimonials = lazy(() => import('../../container/testimonial/TestimonialMain'));
const FooterContent = lazy(() => import('../../container/homepageContent/footerContent/FormContentMain'));
const SearchInquiry = lazy(() => import('../../container/search inquiry/SearchInquiry'));
const Page = lazy(() => import('../../container/page/PageMain'));
const CreatePage = lazy(() => import('../../container/page/overview/CreatePage'));
const EditPage = lazy(() => import('../../container/page/overview/EditPage'));
const Profile = lazy(() => import('../../container/profile/myProfile/Index'));
const Gallery = lazy(() => import('../../container/gallery/GalleryMain'));
const SellingCar = lazy(() => import('../../container/sellingcar/SellingCar'));
const Store = lazy(() => import('../../container/store/StoreMain'));
const Subscriber = lazy(() => import('../../container/subscriber/Subscriber'));
const FinancingPartner = lazy(() => import('../../container/financing partner/FinancingPartnerMain'));
const Financing = lazy(() => import('../../container/financing/FinancingMain'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const AddOfferCar = lazy(() => import('../../container/offercar/AddOfferCar'));
const OfferCar = lazy(() => import('../../container/offercar/OfferCar'));
const PriceTool = lazy(() => import('../../container/priceTool/PriceTool'));
const OurTeam = lazy(() => import('../../container/ourTeam/OurTeamMain'));
const PressMain = lazy(() => import('../../container/press/PressMain'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <ScrollToTop />
        <Route exact path="/" component={Dashboard} />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/users`} component={User} />
        <Route exact path={`${path}/profile`} component={Profile} />

        <Route exact path={`${path}/cars`} component={Car} />
        <Route exact path={`${path}/cars/car-detail/:id`} component={CarDetail} />
        <Route exact path={`${path}/cars/add`} component={CreateCar} />
        <Route path={`${path}/offer-car/add`} component={AddOfferCar} />
        <Route path={`${path}/offer-car/edit/:id`} component={AddOfferCar} />
        <Route exact path={`${path}/offer-cars`} component={OfferCar} />
        <Route exact path={`${path}/cars/edit/:id`} component={EditCar} />
        <Route path={`${path}/car-body-types`} component={CarBodyType} />
        <Route path={`${path}/car-models`} component={CarModel} />
        <Route path={`${path}/car-brands`} component={CarBrand} />
        <Route path={`${path}/car-colors`} component={CarColor} />
        <Route path={`${path}/price-tool`} component={PriceTool} />
        <Route exact path={`${path}/inspection-reports`} component={InspectionReport} />
        <Route exact path={`${path}/inspection-reports/add`} component={CreateInspectionReport} />
        <Route exact path={`${path}/inspection-reports/edit/:id`} component={EditInspectionReport} />
        <Route exact path={`${path}/inspection-reports/display/:id`} component={DisplayInspectionReport} />

        <Route exact path={`${path}/customer-queries`} component={CustomerQuery} />
        <Route exact path={`${path}/faqs`} component={Faq} />
        <Route exact path={`${path}/user-requirements`} component={UserRequirements} />
        <Route path={`${path}/testimonials`} component={Testimonials} />

        <Route exact path={`${path}/blogs`} component={Blog} />
        <Route exact path={`${path}/blogs/add`} component={CreateBlog} />
        <Route exact path={`${path}/blogs/edit/:id`} component={EditBlog} />
        <Route exact path={`${path}/blogs/view/:id`} component={DisplayBlog} />
        <Route exact path={`${path}/blog-categories`} component={BlogCategory} />

        <Route exact path={`${path}/gallery`} component={Gallery} />
        <Route exact path={`${path}/pages`} component={Page} />
        <Route exact path={`${path}/pages/add`} component={CreatePage} />
        <Route exact path={`${path}/pages/display/:id`} component={DisplayPage} />
        <Route exact path={`${path}/pages/edit/:id`} component={EditPage} />
        <Route path={`${path}/selling-cars`} component={SellingCar} />
        <Route path={`${path}/stores`} component={Store} />
        <Route path={`${path}/subscribers`} component={Subscriber} />
        <Route path={`${path}/contact-information`} component={ContactInformation} />
        <Route path={`${path}/contact-query`} component={ContactQuery} />

        <Route exact path={`${path}/financing-partners`} component={FinancingPartner} />
        <Route exact path={`${path}/financings`} component={Financing} />
        <Route exact path={`${path}/search-inquiries`} component={SearchInquiry} />

        <Route exact path={`${path}/footer-content`} component={FooterContent} />

        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />

        <Route exact path={`${path}/bookings`} component={BookingsMain} />
        <Route path={`${path}/our-team`} component={OurTeam} />
        <Route path={`${path}/press`} component={PressMain} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin, 'admin');
