import * as Yup from 'yup';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicCarFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`description${item[1]}`]: '',
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicCarFields();
  const initialFormData = {
    isMultiLang: 'false',
    isElectric: 'false',
    isNewCar: 'false',
    vin: '',
    price: '',
    avitoPrice: '',
    status: null,
    newCarPriceFrom: '',
    discount: '',
    discountPercent: '',
    mileage: '',
    isHotDeal: null,
    lowPrice: null,
    featured: null,
    clean: null,
    brand: null,
    model: null,
    modelYear: null,
    bodyType: null,
    engineSize: '',
    dateFirstUse: null,
    transmission: null,
    fuelType: null,
    noOfKeys: '',
    noOfSeats: '',
    weight: '',
    noOfOwners: '',
    exteriorColor: null,
    interiorColor: null,
    autonomy: '',
    enginePower: '',
    store: null,
    condition: null,
    fullTankCapacity: '',
    fuelConsumption: '',
    emissionCO2: '',
    length: '',
    breadth: '',
    height: '',
    fiscalPower: '',
    warranty: '',
    speedRegulator: null,
    airCondition: null,
    bluetooth: null,
    cdPlayer: null,
    reverseCamera: null,
    gpsNavigation: null,
    parkAssist: null,
    startAndStop: null,
    airBag: null,
    abs: null,
    computer: null,
    rims: null,
    sunRoof: null,
    electricMirror: null,
    electricWindow: null,
    sequential: null,
    maisonMere: null,
    imported: null,
    tamperedOdometer: null,
    audio: null,
    technicalVisitCertificate: null,
    view360: '',
    images: null,
    keys: null,
    // carIssueArr: [{ issueCaption: '', issueImage: null }],
    carIssueArr: [],
    steeringPosition: null,
    ...dynamicFields,
  };

  return initialFormData;
}

export const carInitialFormData = getInitialFormData();

export function carValidationSchema(flags) {
  let validationFields = {
    [`description${DEFAULT_LANG.value}`]: Yup.string().required(`Description(${DEFAULT_LANG.label}) is required!`),
    dateFirstUse: flags?.isNewCarFlag
      ? Yup.string().nullable()
      : Yup.string()
          .nullable()
          .required('Please select Date First Use!'),
    transmission: flags?.isElectricFlag
      ? Yup.string().nullable()
      : Yup.string()
          .nullable()
          .required('Please select Transmission Type!'),
    fuelType: flags?.isElectricFlag
      ? Yup.string().nullable()
      : Yup.string()
          .nullable()
          .required('Please select Fuel Type!'),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (flags?.isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`description${value}`]: Yup.string().required(`Description(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`description${value}`]: Yup.string(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    isElectric: Yup.string(),
    isNewCar: Yup.string(),
    vin: Yup.string(),
    price: Yup.number().required('Enter Price!'),
    status: Yup.string()
      .nullable()
      .required('Please select Status!'),
    newCarPriceFrom: Yup.number(),
    discount: Yup.number(),
    discountPercent: Yup.number(),
    mileage: Yup.number().required('Enter Mileage!'),
    isHotDeal: Yup.string().nullable(),
    lowPrice: Yup.string().nullable(),
    featured: Yup.string().nullable(),
    clean: Yup.string().nullable(),
    brand: Yup.string()
      .nullable()
      .required('Please select Brand!'),
    model: Yup.string()
      .nullable()
      .required('Please select Model!'),
    modelYear: Yup.string()
      .nullable()
      .required('Please select Car Year!'),
    bodyType: Yup.string()
      .nullable()
      .required('Please select Car Body Type!'),
    engineSize: Yup.number(),
    noOfKeys: Yup.number(),
    noOfSeats: Yup.number(),
    weight: Yup.number(),
    noOfOwners: Yup.number(),
    exteriorColor: Yup.string()
      .nullable()
      .required('Please select Exterior Color!'),
    interiorColor: Yup.string()
      .nullable()
      .required('Please select Interior Color!'),
    autonomy: Yup.number(),
    enginePower: Yup.string(),
    store: Yup.string()
      .nullable()
      .required('Please select Store!'),
    condition: Yup.string()
      .nullable()
      .required('Please select Condition!'),
    fullTankCapacity: Yup.number(),
    fuelConsumption: Yup.string(),
    emissionCO2: Yup.string(),
    length: Yup.number(),
    breadth: Yup.number(),
    height: Yup.number(),
    fiscalPower: Yup.number(),
    warranty: Yup.number(),
    speedRegulator: Yup.string().nullable(),
    airCondition: Yup.string().nullable(),
    bluetooth: Yup.string().nullable(),
    cdPlayer: Yup.string().nullable(),
    reverseCamera: Yup.string().nullable(),
    gpsNavigation: Yup.string().nullable(),
    parkAssist: Yup.string().nullable(),
    startAndStop: Yup.string().nullable(),
    airBag: Yup.string().nullable(),
    abs: Yup.string().nullable(),
    computer: Yup.string().nullable(),
    rims: Yup.string().nullable(),
    sunRoof: Yup.string().nullable(),
    electricMirror: Yup.string().nullable(),
    electricWindow: Yup.string().nullable(),
    sequential: Yup.string().nullable(),
    maisonMere: Yup.string().nullable(),
    imported: Yup.string().nullable(),
    tamperedOdometer: Yup.string().nullable(),
    audio: Yup.mixed().nullable(),
    technicalVisitCertificate: Yup.mixed().nullable(),
    view360: Yup.string(),
    images: Yup.mixed().nullable(),
    keys: Yup.mixed().nullable(),
    carIssueArr: Yup.array().of(
      Yup.object().shape({
        issueCaption: Yup.string().required('Car Issue Caption is required'),
        issueImage: Yup.mixed()
          .nullable()
          .required('Car Issue Image is required'),
      }),
    ),
    steeringPosition: Yup.string().required('Please select Steering Position!'),
    ...validationFields,
  });
}
