const actions = {
  GET_REPORT_BEGIN: 'GET_REPORT_BEGIN',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILED: 'GET_REPORT_FAILED',

  ADD_REPORT_BEGIN: 'ADD_REPORT_BEGIN',
  ADD_REPORT_SUCCESS: 'ADD_REPORT_SUCCESS',
  ADD_REPORT_FAILED: 'ADD_REPORT_FAILED',

  UPDATE_REPORT_BEGIN: 'UPDATE_REPORT_BEGIN',
  UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_FAILED: 'UPDATE_REPORT_FAILED',

  DELETE_REPORT_BEGIN: 'DELETE_REPORT_BEGIN',
  DELETE_REPORT_SUCCESS: 'DELETE_REPORT_SUCCESS',
  DELETE_REPORT_FAILED: 'DELETE_REPORT_FAILED',

  getReportBegin: () => {
    return {
      type: actions.GET_REPORT_BEGIN,
    };
  },

  getReportSuccess: data => {
    return {
      type: actions.GET_REPORT_SUCCESS,
      data,
    };
  },

  getReportFailed: err => {
    return {
      type: actions.GET_REPORT_FAILED,
      err,
    };
  },

  addReportBegin: () => {
    return {
      type: actions.ADD_REPORT_BEGIN,
    };
  },

  addReportSuccess: data => {
    return {
      type: actions.ADD_REPORT_SUCCESS,
      data,
    };
  },

  addReportFailed: err => {
    return {
      type: actions.ADD_REPORT_FAILED,
      err,
    };
  },
  updateReportBegin: () => {
    return {
      type: actions.UPDATE_REPORT_BEGIN,
    };
  },

  updateReportSuccess: data => {
    return {
      type: actions.UPDATE_REPORT_SUCCESS,
      data,
    };
  },

  updateReportFailed: err => {
    return {
      type: actions.UPDATE_REPORT_FAILED,
      err,
    };
  },
  deleteReportBegin: () => {
    return {
      type: actions.DELETE_REPORT_BEGIN,
    };
  },

  deleteReportSuccess: data => {
    return {
      type: actions.DELETE_REPORT_SUCCESS,
      data,
    };
  },

  deleteReportFailed: err => {
    return {
      type: actions.DELETE_REPORT_FAILED,
      err,
    };
  },
};

export default actions;
