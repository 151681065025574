import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers';

const reduxDevTool =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(
      applyMiddleware(thunk))
    : compose(
      applyMiddleware(thunk))
const store = createStore(rootReducer, reduxDevTool);

export default store;