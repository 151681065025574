// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React from 'react';
import propTypes from 'prop-types';
import { isWebUri } from 'valid-url';
import { message } from 'antd';

// USER'S COMPONENTS
import { Modal } from '../../../components/modals/antd-modals';

const View360 = ({ visible, onCancel, name, car360View }) => {
  if (car360View) {
    const get360Source = (imgStr) => {
      if (imgStr.startsWith('<script') || imgStr.startsWith('<iframe')) {
        const div = document.createElement('div')
        div.innerHTML = imgStr
        const iframe = div.querySelector('iframe');
        if (iframe)
          return iframe.src;
        else {
          return div.firstChild.getAttribute('data-kuula');
        }
      }
      else return false;
    }

    const url = get360Source(car360View);
    return (isWebUri(url)) ? (
      <Modal
        type={'primary'}
        title={name + " 360 View"}
        width={'70%'}
        visible={visible}
        footer={null}
        onCancel={onCancel}
      >
        <iframe
          src={url}
          width="100%"
          height="640"
          frameborder="0"
          allow="xr-spatial-tracking; gyroscope; accelerometer; fullscreen"
          scrolling="no"
        ></iframe>
      </Modal >
    ) : message.warning('Not a valid 360 url');
  }
  else null;

};

View360.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default View360;