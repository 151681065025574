import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie'
import { getHeaders } from '../../utils/common';

const {
  getCarModelBegin, getCarModelSuccess, getCarModelFailed,
  getCarModelByBrandBegin, getCarModelByBrandSuccess, getCarModelByBrandFailed,
  getBrandYearBegin, getBrandYearSuccess, getBrandYearFailed,
  addCarModelBegin, addCarModelSuccess, addCarModelFailed,
  updateCarModelBegin, updateCarModelSuccess, updateCarModelFailed,
  deleteCarModelBegin, deleteCarModelSuccess, deleteCarModelFailed,
} = actions;

// GETTING ALL CAR MODELS
let cancelToken;
export const getAllModel = filter => async (dispatch) => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getCarModelSuccess({ carModels: [] }));
    dispatch(getCarModelBegin());
    const res = await axios.get(`car-models${filter ? filter : ''}`, { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    const { carModels, total, currentPage, pageLimit } = res.data;
    dispatch(getCarModelSuccess({ carModels, total, currentPage, pageLimit }));
    return res;
  } catch (err) {
    dispatch(getCarModelFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
}

// GETTING ALL CAR MODELS
export const getAllModelsByBrand = id => async (dispatch) => {
  try {
    dispatch(getCarModelByBrandSuccess([]));
    dispatch(getCarModelByBrandBegin());
    const res = await axios.get(`car-models/by-brand/${id}`, { headers: { ...getHeaders() } });
    dispatch(getCarModelByBrandSuccess(res.data.carModels));
    return res;
  } catch (err) {
    dispatch(getCarModelByBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// GETTING ALL CAR MODELS
export const getAllYearsByBrand = id => async (dispatch) => {
  try {
    dispatch(getBrandYearBegin());
    dispatch(getBrandYearSuccess([]));
    const res = await axios.get(`car-models/model-years/by-model/${id}`);
    dispatch(getBrandYearSuccess(res.data.modelYears));
    return res;
  } catch (err) {
    dispatch(getBrandYearFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// ADDING NEW CAR MODEL
export const addNewModel = (data) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(addCarModelBegin());
    const res = await axios.post('car-models', data, { headers: { ...getHeaders() } });
    const newModels = [res.data.carModel, ...state.carModel.models]

    dispatch(addCarModelSuccess(newModels));
    message.success('New car model created successfully');
    // form.resetFields();
    return res;
  } catch (err) {
    dispatch(addCarModelFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// UPDATING CAR MODEL BY ID
export const editCarModel = (id, datas) => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(updateCarModelBegin());
    const res = await axios.put(`car-models/${id}`, datas, { headers: { ...getHeaders() } });
    const newCarModel = state.carModel.models.map(model => {
      if (model._id === id) return res.data.carModel;
      else return model;
    })
    dispatch(updateCarModelSuccess(newCarModel));
    message.success('Car model updated successfully');
    return res;
  } catch (err) {
    dispatch(updateCarModelFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
}

// DELETE CAR MODEL
export const deleteCarModel = id => async (dispatch) => {
  try {
    const state = store.getState();
    dispatch(deleteCarModelBegin());
    const res = await axios.delete(`car-models/${id}`, { headers: { ...getHeaders() } });

    dispatch(deleteCarModelSuccess(state.carModel.models.filter(model => model._id !== id)));
    message.success('Car model deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteCarModelFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
} 