const actions = {
  GET_CONTACT_INFORMATION_BEGIN: 'GET_CONTACT_INFORMATION_BEGIN',
  GET_CONTACT_INFORMATION_SUCCESS: 'GET_CONTACT_INFORMATION_SUCCESS',
  GET_CONTACT_INFORMATION_FAILED: 'GET_CONTACT_INFORMATION_FAILED',

  ADD_CONTACT_INFORMATION_BEGIN: 'ADD_CONTACT_INFORMATION_BEGIN',
  ADD_CONTACT_INFORMATION_SUCCESS: 'ADD_CONTACT_INFORMATION_SUCCESS',
  ADD_CONTACT_INFORMATION_FAILED: 'ADD_CONTACT_INFORMATION_FAILED',

  UPDATE_CONTACT_INFORMATION_BEGIN: 'UPDATE_CONTACT_INFORMATION_BEGIN',
  UPDATE_CONTACT_INFORMATION_SUCCESS: 'UPDATE_CONTACT_INFORMATION_SUCCESS',
  UPDATE_CONTACT_INFORMATION_FAILED: 'UPDATE_CONTACT_INFORMATION_FAILED',

  DELETE_CONTACT_INFORMATION_BEGIN: 'DELETE_CONTACT_INFORMATION_BEGIN',
  DELETE_CONTACT_INFORMATION_SUCCESS: 'DELETE_CONTACT_INFORMATION_SUCCESS',
  DELETE_CONTACT_INFORMATION_FAILED: 'DELETE_CONTACT_INFORMATION_FAILED',

  getContactInformationBegin: () => {
    return {
      type: actions.GET_CONTACT_INFORMATION_BEGIN,
    };
  },

  getContactInformationSuccess: data => {
    return {
      type: actions.GET_CONTACT_INFORMATION_SUCCESS,
      data,
    };
  },

  getContactInformationFailed: err => {
    return {
      type: actions.GET_CONTACT_INFORMATION_FAILED,
      err,
    };
  },

  addContactInformationBegin: () => {
    return {
      type: actions.ADD_CONTACT_INFORMATION_BEGIN,
    };
  },

  addContactInformationSuccess: data => {
    return {
      type: actions.ADD_CONTACT_INFORMATION_SUCCESS,
      data,
    };
  },

  addContactInformationFailed: err => {
    return {
      type: actions.ADD_CONTACT_INFORMATION_FAILED,
      err,
    };
  },

  updateContactInformationBegin: () => {
    return {
      type: actions.UPDATE_CONTACT_INFORMATION_BEGIN,
    };
  },

  updateContactInformationSuccess: data => {
    return {
      type: actions.UPDATE_CONTACT_INFORMATION_SUCCESS,
      data,
    };
  },

  updateContactInformationFailed: err => {
    return {
      type: actions.UPDATE_CONTACT_INFORMATION_FAILED,
      err,
    };
  },

  deleteContactInformationBegin: () => {
    return {
      type: actions.DELETE_CONTACT_INFORMATION_BEGIN,
    };
  },

  deleteContactInformationSuccess: data => {
    return {
      type: actions.DELETE_CONTACT_INFORMATION_SUCCESS,
      data,
    };
  },

  deleteContactInformationFailed: err => {
    return {
      type: actions.DELETE_CONTACT_INFORMATION_FAILED,
      err,
    };
  },
};

export default actions;