const actions = {
  GET_ROLE_BEGIN: 'GET_ROLE_BEGIN',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILED: 'GET_ROLE_FAILED',

  ADD_ROLE_BEGIN: 'ADD_ROLE_BEGIN',
  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  ADD_ROLE_FAILED: 'ADD_ROLE_FAILED',

  UPDATE_ROLE_BEGIN: 'UPDATE_ROLE_BEGIN',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',

  DELETE_ROLE_BEGIN: 'DELETE_ROLE_BEGIN',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',

  getRoleBegin: () => {
    return {
      type: actions.GET_ROLE_BEGIN,
    };
  },

  getRoleSuccess: data => {
    return {
      type: actions.GET_ROLE_SUCCESS,
      data,
    };
  },

  getRoleFailed: err => {
    return {
      type: actions.GET_ROLE_FAILED,
      err,
    };
  },

  addRoleBegin: () => {
    return {
      type: actions.ADD_ROLE_BEGIN,
    };
  },

  addRoleSuccess: data => {
    return {
      type: actions.ADD_ROLE_SUCCESS,
      data,
    };
  },

  addRoleFailed: err => {
    return {
      type: actions.ADD_ROLE_FAILED,
      err,
    };
  },

  updateRoleBegin: () => {
    return {
      type: actions.UPDATE_ROLE_BEGIN,
    };
  },

  updateRoleSuccess: data => {
    return {
      type: actions.UPDATE_ROLE_SUCCESS,
      data,
    };
  },

  updateRoleFailed: err => {
    return {
      type: actions.UPDATE_ROLE_FAILED,
      err,
    };
  },

  deleteRoleBegin: () => {
    return {
      type: actions.DELETE_ROLE_BEGIN,
    };
  },

  deleteRoleSuccess: data => {
    return {
      type: actions.DELETE_ROLE_SUCCESS,
      data,
    };
  },

  deleteRoleFailed: err => {
    return {
      type: actions.DELETE_ROLE_FAILED,
      err,
    };
  },
};

export default actions;