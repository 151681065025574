const actions = {
  GET_BOOKINGS_BEGIN: 'GET_BOOKINGS_BEGIN',
  GET_BOOKINGS_SUCCESS: 'GET_BOOKINGS_SUCCESS',
  GET_BOOKINGS_FAILED: 'GET_BOOKINGS_FAILED',

  CHANGE_BOOKING_STATUS_BEGIN: 'CHANGE_BOOKING_STATUS_BEGIN',
  CHANGE_BOOKING_STATUS_SUCCESS: 'CHANGE_BOOKING_STATUS_SUCCESS',
  CHANGE_BOOKING_STATUS_FAILED: 'CHANGE_BOOKING_STATUS_FAILED',

  DELETE_BOOKING_BEGIN: 'DELETE_BOOKING_BEGIN',
  DELETE_BOOKING_SUCCESS: 'DELETE_BOOKING_SUCCESS',
  DELETE_BOOKING_FAILED: 'DELETE_BOOKING_FAILED',

  getBookingsBegin: () => {
    return {
      type: actions.GET_BOOKINGS_BEGIN,
    };
  },

  getBookingsSuccess: data => {
    return {
      type: actions.GET_BOOKINGS_SUCCESS,
      data,
    };
  },

  getBookingsFailed: err => {
    return {
      type: actions.GET_BOOKINGS_FAILED,
      err,
    };
  },

  changeBookingStatusBegin: () => {
    return {
      type: actions.CHANGE_BOOKING_STATUS_BEGIN,
    };
  },

  changeBookingStatusSuccess: data => {
    return {
      type: actions.CHANGE_BOOKING_STATUS_SUCCESS,
      data,
    };
  },

  changeBookingStatusFailed: err => {
    return {
      type: actions.CHANGE_BOOKING_STATUS_FAILED,
      err,
    };
  },

  deleteBookingBegin: () => {
    return {
      type: actions.DELETE_BOOKING_BEGIN,
    };
  },

  deleteBookingSuccess: data => {
    return {
      type: actions.DELETE_BOOKING_SUCCESS,
      data,
    };
  },

  deleteBookingFailed: err => {
    return {
      type: actions.DELETE_BOOKING_FAILED,
      err,
    };
  },
};

export default actions;
