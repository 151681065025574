// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import FeatherIcon from 'feather-icons-react';
import { useSelector, useDispatch } from 'react-redux';
import Pluralize from 'pluralize';

// USER COMPONENTS
import BlogCategoryTable from '../pages/overview/BlogCategoryTable';
import { Button } from '../../components/buttons/buttons';
import CreateBlogCategory from './overview/CreateBlogCategory';
import { getAllBlogCategories, addNewBlogCategory, deleteBlogCategory, editBlogCategory } from '../../redux/blogcategory/actionCreator';

const BlogCategory = () => {
  const [state, setState] = useState({ visible: false, currentPage: 1, pageLimit: 50 });
  const dispatch = useDispatch();

  const { blogCategories, loading } = useSelector(state => {
    return {
      loading: state.blogCategory.loading,
      blogCategories: state.blogCategory.categories,
    }
  })

  useEffect(() => {
    // For getting all the car body lists
    if (blogCategories.length == 0) getAllBlogCategories(dispatch);
  }, []);

  // For handling the open model
  const showModal = () => { setState({ visible: true }); };

  // For handling the close model
  const onCancel = () => { setState({ visible: false }); };

  // handling adding new blog category
  const handleAdd = (data, form) => { dispatch(addNewBlogCategory(data, form)) }

  // Handling edit blog category
  const handleEdit = (id, data) => { dispatch(editBlogCategory(id, data)) }

  // Handling delete blog category
  const handleDelete = (id) => { dispatch(deleteBlogCategory(id)) }

  return (
    <>
      <PageHeader
        ghost
        title="Blog Category"
        subTitle={
          blogCategories.length > 0 && (
            <span className="title-counter">{blogCategories.length} {Pluralize('Category', blogCategories.length)} </span>
          )
        }
        buttons={[
          <Button onClick={showModal} key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
            <FeatherIcon icon="plus" size={16} /> Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {
              loading ? (
                <div className='spin'>
                  <Spin />
                </div>
              ) : <BlogCategoryTable blogCategories={blogCategories} handleDelete={handleDelete} handleEdit={handleEdit} categoryState={setState} currentPage={state.currentPage} pageLimit={state.pageLimit} />
            }
          </Col>
        </Row>
        <CreateBlogCategory onCancel={onCancel} visible={state.visible} handleAdd={handleAdd} />
      </Main>
    </>
  );
};

export default BlogCategory;