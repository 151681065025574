import actions from './actions';

const {
  GET_BLOG_CATEGORY_BEGIN, GET_BLOG_CATEGORY_SUCCESS, GET_BLOG_CATEGORY_FAILED,
  ADD_BLOG_CATEGORY_BEGIN, ADD_BLOG_CATEGORY_SUCCESS, ADD_BLOG_CATEGORY_FAILED,
  UPDATE_BLOG_CATEGORY_BEGIN, UPDATE_BLOG_CATEGORY_SUCCESS, UPDATE_BLOG_CATEGORY_FAILED,
  DELETE_BLOG_CATEGORY_BEGIN, DELETE_BLOG_CATEGORY_SUCCESS, DELETE_BLOG_CATEGORY_FAILED
} = actions;

const initState = {
  categories: [],
  loading: false,
  error: null,
};


const BlogCategoryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_BLOG_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case GET_BLOG_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_BLOG_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case ADD_BLOG_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_BLOG_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case UPDATE_BLOG_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BLOG_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case DELETE_BLOG_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default BlogCategoryReducer;