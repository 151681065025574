import actions from './actions';

const {
  GET_TESTIMONIAL_BEGIN, GET_TESTIMONIAL_SUCCESS, GET_TESTIMONIAL_FAILED,
  ADD_TESTIMONIAL_BEGIN, ADD_TESTIMONIAL_SUCCESS, ADD_TESTIMONIAL_FAILED,
  UPDATE_TESTIMONIAL_BEGIN, UPDATE_TESTIMONIAL_SUCCESS, UPDATE_TESTIMONIAL_FAILED,
  DELETE_TESTIMONIAL_BEGIN, DELETE_TESTIMONIAL_SUCCESS, DELETE_TESTIMONIAL_FAILED
} = actions;

const initState = {
  testimonials: [],
  loading: false,
  error: null,
};


const TestimonialReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_TESTIMONIAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: data,
        loading: false,
      };
    case GET_TESTIMONIAL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_TESTIMONIAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: data,
        loading: false,
      };
    case ADD_TESTIMONIAL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_TESTIMONIAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: data,
        loading: false,
      };
    case UPDATE_TESTIMONIAL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_TESTIMONIAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: data,
        loading: false,
      };
    case DELETE_TESTIMONIAL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default TestimonialReducer;