import actions from './actions';

const {
  GET_SELL_CAR_BEGIN, GET_SELL_CAR_SUCCESS, GET_SELL_CAR_FAILED,
  ADD_SELL_CAR_BEGIN, ADD_SELL_CAR_SUCCESS, ADD_SELL_CAR_FAILED,
  UPDATE_SELL_CAR_BEGIN, UPDATE_SELL_CAR_SUCCESS, UPDATE_SELL_CAR_FAILED,
  DELETE_SELL_CAR_BEGIN, DELETE_SELL_CAR_SUCCESS, DELETE_SELL_CAR_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  cars: [],
  loading: false,
  error: null,
};


const SellingCarReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SELL_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SELL_CAR_SUCCESS:
      return {
        ...state,
        total: data.total || 0,
        cars: data.sellingCars,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_SELL_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_SELL_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_SELL_CAR_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        cars: data,
        loading: false,
      };
    case ADD_SELL_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SELL_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SELL_CAR_SUCCESS:
      return {
        ...state,
        cars: data,
        loading: false,
      };
    case UPDATE_SELL_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_SELL_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SELL_CAR_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        cars: data,
        loading: false,
      };
    case DELETE_SELL_CAR_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default SellingCarReducer;